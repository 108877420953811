import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgMask = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M5.99316 9.51856C5.99316 9.24241 5.76931 9.01856 5.49316 9.01856 5.21702 9.01856 4.99316 9.24241 4.99316 9.51856 4.99316 11.013 6.4269 12.0186 7.99317 12.0186 9.55942 12.0186 10.9932 11.013 10.9932 9.51856 10.9932 9.24241 10.7693 9.01856 10.4932 9.01856 10.217 9.01856 9.99317 9.24241 9.99317 9.51856 9.99317 10.2641 9.2269 11.0186 7.99317 11.0186 6.75942 11.0186 5.99316 10.2641 5.99316 9.51856ZM5.12174 5.13443C5.05312 5.20305 4.99316 5.31955 4.99316 5.50598 4.99316 5.78212 4.76931 6.00598 4.49316 6.00598 4.21702 6.00598 3.99316 5.78212 3.99316 5.50598 3.99316 5.09234 4.13321 4.70878 4.41459 4.42736 4.69598 4.14594 5.07952 4.00586 5.49316 4.00586 5.90681 4.00586 6.29035 4.14594 6.57174 4.42736 6.85312 4.70878 6.99316 5.09234 6.99316 5.50598 6.99316 5.78212 6.76931 6.00598 6.49316 6.00598 6.21702 6.00598 5.99316 5.78212 5.99316 5.50598 5.99316 5.31955 5.93321 5.20305 5.86459 5.13443 5.79598 5.0658 5.67952 5.00586 5.49316 5.00586 5.30681 5.00586 5.19035 5.0658 5.12174 5.13443ZM9.99317 5.50598C9.99317 5.31955 10.0531 5.20305 10.1217 5.13443 10.1904 5.0658 10.3068 5.00586 10.4932 5.00586 10.6795 5.00586 10.796 5.0658 10.8646 5.13443 10.9332 5.20305 10.9932 5.31955 10.9932 5.50598 10.9932 5.78212 11.217 6.00598 11.4932 6.00598 11.7693 6.00598 11.9932 5.78212 11.9932 5.50598 11.9932 5.09234 11.8531 4.70878 11.5717 4.42736 11.2904 4.14594 10.9068 4.00586 10.4932 4.00586 10.0795 4.00586 9.69598 4.14594 9.41459 4.42736 9.13321 4.70878 8.99317 5.09234 8.99317 5.50598 8.99317 5.78212 9.21702 6.00598 9.49317 6.00598 9.76931 6.00598 9.99317 5.78212 9.99317 5.50598Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M2.68182 0C2.21746 0 1.78998 0.152694 1.47603 0.458654C1.16093 0.765739 1 1.18867 1 1.65176V8.90781C1 12.4967 3.66844 15.5982 7.35894 15.9703L7.3636 15.9707C11.4297 16.3422 15 13.1264 15 9.13816V1.65176C15 1.18867 14.8391 0.765739 14.524 0.458654C14.21 0.152694 13.7825 0 13.3182 0H2.68182ZM2 1.65176C2 1.42379 2.07543 1.27084 2.17397 1.17481C2.27366 1.07766 2.43709 1 2.68182 1H13.3182C13.5629 1 13.7263 1.07766 13.826 1.17481C13.9246 1.27084 14 1.42379 14 1.65176V9.13816C14 12.5204 10.9534 15.2932 7.45688 14.9751C4.29405 14.655 2 11.9983 2 8.90781V1.65176Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgMask.propTypes = iconPropTypes;
