import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgIdCard = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M4.5.999512C4.22386.999512 4 1.22328 4 1.49931 4 1.77533 4.22386 1.9991 4.5 1.9991H11.5C11.7761 1.9991 12 1.77533 12 1.49931 12 1.22328 11.7761.999512 11.5.999512H4.5ZM.499023 4.00853C.222881 4.00853-.000976562 4.23229-.000976562 4.50832V14.4997C-.000976562 14.7757.222881 14.9995.499023 14.9995H15.499C15.7752 14.9995 15.999 14.7757 15.999 14.4997V4.50832C15.999 4.23229 15.7752 4.00853 15.499 4.00853H11.5C11.2239 4.00853 11 4.23229 11 4.50832 11 4.78435 11.2239 5.00812 11.5 5.00812H14.999V13.9999H.999023V5.00812H4.49794C4.77409 5.00812 4.99794 4.78435 4.99794 4.50832 4.99794 4.23229 4.77409 4.00853 4.49794 4.00853H.499023Z" />
          <path
            fillRule="evenodd"
            d="M3.0293 7.99517C3.0293 6.89105 3.92473 5.99599 5.0293 5.99599C6.13387 5.99599 7.0293 6.89105 7.0293 7.99517C7.0293 8.5123 6.83287 8.98358 6.51052 9.33851C6.81705 9.50292 7.08143 9.7255 7.29953 10.0045C7.80317 10.6487 8.00586 11.5253 8.00586 12.4933C8.00586 12.7693 7.782 12.9931 7.50586 12.9931C7.22972 12.9931 7.00586 12.7693 7.00586 12.4933C7.00586 11.6435 6.8248 11.0206 6.51158 10.62C6.21474 10.2403 5.74782 9.99435 5.00586 9.99435C4.2639 9.99435 3.79698 10.2403 3.50014 10.62C3.18692 11.0206 3.00586 11.6435 3.00586 12.4933C3.00586 12.7693 2.782 12.9931 2.50586 12.9931C2.22972 12.9931 2.00586 12.7693 2.00586 12.4933C2.00586 11.5253 2.20854 10.6487 2.71219 10.0045C2.9378 9.71589 3.21292 9.48766 3.53301 9.32173C3.21962 8.96879 3.0293 8.5042 3.0293 7.99517ZM5.0293 6.99558C4.47701 6.99558 4.0293 7.44311 4.0293 7.99517C4.0293 8.54723 4.47701 8.99476 5.0293 8.99476C5.58158 8.99476 6.0293 8.54723 6.0293 7.99517C6.0293 7.44311 5.58158 6.99558 5.0293 6.99558Z"
            clipRule="evenodd"
          />
          <path d="M9.00928 7.49586C9.00928 7.21983 9.23314 6.99607 9.50928 6.99607H13.5093C13.7854 6.99607 14.0093 7.21983 14.0093 7.49586 14.0093 7.77189 13.7854 7.99566 13.5093 7.99566H9.50928C9.23314 7.99566 9.00928 7.77189 9.00928 7.49586ZM9.50928 9.98995C9.23314 9.98995 9.00928 10.2137 9.00928 10.4897 9.00928 10.7658 9.23314 10.9895 9.50928 10.9895H13.5093C13.7854 10.9895 14.0093 10.7658 14.0093 10.4897 14.0093 10.2137 13.7854 9.98995 13.5093 9.98995H9.50928Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgIdCard.propTypes = iconPropTypes;
