import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgArrowLeftRect = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M6.49439 1.00488C6.77039 1.00488 6.99414 1.22882 6.99414 1.50506C6.99414 1.78131 6.77039 2.00525 6.49439 2.00525H3.99561C2.86406 2.00525 1.99658 2.8634 1.99658 3.83834V13.1714C1.99658 14.1464 2.86406 15.0045 3.99561 15.0045H6.49439C6.77039 15.0045 6.99414 15.2285 6.99414 15.5047C6.99414 15.7809 6.77039 16.0049 6.49439 16.0049H3.99561C2.37849 16.0049 0.99707 14.7631 0.99707 13.1714V3.83834C0.99707 2.24668 2.37849 1.00488 3.99561 1.00488H6.49439Z" />
          <path d="M8.18939 5.84242C7.99531 5.64601 7.67888 5.64426 7.48264 5.83852L5.15045 8.14705C5.05553 8.241 5.00211 8.36906 5.00211 8.50268C5.00211 8.63629 5.05553 8.76435 5.15045 8.85831L7.48264 11.1668C7.67888 11.3611 7.99531 11.3593 8.18939 11.1629C8.38348 10.9665 8.38173 10.6498 8.18549 10.4556L6.71793 9.0029H14.4973C14.7733 9.0029 14.9971 8.77897 14.9971 8.50272C14.9971 8.22648 14.7733 8.00254 14.4973 8.00254H6.71783L8.18549 6.54978C8.38173 6.35553 8.38348 6.03883 8.18939 5.84242Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" transform="rotate(-90 8 8)" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgArrowLeftRect.propTypes = iconPropTypes;
