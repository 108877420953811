import { UnreachableCaseException } from '@kontent-ai/errors';
import PropTypes from 'prop-types';
import React, { ComponentProps, useContext } from 'react';
import { Badge, maxBadgeValue } from '../Badge/Badge.tsx';
import { BadgeStyle } from '../Badge/badgeStyle.ts';
import { ButtonStyle } from './buttonStyle.ts';
import { StyledButtonBadgePositioner } from './components/StyledButtonBadgePositioner.tsx';
import { ButtonContext } from './utils/ButtonContext.ts';

interface IButtonBadgeProps {
  readonly value?: number;
}

const buttonBadgePropTypes: PropTypeMap<IButtonBadgeProps> = {
  value: PropTypes.number,
};

export const getBadgeStyle = (buttonStyle: ButtonStyle): BadgeStyle => {
  switch (buttonStyle) {
    case 'primary':
    case 'secondary-inverse':
      return 'inverse';
    case 'primary-inverse':
    case 'secondary':
    case 'tertiary':
      return 'default';
    default:
      throw UnreachableCaseException(buttonStyle);
  }
};

export const ButtonBadge = React.forwardRef<
  HTMLDivElement,
  Pick<ComponentProps<typeof Badge>, 'value'>
>((props, forwardedRef) => {
  const { buttonStyle, buttonSize } = useContext(ButtonContext);
  const badgeStyle = getBadgeStyle(buttonStyle);

  return (
    <StyledButtonBadgePositioner
      ref={forwardedRef}
      buttonSize={buttonSize}
      withValue={!!props.value && props.value <= maxBadgeValue}
    >
      <Badge badgeStyle={badgeStyle} value={props.value} />
    </StyledButtonBadgePositioner>
  );
});

ButtonBadge.displayName = 'ButtonBadge';
ButtonBadge.propTypes = buttonBadgePropTypes;
