import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgAiStars = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3.42164 0.276638C3.25176 -0.0922128 2.74824 -0.0922128 2.57836 0.276638L2.02704 1.47367C1.95885 1.62172 1.82382 1.72406 1.66775 1.74595L0.405957 1.92297C0.0171492 1.97752 -0.138448 2.47702 0.145367 2.75953L1.06643 3.67635C1.18035 3.78975 1.23193 3.95533 1.20366 4.11692L0.97515 5.42335C0.904736 5.82591 1.31209 6.13462 1.65738 5.94037L2.77795 5.30997C2.91655 5.232 3.08345 5.232 3.22205 5.30997L4.34262 5.94037C4.68791 6.13462 5.09526 5.82591 5.02485 5.42335L4.79634 4.11692C4.76807 3.95533 4.81965 3.78975 4.93357 3.67635L5.85463 2.75953C6.13845 2.47702 5.98285 1.97752 5.59404 1.92297L4.33225 1.74595C4.17618 1.72406 4.04115 1.62172 3.97296 1.47367L3.42164 0.276638ZM3 1.75159L2.93533 1.892C2.73065 2.3364 2.31358 2.66514 1.80668 2.73625L1.57248 2.76911L1.7719 2.96762C2.12291 3.31701 2.27187 3.8138 2.18871 4.28922L2.14896 4.51644L2.28764 4.43842C2.73067 4.18918 3.26933 4.18918 3.71236 4.43842L3.85104 4.51644L3.81129 4.28922C3.72813 3.8138 3.87709 3.31701 4.2281 2.96762L4.42752 2.76911L4.19332 2.73625C3.68642 2.66514 3.26935 2.3364 3.06467 1.892L3 1.75159Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M0.974607 7.79662C0.963543 7.80912 0.951277 7.82036 0.938029 7.83017L0.930852 7.83527C0.923783 7.8401 0.91646 7.84453 0.908913 7.84855C0.885716 7.86088 0.860403 7.86925 0.833875 7.87298L0.202978 7.96148C0.00857459 7.98876 -0.0692238 8.23851 0.0726835 8.37977L0.533213 8.83818C0.547873 8.85277 0.560468 8.86909 0.570849 8.88666C0.576706 8.89657 0.581858 8.90688 0.586279 8.9175C0.591016 8.92889 0.594914 8.94063 0.597938 8.95262C0.602436 8.97045 0.605003 8.98884 0.605531 9.00745C0.606012 9.02436 0.604806 9.04146 0.601832 9.05846L0.487575 9.71167C0.452368 9.91296 0.656047 10.0673 0.828692 9.97019L1.38897 9.65499C1.41529 9.64018 1.44365 9.631 1.4725 9.62744L1.48233 9.62644C1.49409 9.62551 1.50591 9.62551 1.51767 9.62644L1.5275 9.62744C1.55635 9.631 1.58471 9.64018 1.61103 9.65499L2.17131 9.97019C2.34395 10.0673 2.54763 9.91296 2.51242 9.71167L2.39817 9.05846C2.39519 9.04146 2.39399 9.02436 2.39447 9.00745C2.395 8.98884 2.39756 8.97045 2.40206 8.95262C2.40509 8.94063 2.40898 8.92889 2.41372 8.9175C2.41814 8.90688 2.42329 8.89657 2.42915 8.88666C2.43953 8.86909 2.45213 8.85277 2.46679 8.83818L2.92732 8.37977C3.06922 8.23851 2.99143 7.98876 2.79702 7.96148L2.16613 7.87298C2.1396 7.86925 2.11428 7.86088 2.09109 7.84855C2.08354 7.84453 2.07622 7.8401 2.06915 7.83527L2.06197 7.83017C2.04872 7.82036 2.03646 7.80912 2.02539 7.79662C2.00984 7.77904 1.99667 7.75896 1.98648 7.73683L1.71082 7.13832C1.62588 6.95389 1.37412 6.95389 1.28918 7.13832L1.01352 7.73683C1.00333 7.75896 0.990156 7.77904 0.974607 7.79662Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M9.46816 3.32444C9.39498 3.12929 9.20842 3 9 3C8.79158 3 8.60502 3.12929 8.53184 3.32444L4.03184 15.3244C3.93488 15.583 4.06588 15.8712 4.32444 15.9682C4.583 16.0651 4.8712 15.9341 4.96816 15.6756L6.3465 12H11.6535L13.0318 15.6756C13.1288 15.9341 13.417 16.0651 13.6756 15.9682C13.9341 15.8712 14.0651 15.583 13.9682 15.3244L9.46816 3.32444ZM9 4.924L11.2785 11H6.7215L9 4.924Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M16 3.5C16 3.22386 15.7761 3 15.5 3C15.2239 3 15 3.22386 15 3.5V15.5C15 15.7761 15.2239 16 15.5 16C15.7761 16 16 15.7761 16 15.5V3.5Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgAiStars.propTypes = iconPropTypes;
