import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgMonitor = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M5.98974 12H1.65385C1.18143 12 0.755136 11.8261 0.449906 11.4954C0.148024 11.1684 0 10.7272 0 10.25V2.75C0 2.2728 0.148024 1.83165 0.449906 1.50461C0.755136 1.17394 1.18143 1 1.65385 1H14.3462C14.8186 1 15.2449 1.17394 15.5501 1.50461C15.852 1.83165 16 2.2728 16 2.75V10.25C16 10.7272 15.852 11.1684 15.5501 11.4954C15.2449 11.8261 14.8186 12 14.3462 12H10.0205C10.0369 12.0765 10.061 12.1624 10.0958 12.2565C10.26 12.6997 10.6807 13.3695 11.7728 14.0805C11.9583 14.2013 12.0423 14.4296 11.9793 14.6418C11.9163 14.854 11.7213 14.9995 11.5 14.9995H4.5C4.27866 14.9995 4.08365 14.854 4.02067 14.6418C3.95769 14.4296 4.04171 14.2013 4.22721 14.0805C5.31977 13.3692 5.74521 12.6985 5.91304 12.2536C5.94808 12.1608 5.97265 12.0758 5.98974 12ZM1 2.75C1 2.4772 1.08275 2.29335 1.18471 2.18289C1.28333 2.07606 1.43395 2 1.65385 2H14.3462C14.566 2 14.7167 2.07606 14.8153 2.18289C14.9173 2.29335 15 2.4772 15 2.75V10.25C15 10.5228 14.9173 10.7066 14.8153 10.8171C14.7167 10.9239 14.566 11 14.3462 11H1.65385C1.43395 11 1.28333 10.9239 1.18471 10.8171C1.08275 10.7066 1 10.5228 1 10.25V2.75ZM7.00516 12C6.98132 12.1744 6.93442 12.3793 6.84868 12.6066C6.69062 13.0256 6.40711 13.5039 5.91816 13.9995H10.0833C9.59516 13.5031 9.31365 13.0239 9.15806 12.6037C9.07438 12.3777 9.02906 12.1738 9.00647 12H7.00516Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgMonitor.propTypes = iconPropTypes;
