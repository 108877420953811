import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLMenuTextCol = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M12 1.5C12 1.22386 12.2239 1 12.5 1H14.5C14.7761 1 15 1.22386 15 1.5V14.5C15 14.7761 14.7761 15 14.5 15H12.5C12.2239 15 12 14.7761 12 14.5V1.5ZM13 2V14H14V2H13Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M1.5 1.00195C1.22386 1.00195 1 1.22581 1 1.50195 1 1.7781 1.22386 2.00195 1.5 2.00195H3.5C3.77614 2.00195 4 1.7781 4 1.50195 4 1.22581 3.77614 1.00195 3.5 1.00195H1.5ZM1.5 3.00586C1.22386 3.00586 1 3.22972 1 3.50586 1 3.782 1.22386 4.00586 1.5 4.00586H3.5C3.77614 4.00586 4 3.782 4 3.50586 4 3.22972 3.77614 3.00586 3.5 3.00586H1.5ZM1 5.50488C1 5.22874 1.22386 5.00488 1.5 5.00488H3.5C3.77614 5.00488 4 5.22874 4 5.50488 4 5.78103 3.77614 6.00488 3.5 6.00488H1.5C1.22386 6.00488 1 5.78103 1 5.50488ZM5.5 1.00195C5.22386 1.00195 5 1.22581 5 1.50195 5 1.7781 5.22386 2.00195 5.5 2.00195H10.5C10.7761 2.00195 11 1.7781 11 1.50195 11 1.22581 10.7761 1.00195 10.5 1.00195H5.5ZM5.5 3.00586C5.22386 3.00586 5 3.22972 5 3.50586 5 3.782 5.22386 4.00586 5.5 4.00586H10.5C10.7761 4.00586 11 3.782 11 3.50586 11 3.22972 10.7761 3.00586 10.5 3.00586H5.5ZM5 5.50488C5 5.22874 5.22386 5.00488 5.5 5.00488H10.5C10.7761 5.00488 11 5.22874 11 5.50488 11 5.78103 10.7761 6.00488 10.5 6.00488H5.5C5.22386 6.00488 5 5.78103 5 5.50488ZM5.5 7.00879C5.22386 7.00879 5 7.23265 5 7.50879 5 7.78493 5.22386 8.00879 5.5 8.00879H10.5C10.7761 8.00879 11 7.78493 11 7.50879 11 7.23265 10.7761 7.00879 10.5 7.00879H5.5ZM5 9.50098C5 9.22483 5.22386 9.00098 5.5 9.00098H10.5C10.7761 9.00098 11 9.22483 11 9.50098 11 9.77712 10.7761 10.001 10.5 10.001H5.5C5.22386 10.001 5 9.77712 5 9.50098ZM5.5 11.001C5.22386 11.001 5 11.2248 5 11.501 5 11.7771 5.22386 12.001 5.5 12.001H8.5C8.77614 12.001 9 11.7771 9 11.501 9 11.2248 8.77614 11.001 8.5 11.001H5.5Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLMenuTextCol.propTypes = iconPropTypes;
