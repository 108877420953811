import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgWizardStick = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M9.5389 0.0675294C9.72612 -0.0410956 9.96197 -0.0169231 10.1233 0.12742L12.0353 1.83852L14.2638 0.559704C14.4501 0.452805 14.6838 0.476821 14.8445 0.619366C15.0052 0.761911 15.0568 0.991135 14.9729 1.18883L13.9984 3.48345L15.8455 5.24871C16 5.39644 16.0436 5.62612 15.9538 5.82017C15.864 6.01422 15.6607 6.12969 15.4481 6.10747L12.8894 5.84018L11.8437 8.17456C11.7553 8.37195 11.5499 8.49011 11.3349 8.46739C11.1198 8.44467 10.9437 8.28622 10.8984 8.07472L10.4817 6.12609L0.855363 15.8517C0.661106 16.048 0.344527 16.0496 0.148266 15.8554C-0.0479959 15.6611 -0.0496206 15.3445 0.144637 15.1483L9.68863 5.50582L7.80635 5.30919C7.59061 5.28665 7.414 5.12758 7.36912 4.91536C7.32423 4.70314 7.4213 4.48618 7.60944 4.37822L9.83481 3.10122L9.30088 0.604571C9.25562 0.392909 9.35168 0.176154 9.5389 0.0675294ZM10.5996 1.89569L10.889 3.24878C10.9344 3.46131 10.8374 3.67885 10.6489 3.78702L9.44932 4.47538L10.8291 4.61952C11.0444 4.64201 11.2208 4.80053 11.2661 5.01225L11.5564 6.36973L12.1248 5.10086C12.2132 4.90366 12.4182 4.78552 12.6331 4.80797L14.0969 4.96089L13.0576 3.96759C12.9072 3.82389 12.8615 3.60211 12.9428 3.41067L13.4707 2.16778L12.2198 2.88559C12.0328 2.99288 11.7981 2.96826 11.6375 2.8245L10.5996 1.89569Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgWizardStick.propTypes = iconPropTypes;
