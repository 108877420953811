import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgPlaceholder = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.49976 1C1.22361 1 0.999756 1.22386 0.999756 1.5V14.4994C0.999756 14.7755 1.22361 14.9994 1.49976 14.9994H14.4991C14.7753 14.9994 14.9991 14.7755 14.9991 14.4994V1.5C14.9991 1.22386 14.7753 1 14.4991 1H1.49976ZM1.99976 2.70691V13.2916L7.29041 7.99756L1.99976 2.70691ZM2.70615 13.9994H13.2922L7.99752 8.70467L2.70615 13.9994ZM13.9991 13.2921V2.69928L8.7044 7.99734L13.9991 13.2921ZM13.2842 2H2.70706L7.99729 7.29024L13.2842 2Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgPlaceholder.propTypes = iconPropTypes;
