import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLGrid32 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.5.99707C1.22386.99707 1 1.22093 1 1.49707V6.49977C1 6.77591 1.22386 6.99977 1.5 6.99977H4.5C4.77614 6.99977 5 6.77591 5 6.49977V1.49707C5 1.22093 4.77614.99707 4.5.99707H1.5ZM2 5.99977V1.99707H4V5.99977H2ZM1.5 9C1.22386 9 1 9.22386 1 9.5V14.5C1 14.7761 1.22386 15 1.5 15H4.5C4.77614 15 5 14.7761 5 14.5V9.5C5 9.22386 4.77614 9 4.5 9H1.5ZM2 14V10H4V14H2ZM6 1.49707C6 1.22093 6.22386.99707 6.5.99707H9.5C9.77614.99707 10 1.22093 10 1.49707V6.49977C10 6.77591 9.77614 6.99977 9.5 6.99977H6.5C6.22386 6.99977 6 6.77591 6 6.49977V1.49707ZM7 1.99707V5.99977H9V1.99707H7ZM11.5.99707C11.2239.99707 11 1.22093 11 1.49707V6.49977C11 6.77591 11.2239 6.99977 11.5 6.99977H14.5C14.7761 6.99977 15 6.77591 15 6.49977V1.49707C15 1.22093 14.7761.99707 14.5.99707H11.5ZM12 5.99977V1.99707H14V5.99977H12ZM6 9.5C6 9.22386 6.22386 9 6.5 9H9.5C9.77614 9 10 9.22386 10 9.5V14.5C10 14.7761 9.77614 15 9.5 15H6.5C6.22386 15 6 14.7761 6 14.5V9.5ZM7 10V14H9V10H7ZM11.5 9C11.2239 9 11 9.22386 11 9.5V14.5C11 14.7761 11.2239 15 11.5 15H14.5C14.7761 15 15 14.7761 15 14.5V9.5C15 9.22386 14.7761 9 14.5 9H11.5ZM12 14V10H14V14H12Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLGrid32.propTypes = iconPropTypes;
