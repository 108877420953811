import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgTF = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M14.8536.853553C15.0488.658291 15.0488.341709 14.8536.146447 14.6583-.0488155 14.3417-.0488155 14.1464.146447L.146447 14.1464C-.0488155 14.3417-.0488155 14.6583.146447 14.8536.341709 15.0488.658291 15.0488.853553 14.8536L14.8536.853553ZM1.00781.503906C.73167.503906.507812.727764.507812 1.00391.507812 1.28005.73167 1.50391 1.00781 1.50391H3.56396V8.00391C3.56396 8.28005 3.78782 8.50391 4.06396 8.50391 4.34011 8.50391 4.56396 8.28005 4.56396 8.00391V1.50391H7.00781C7.28396 1.50391 7.50781 1.28005 7.50781 1.00391 7.50781.727764 7.28396.503906 7.00781.503906H1.00781ZM9.99365 7.5127C9.86104 7.5127 9.73387 7.56537 9.6401 7.65914 9.54633 7.75291 9.49365 7.88009 9.49365 8.0127V14.0127C9.49365 14.2888 9.71751 14.5127 9.99365 14.5127 10.2698 14.5127 10.4937 14.2888 10.4937 14.0127V11.5144H12.0017C12.2778 11.5144 12.5017 11.2905 12.5017 11.0144 12.5017 10.7382 12.2778 10.5144 12.0017 10.5144H10.4937V8.5127H13.9937C14.2698 8.5127 14.4937 8.28884 14.4937 8.0127 14.4937 7.73655 14.2698 7.5127 13.9937 7.5127H9.99365Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgTF.propTypes = iconPropTypes;
