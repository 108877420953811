import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgCbCheckSign = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M12.3883 4.09317C12.613 4.25368 12.665 4.56595 12.5045 4.79066L7.50452 11.7907C7.42085 11.9078 7.29073 11.9832 7.14748 11.9976C7.00423 12.0119 6.86175 11.9638 6.7565 11.8656L3.7565 9.06557C3.55462 8.87715 3.54371 8.56076 3.73213 8.35888C3.92055 8.157 4.23694 8.14609 4.43882 8.33451L7.02211 10.7456L11.6908 4.20942C11.8513 3.98471 12.1636 3.93267 12.3883 4.09317Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgCbCheckSign.propTypes = iconPropTypes;
