import PropTypes from 'prop-types';
import React, { HTMLAttributes } from 'react';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { BadgeStyle, badgeStyleValues } from './badgeStyle.ts';
import { StyledBadge } from './components/StyledBadge.tsx';
import { StyledBadgeContent } from './components/StyledBadgeContent.tsx';

export interface IBadgeProps {
  readonly badgeStyle: BadgeStyle;
  readonly value?: number;
}

const propTypes: PropTypeMap<IBadgeProps> = {
  badgeStyle: PropTypes.oneOf(badgeStyleValues).isRequired,
  value: PropTypes.number,
};

export const maxBadgeValue = 99;

export const Badge = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<IBadgeProps & HTMLAttributes<HTMLDivElement>>
>(({ badgeStyle, value, ...otherProps }, forwardedRef) => {
  const withValue = typeof value !== 'undefined' && value <= maxBadgeValue;
  const showBadge = value !== 0;

  if (!showBadge) {
    return null;
  }

  return (
    <StyledBadge
      badgeStyle={badgeStyle}
      ref={forwardedRef}
      withValue={withValue}
      {...otherProps}
      {...getDataUiComponentAttribute(Badge)}
    >
      {withValue && <StyledBadgeContent>{value}</StyledBadgeContent>}
    </StyledBadge>
  );
});

Badge.displayName = 'Badge';
Badge.propTypes = propTypes;
