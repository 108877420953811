import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgMug = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.00005 2.49964C1.00007 1.67141 1.66989 1 2.49615 1H11.4726C12.2989 1 12.9687 1.67143 12.9687 2.49968V3.99354L13.5024 3.99326C14.4712 3.99276 15.185 4.9012 14.9575 5.8451L14.2343 8.84592C14.072 9.51914 13.4709 9.99343 12.78 9.99343H11.7782C11.6823 9.99343 11.5928 9.9663 11.5168 9.91929L10.9075 11.9293C10.716 12.5611 10.1348 12.993 9.47602 12.993H4.56113C3.90805 12.993 3.33044 12.5684 3.13419 11.944L1.06915 5.37404C1.02332 5.22822 0.999997 5.07623 1 4.92335L1.00005 2.49964ZM12.9041 5.34227C12.9385 5.22873 12.9593 5.11159 12.9662 4.99333L13.503 4.99305C13.8259 4.99288 14.0638 5.29569 13.988 5.61033L13.2648 8.61114C13.2107 8.83555 13.0103 8.99364 12.78 8.99364H11.7973L12.9041 5.34227ZM2.49615 1.99978C2.22073 1.99978 1.99745 2.22359 1.99745 2.49967L1.99739 4.92337C1.99739 4.97433 2.00517 5.02499 2.02044 5.0736L4.08548 11.6436C4.1509 11.8517 4.34344 11.9932 4.56113 11.9932H9.47602C9.6956 11.9932 9.88935 11.8493 9.95318 11.6387L11.9498 5.05162C11.9641 5.0045 11.9713 4.95553 11.9713 4.90629V2.49968C11.9713 2.22359 11.7481 1.99978 11.4726 1.99978H2.49615Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M1.51823 14.0002C1.2428 14.0002 1.01953 14.224 1.01953 14.5001C1.01953 14.7762 1.2428 15 1.51822 15L12.4896 15C12.765 15 12.9883 14.7762 12.9883 14.5001C12.9883 14.224 12.765 14.0002 12.4896 14.0002L1.51823 14.0002Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgMug.propTypes = iconPropTypes;
