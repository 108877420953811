import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgBrandGoogle = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M7.97119 3.00098C5.20977 3.00098 2.97119 5.23955 2.97119 8.00098C2.97119 10.7624 5.20977 13.001 7.97119 13.001C10.6816 13.001 12.9712 10.9669 12.9712 8.49781C12.9712 8.22166 12.7473 7.99781 12.4712 7.99781H8.87119C8.59505 7.99781 8.37119 8.22166 8.37119 8.49781C8.37119 8.77395 8.59505 8.99781 8.87119 8.99781H11.9276C11.6451 10.6146 10.0224 12.001 7.97119 12.001C5.76205 12.001 3.97119 10.2101 3.97119 8.00098C3.97119 5.79184 5.76205 4.00098 7.97119 4.00098C9.02911 4.00098 9.99017 4.41102 10.7059 5.08179C10.9074 5.27063 11.2238 5.26038 11.4126 5.05889C11.6014 4.85741 11.5912 4.54099 11.3897 4.35216C10.496 3.51458 9.29304 3.00098 7.97119 3.00098Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.00391 3.00415C1.00391 1.89958 1.89934 1.00415 3.00391 1.00415H13.0039C14.1085 1.00415 15.0039 1.89958 15.0039 3.00415V13.0042C15.0039 14.1087 14.1085 15.0042 13.0039 15.0042H3.00391C1.89934 15.0042 1.00391 14.1087 1.00391 13.0042V3.00415ZM3.00391 2.00415H13.0039C13.5562 2.00415 14.0039 2.45187 14.0039 3.00415V13.0042C14.0039 13.5564 13.5562 14.0042 13.0039 14.0042H3.00391C2.45162 14.0042 2.00391 13.5564 2.00391 13.0042V3.00415C2.00391 2.45187 2.45162 2.00415 3.00391 2.00415Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgBrandGoogle.propTypes = iconPropTypes;
