import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgDollarSign = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M8.50011 0C8.77625 -1.21072e-08 9.00011 0.223858 9.00011 0.5V2.01464C10.7282 2.24786 12 3.6796 12 5.48805C12 5.7642 11.7761 5.98805 11.5 5.98805C11.2239 5.98805 11 5.7642 11 5.48805C11 4.05932 9.9228 2.98132 8.5 2.98132C7.0772 2.98132 6 4.05932 6 5.48805C6 6.34512 6.30884 6.80524 6.71528 7.0768C7.15736 7.37218 7.78991 7.49366 8.5 7.49366C9.28991 7.49366 10.1574 7.62274 10.8403 8.07905C11.5588 8.55916 12 9.35185 12 10.4993C12 12.3077 10.7282 13.7395 9.00011 13.9727V15.4978C9.00011 15.7739 8.77625 15.9978 8.50011 15.9978C8.22396 15.9978 8.00011 15.7739 8.00011 15.4978V13.9727C6.27188 13.7396 5 12.3078 5 10.4993C5 10.2231 5.22386 9.99927 5.5 9.99927C5.77614 9.99927 6 10.2231 6 10.4993C6 11.928 7.0772 13.006 8.5 13.006C9.9228 13.006 11 11.928 11 10.4993C11 9.6422 10.6912 9.18209 10.2847 8.91052C9.84264 8.61514 9.21009 8.49366 8.5 8.49366C7.71009 8.49366 6.84264 8.36458 6.15972 7.90828C5.44116 7.42816 5 6.63547 5 5.48805C5 3.67953 6.27188 2.24774 8.00011 2.01461V0.5C8.00011 0.223858 8.22396 1.21072e-08 8.50011 0Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgDollarSign.propTypes = iconPropTypes;
