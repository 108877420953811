import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgTimes = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.85355 1.14644C1.65828 0.951182 1.3417 0.951187 1.14644 1.14645C0.951182 1.34172 0.951187 1.6583 1.14645 1.85356L7.29202 7.99895L1.14645 14.1443C0.951187 14.3396 0.951182 14.6562 1.14644 14.8515C1.3417 15.0467 1.65828 15.0467 1.85355 14.8515L7.99913 8.70605L14.1443 14.8511C14.3396 15.0463 14.6562 15.0463 14.8515 14.8511C15.0467 14.6558 15.0467 14.3392 14.8514 14.144L8.70625 7.99895L14.8514 1.85393C15.0467 1.65867 15.0467 1.34209 14.8515 1.14683C14.6562 0.95156 14.3396 0.951556 14.1443 1.14681L7.99913 7.29185L1.85355 1.14644Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgTimes.propTypes = iconPropTypes;
