import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgChevronLeftSquare = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M9.53379 4.87242C9.72922 4.67733 9.7295 4.36075 9.53442 4.16532C9.33933 3.96988 9.02274 3.9696 8.82731 4.16469L5.32108 7.66469C5.22712 7.75848 5.17432 7.88579 5.17432 8.01855C5.17432 8.15132 5.22712 8.27863 5.32108 8.37242L8.82731 11.8724C9.02275 12.0675 9.33933 12.0672 9.53442 11.8718C9.7295 11.6764 9.72922 11.3598 9.53379 11.1647L6.38205 8.01855L9.53379 4.87242Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3 1C1.89543 1 1 1.89543 1 3L1 13C1 14.1046 1.89543 15 3 15L13 15C14.1046 15 15 14.1046 15 13L15 3C15 1.89543 14.1046 0.999999 13 0.999999L3 1ZM2 3L2 13C2 13.5523 2.44772 14 3 14L13 14C13.5523 14 14 13.5523 14 13L14 3C14 2.44771 13.5523 2 13 2L3 2C2.44772 2 2 2.44772 2 3Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgChevronLeftSquare.propTypes = iconPropTypes;
