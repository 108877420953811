import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgHeartshake = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M14.6726 9.10631C16.1129 7.68089 16.43 5.50771 15.3864 3.88476L15.3805 3.87584C14.5064 2.57817 13.1839 1.99561 11.8701 1.99561C10.8497 1.99561 9.68679 2.421 8.93857 3.1615L8.00039 4.09001L7.06221 3.1615C6.314 2.421 5.15111 1.99561 4.1307 1.99561C2.81691 1.99561 1.49438 2.57817 0.620254 3.87584L0.614382 3.88476C-0.429166 5.50771 -0.112088 7.68089 1.32818 9.10631L6.74575 14.468C7.45654 15.1715 8.54424 15.1715 9.25504 14.468L14.6726 9.10631ZM2.03161 8.39554C0.893505 7.26918 0.693698 5.6153 1.45265 4.43005C2.12636 3.43301 3.12418 2.99561 4.1307 2.99561C4.91615 2.99561 5.81709 3.33616 6.35878 3.87226L7.31274 4.81639L6.73298 5.47214C6.0321 6.18022 6.03636 7.38296 6.74575 8.08503C7.47528 8.80703 8.53929 8.75774 9.34268 8.12165L9.36437 8.10449L10.0639 7.41212L12.5115 9.83823L9.82246 12.4995C9.73646 12.2464 9.58844 12.0067 9.3784 11.8L6.04817 8.5218C5.34123 7.8259 4.26041 7.8259 3.55346 8.5218L2.85444 9.20989L2.03161 8.39554ZM8.36395 5.13714L9.64201 3.87226C10.1837 3.33616 11.0846 2.99561 11.8701 2.99561C12.8766 2.99561 13.8744 3.43302 14.5481 4.43006C15.3071 5.61531 15.1073 7.26918 13.9692 8.39554L13.2222 9.13477L10.0645 6.00462L8.70153 7.35355C8.22194 7.7214 7.74734 7.66935 7.44918 7.37427C7.13093 7.0593 7.13093 6.4851 7.44918 6.17014L7.46102 6.15842L8.36395 5.13714ZM4.25498 9.23445C4.57271 8.92168 5.02892 8.92168 5.34666 9.23445L8.67688 12.5126C8.99023 12.8211 8.99023 13.2567 8.67688 13.5652L8.54879 13.6913C8.23106 14.004 7.77485 14.004 7.45712 13.6913L3.59228 9.8868L4.25498 9.23445Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgHeartshake.propTypes = iconPropTypes;
