import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgBullseye = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path
            fillRule="evenodd"
            d="M14.1412 0.146447C13.946 -0.0488155 13.6294 -0.0488155 13.4341 0.146447L12.1484 1.43216C12.0546 1.52593 12.002 1.65311 12.002 1.78571V3.29334L8.08883 7.20647C7.89357 7.40173 7.89357 7.71831 8.08883 7.91358C8.28409 8.10884 8.60067 8.10884 8.79594 7.91358L12.7095 4H14.2162C14.3488 4 14.476 3.94732 14.5698 3.85355L15.8555 2.56784C16.0508 2.37258 16.0508 2.05599 15.8555 1.86073L14.1412 0.146447ZM13.002 3V1.99282L13.7877 1.20711L14.7948 2.21429L14.0091 3H13.002Z"
            clipRule="evenodd"
          />
          <path d="M8.00107 1C4.12956 1 1 4.12956 1 8.00107C1 11.8726 4.12956 15.0021 8.00107 15.0021C11.8726 15.0021 15.0021 11.8726 15.0021 8.00107C15.0021 7.18406 14.8386 6.3526 14.582 5.49731C14.5026 5.23281 14.6527 4.95407 14.9172 4.87472C15.1817 4.79537 15.4605 4.94546 15.5398 5.20996C15.8127 6.11963 16.0021 7.05313 16.0021 8.00107C16.0021 12.4249 12.4249 16.0021 8.00107 16.0021C3.57728 16.0021 0 12.4249 0 8.00107C0 3.57728 3.57728 7.45058e-09 8.00107 7.45058e-09C8.94901 7.45058e-09 9.88251 0.189426 10.7922 0.462326C11.0567 0.541675 11.2068 0.820417 11.1274 1.08491C11.0481 1.34941 10.7693 1.4995 10.5048 1.42015C9.64954 1.16357 8.81809 1 8.00107 1Z" />
          <path d="M7.06792 5.91624C7.2447 6.12837 7.21604 6.44366 7.0039 6.62044C6.56079 6.9897 6.29492 7.47978 6.29492 8.01411C6.29492 8.98241 7.04884 9.73633 8.01714 9.73633C8.55147 9.73633 9.04155 9.47046 9.41081 9.02735C9.58759 8.81521 9.90288 8.78655 10.115 8.96333C10.3272 9.14011 10.3558 9.45539 10.179 9.66753C9.65941 10.2911 8.90504 10.7363 8.01714 10.7363C6.49656 10.7363 5.29492 9.53469 5.29492 8.01411C5.29492 7.12621 5.74017 6.37184 6.36372 5.85222C6.57586 5.67544 6.89114 5.7041 7.06792 5.91624Z" />
          <path d="M8.00928 3.50952C8.39062 3.50952 8.57838 3.51415 8.76025 3.57477C9.02223 3.6621 9.30539 3.52052 9.39271 3.25854C9.48003 2.99657 9.33845 2.71341 9.07648 2.62609C8.72578 2.50919 8.38055 2.50935 8.04608 2.50951L8.00928 2.50952C5.00586 2.50952 2.50928 5.00611 2.50928 8.00952C2.50928 11.0129 5.00586 13.5095 8.00928 13.5095C11.0127 13.5095 13.5093 11.0129 13.5093 8.00952L13.5093 7.98023C13.5095 7.72588 13.5098 7.29364 13.3927 6.94232C13.3054 6.68035 13.0222 6.53876 12.7603 6.62609C12.4983 6.71341 12.3567 6.99657 12.444 7.25854C12.5041 7.4388 12.5093 7.71344 12.5093 8.00952C12.5093 10.4607 10.4604 12.5095 8.00928 12.5095C5.55815 12.5095 3.50928 10.4607 3.50928 8.00952C3.50928 5.55839 5.55815 3.50952 8.00928 3.50952Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgBullseye.propTypes = iconPropTypes;
