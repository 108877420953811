import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgBoxClock = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 17"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M4.5 6C4.22386 6 4 6.22386 4 6.5 4 6.77614 4.22386 7 4.5 7H9.5C9.77614 7 10 6.77614 10 6.5 10 6.22386 9.77614 6 9.5 6H4.5ZM12.011 10.3779C12.011 10.1018 11.7872 9.87793 11.511 9.87793 11.2349 9.87793 11.011 10.1018 11.011 10.3779V11.7384C11.011 11.8808 11.0718 12.0165 11.178 12.1114L12.1938 13.0184C12.3998 13.2023 12.7159 13.1844 12.8998 12.9784 13.0837 12.7724 13.0658 12.4563 12.8598 12.2724L12.011 11.5145V10.3779Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M0 1.5V13.5266C0 14.355 0.671571 15.0266 1.5 15.0266H8.65499C9.42726 15.6501 10.4099 16.0234 11.4797 16.0234C13.965 16.0234 15.9797 14.0087 15.9797 11.5234C15.9797 9.98678 15.2095 8.63002 14.0341 7.81815V1.5C14.0341 0.671573 13.3625 0 12.5341 0H1.5C0.671573 0 0 0.671572 0 1.5ZM1.5 1C1.22386 1 1 1.22386 1 1.5V3H13.0341V1.5C13.0341 1.22386 12.8102 1 12.5341 1H1.5ZM6.97974 11.5234C6.97974 12.4499 7.2597 13.3109 7.73963 14.0266H1.5C1.22386 14.0266 1 13.8027 1 13.5266V4H13.0341V7.29909C12.5496 7.12079 12.0261 7.02344 11.4797 7.02344C8.99446 7.02344 6.97974 9.03816 6.97974 11.5234ZM11.4797 15.0234C13.4127 15.0234 14.9797 13.4564 14.9797 11.5234C14.9797 9.59044 13.4127 8.02344 11.4797 8.02344C9.54674 8.02344 7.97974 9.59044 7.97974 11.5234C7.97974 13.4564 9.54674 15.0234 11.4797 15.0234Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgBoxClock.propTypes = iconPropTypes;
