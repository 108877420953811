import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgArrowUp = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            d="M5.15023 3.1476C4.95448 3.34293 4.95448 3.65963 5.15023 3.85497C5.34599 4.0503 5.66337 4.0503 5.85912 3.85497L8.00216 1.71653L8.00216 15.4998C8.00216 15.7761 8.22658 16 8.50342 16C8.78025 16 9.00468 15.7761 9.00468 15.4998L9.00467 1.71652L11.1477 3.85497C11.3435 4.0503 11.6608 4.0503 11.8566 3.85496C12.0524 3.65963 12.0524 3.34293 11.8566 3.1476L8.93675 0.234016C8.91715 0.202945 8.89379 0.173558 8.86667 0.1465C8.76655 0.0465943 8.63461 -0.00221344 8.50341 7.70008e-05C8.37221 -0.00220746 8.24028 0.0466004 8.14016 0.146501C8.11305 0.173558 8.08969 0.202944 8.07008 0.234014L5.15023 3.1476Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" transform="matrix(0 1 1 0 0 0)" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgArrowUp.propTypes = iconPropTypes;
