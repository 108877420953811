import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgDocPaste = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M6.97021 2.50857C6.97021 2.23259 7.19407 2.00886 7.47021 2.00886H8.47228C8.74842 2.00886 8.97228 2.23259 8.97228 2.50857C8.97228 2.78456 8.74842 3.00828 8.47228 3.00828H7.47021C7.19407 3.00828 6.97021 2.78456 6.97021 2.50857Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.98047 0C4.42818 0 3.98047 0.447456 3.98047 0.99942V3.99768C3.98047 4.54965 4.42818 4.9971 4.98047 4.9971H10.9805C11.5328 4.9971 11.9805 4.54965 11.9805 3.99768V0.999421C11.9805 0.447456 11.5328 0 10.9805 0H4.98047ZM10.9805 0.999421H4.98047V3.99768H10.9805V0.999421Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M2.5 2.00811C1.67157 2.00811 1 2.6793 1 3.50724V14.5009C1 15.3288 1.67157 16 2.5 16H13.5C14.3284 16 15 15.3288 15 14.5009V3.50724C15 2.6793 14.3284 2.00811 13.5 2.00811H13.4862C13.2101 2.00811 12.9862 2.23184 12.9862 2.50782C12.9862 2.78381 13.2101 3.00753 13.4862 3.00753H13.5C13.7761 3.00753 14 3.23126 14 3.50724V14.5009C14 14.7769 13.7761 15.0006 13.5 15.0006H2.5C2.22386 15.0006 2 14.7769 2 14.5009V3.50724C2 3.23126 2.22386 3.00753 2.5 3.00753H2.51498C2.79113 3.00753 3.01498 2.78381 3.01498 2.50782C3.01498 2.23184 2.79113 2.00811 2.51498 2.00811H2.5Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgDocPaste.propTypes = iconPropTypes;
