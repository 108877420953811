import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgStarFull = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M7.01617 1.64549C7.41256 0.784837 8.58744 0.784837 8.98383 1.64549L10.2702 4.43856C10.4294 4.78402 10.7444 5.0228 11.1086 5.07388L14.0528 5.48693C14.96 5.6142 15.323 6.77972 14.6608 7.43891L12.5117 9.57816C12.2459 9.84276 12.1255 10.2291 12.1915 10.6061L12.7247 13.6545C12.8889 14.5938 11.9384 15.3141 11.1328 14.8609L8.51812 13.3899C8.19473 13.208 7.80527 13.208 7.48188 13.3899L4.86723 14.8609C4.06155 15.3141 3.11105 14.5938 3.27535 13.6545L3.80855 10.6061C3.8745 10.2291 3.75415 9.84276 3.48833 9.57816L1.33919 7.43891C0.676956 6.77972 1.04001 5.6142 1.94723 5.48693L4.89142 5.07388C5.25557 5.0228 5.57065 4.78402 5.72976 4.43856L7.01617 1.64549Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgStarFull.propTypes = iconPropTypes;
