import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgBrandOpenId = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.50215 3.03101C7.77829 3.031 8.00215 3.25486 8.00216 3.531L8.00218 5.00029C9.12852 5.1085 10.1626 5.5914 10.9597 6.17268L11.7496 5.38284C12.039 5.09346 12.5345 5.2553 12.5972 5.65971L13.0057 8.29166C13.0576 8.62603 12.7693 8.91432 12.4349 8.86243L9.80299 8.45394C9.39859 8.39118 9.23674 7.89568 9.52612 7.6063L10.2432 6.88925C9.60265 6.4474 8.82425 6.10534 8.00219 6.00764L8.00229 12.5023C8.0023 12.6349 7.94962 12.7621 7.85585 12.8559C7.76208 12.9496 7.6349 13.0023 7.50229 13.0023C6.81733 13.0023 5.95626 12.9109 5.16211 12.5245C4.35102 12.1299 3.63274 11.4376 3.24887 10.2939C2.56305 8.2505 3.62606 6.05776 6.00867 5.23312C6.34168 5.11787 6.67392 5.04469 7.00218 5.00746L7.00216 3.53101C7.00215 3.25487 7.22601 3.03101 7.50215 3.03101ZM7.00219 6.01674C6.77988 6.04858 6.55715 6.10149 6.33574 6.17812C4.42278 6.84021 3.70163 8.50005 4.1969 9.97572C4.48998 10.8489 5.01395 11.3404 5.59959 11.6253C6.04208 11.8406 6.53179 11.943 7.00229 11.9822L7.00219 6.01674Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3.07705 1.00171C1.93447 1.00171 1.00171 1.93447 1.00171 3.07705V12.9229C1.00171 14.0655 1.93447 14.9983 3.07705 14.9983H12.9229C14.0655 14.9983 14.9983 14.0655 14.9983 12.9229V3.07705C14.9983 1.93447 14.0655 1.00171 12.9229 1.00171H3.07705ZM2.00171 3.07705C2.00171 2.48675 2.48675 2.00171 3.07705 2.00171H12.9229C13.5132 2.00171 13.9983 2.48675 13.9983 3.07705V12.9229C13.9983 13.5132 13.5132 13.9983 12.9229 13.9983H3.07705C2.48675 13.9983 2.00171 13.5132 2.00171 12.9229V3.07705Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgBrandOpenId.propTypes = iconPropTypes;
