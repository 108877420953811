import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgArrowLeft = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            d="M16.0034 8.5C16.0034 8.77684 15.7796 9.00126 15.5034 9.00126H1.7159L3.85355 11.1443C4.04881 11.3401 4.04881 11.6574 3.85355 11.8532C3.65829 12.0489 3.34171 12.0489 3.14645 11.8532L0.233926 8.93333C0.202868 8.91373 0.173493 8.89037 0.146445 8.86325C0.0466167 8.76317 -0.00216754 8.6313 7.93221e-05 8.50015C-0.00224573 8.3689 0.0465423 8.2369 0.146445 8.13675C0.173493 8.10963 0.202867 8.08627 0.233924 8.06667L3.14645 5.14682C3.34171 4.95106 3.65829 4.95106 3.85355 5.14682C4.04881 5.34257 4.04882 5.65995 3.85355 5.8557L1.71589 7.99874L15.5034 7.99874C15.7796 7.99874 16.0034 8.22316 16.0034 8.5Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgArrowLeft.propTypes = iconPropTypes;
