import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgArrows = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            d="M8.85363 1.15084C8.65837 0.955579 8.34178 0.955579 8.14652 1.15084L5.83883 3.45853C5.64357 3.6538 5.64357 3.97038 5.83883 4.16564C6.03409 4.3609 6.35067 4.3609 6.54594 4.16564L8 2.71158V8H2.71158L4.16564 6.54594C4.3609 6.35067 4.3609 6.03409 4.16564 5.83883C3.97038 5.64357 3.6538 5.64357 3.45853 5.83883L1.15084 8.14652C0.955579 8.34178 0.955579 8.65837 1.15084 8.85363L3.45853 11.1613C3.6538 11.3566 3.97038 11.3566 4.16564 11.1613C4.3609 10.9661 4.3609 10.6495 4.16564 10.4542L2.71143 9H8V14.2961L6.54594 12.842C6.35067 12.6467 6.03409 12.6467 5.83883 12.842C5.64357 13.0373 5.64357 13.3538 5.83883 13.5491L8.14652 15.8568C8.24029 15.9506 8.36747 16.0032 8.50008 16.0032C8.63268 16.0032 8.75986 15.9506 8.85363 15.8568L11.1613 13.5491C11.3566 13.3538 11.3566 13.0373 11.1613 12.842C10.9661 12.6467 10.6495 12.6467 10.4542 12.842L9 14.2962V9H14.296L12.8418 10.4542C12.6465 10.6495 12.6465 10.9661 12.8418 11.1613C13.037 11.3566 13.3536 11.3566 13.5489 11.1613L15.8566 8.85363C15.9503 8.75986 16.003 8.63268 16.003 8.50008C16.003 8.36747 15.9503 8.24029 15.8566 8.14652L13.5489 5.83883C13.3536 5.64357 13.037 5.64357 12.8418 5.83883C12.6465 6.03409 12.6465 6.35067 12.8418 6.54594L14.2958 8H9V2.71143L10.4542 4.16564C10.6495 4.3609 10.9661 4.3609 11.1613 4.16564C11.3566 3.97038 11.3566 3.6538 11.1613 3.45853L8.85363 1.15084Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" transform="rotate(-90 8 8)" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgArrows.propTypes = iconPropTypes;
