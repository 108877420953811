import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgCircleDashed = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M15.801 9.78098L14.8259 9.55929C14.9397 9.05879 15 8.53705 15 8C15 7.46295 14.9397 6.94121 14.8259 6.44071L15.801 6.21902C15.9312 6.79176 16 7.38785 16 8C16 8.61215 15.9312 9.20824 15.801 9.78098ZM14.7747 3.74324L13.9285 4.27607C13.3684 3.38656 12.6134 2.63159 11.7239 2.0715L12.2568 1.22528C13.2729 1.86507 14.1349 2.72714 14.7747 3.74324ZM9.78098 0.198963L9.55929 1.17408C9.05879 1.0603 8.53705 1 8 1C7.46295 1 6.94121 1.0603 6.44071 1.17408L6.21902 0.198964C6.79176 0.0687549 7.38785 0 8 0C8.61215 0 9.20824 0.0687548 9.78098 0.198963ZM3.74324 1.22528L4.27607 2.07151C3.38656 2.63159 2.63159 3.38656 2.0715 4.27607L1.22528 3.74325C1.86507 2.72714 2.72714 1.86507 3.74324 1.22528ZM0.198963 6.21902C0.0687548 6.79176 0 7.38785 0 8C0 8.61215 0.0687549 9.20824 0.198964 9.78098L1.17408 9.55929C1.0603 9.05879 1 8.53705 1 8C1 7.46295 1.0603 6.94121 1.17408 6.44071L0.198963 6.21902ZM1.22528 12.2568L2.07151 11.7239C2.63159 12.6134 3.38656 13.3684 4.27607 13.9285L3.74325 14.7747C2.72714 14.1349 1.86507 13.2729 1.22528 12.2568ZM6.21902 15.801L6.44071 14.8259C6.94121 14.9397 7.46295 15 8 15C8.53705 15 9.05879 14.9397 9.55929 14.8259L9.78098 15.801C9.20824 15.9312 8.61215 16 8 16C7.38785 16 6.79176 15.9312 6.21902 15.801ZM12.2568 14.7747L11.7239 13.9285C12.6134 13.3684 13.3684 12.6134 13.9285 11.7239L14.7747 12.2568C14.1349 13.2729 13.2729 14.1349 12.2568 14.7747Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgCircleDashed.propTypes = iconPropTypes;
