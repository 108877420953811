import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgFolderOpened = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M-0.00244141 3.5C-0.00244141 2.11929 1.11685 1 2.49756 1H6.92206C7.45568 1 7.94913 1.28349 8.2179 1.74448L8.80566 2.75259C8.89525 2.90625 9.05973 3.00075 9.2376 3.00075H13.4956C14.324 3.00075 14.9956 3.67232 14.9956 4.50075V5.99316H16.0011V12.5C16.0011 13.8807 14.8818 15 13.5011 15H2.49756C1.11685 15 -0.00244141 13.8807 -0.00244141 12.5V3.5ZM13.9956 5.99316V4.50075C13.9956 4.22461 13.7717 4.00075 13.4956 4.00075H9.2376C8.70398 4.00075 8.21054 3.71726 7.94177 3.25627L7.35401 2.24816C7.26442 2.0945 7.09994 2 6.92206 2H2.49756C1.66913 2 0.997559 2.67157 0.997559 3.5V12.5C0.997559 13.3144 1.64659 13.9772 2.45563 13.9994L4.47367 7.07355C4.66021 6.43336 5.24697 5.99316 5.91378 5.99316H13.9956ZM3.49705 14H13.5011C14.3295 14 15.0011 13.3284 15.0011 12.5V6.99316H5.91378C5.69151 6.99316 5.49592 7.1399 5.43375 7.35329L3.49705 14Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgFolderOpened.propTypes = iconPropTypes;
