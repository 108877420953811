import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgChevronDownCircle = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M11.8499 7.21411C12.0472 7.02087 12.0504 6.70431 11.8572 6.50704C11.6639 6.30978 11.3474 6.30652 11.1501 6.49975L8 9.58558L4.84989 6.49975C4.65263 6.30652 4.33606 6.30978 4.14282 6.50704C3.94958 6.70431 3.95285 7.02087 4.15011 7.21411L7.65011 10.6427C7.8445 10.8331 8.15549 10.8331 8.34989 10.6427L11.8499 7.21411Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M16 8C16 3.58171 12.4183 0 8 0C3.58171 0 0 3.58171 0 8C0 12.4183 3.58171 16 8 16C12.4183 16 16 12.4183 16 8ZM15 8C15 4.134 11.866 0.999999 8 0.999999C4.134 0.999999 1 4.134 1 8C1 11.866 4.134 15 8 15C11.866 15 15 11.866 15 8Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgChevronDownCircle.propTypes = iconPropTypes;
