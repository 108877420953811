import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgTag = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.53093 2L2.39348 2.00014C2.24855 2.00014 2.16203 2.04842 2.10983 2.1039C2.05505 2.16211 2 2.26961 2 2.44961V7.19695C2 7.33749 2.02971 7.40796 2.12414 7.51031L8.84886 13.957C8.85505 13.963 8.86109 13.9691 8.86697 13.9753C8.87591 13.9848 8.88315 13.9915 8.88867 13.9961L8.89077 13.9954C8.91934 13.985 8.96753 13.9597 9.03573 13.9063C9.10255 13.854 9.17565 13.7849 9.25484 13.7007L9.25825 13.6971L13.8693 8.89398C14.0441 8.70678 14.0436 8.43832 13.8678 8.25157L13.8668 8.2505L8.14732 2.1367C8.14232 2.13353 8.12983 2.12603 8.10687 2.11563C8.06269 2.0956 8.00161 2.07467 7.92744 2.05586C7.77561 2.01734 7.61873 2 7.53093 2ZM8.90171 14.0054L8.90024 14.0046L8.90171 14.0054ZM2.39347 1.00014L7.53091 1C7.71115 1 7.9509 1.03013 8.17334 1.08656C8.28631 1.11522 8.40678 1.15363 8.51971 1.20482C8.62685 1.25339 8.75911 1.32757 8.86697 1.44219L8.86798 1.44326L14.5961 7.56626C15.134 8.13857 15.1344 9.00873 14.5961 9.58082L14.5927 9.58445L9.98132 14.3879C9.79471 14.586 9.53765 14.8247 9.23104 14.9357C9.06573 14.9955 8.87118 15.0238 8.66451 14.9757C8.46201 14.9286 8.29001 14.8181 8.14863 14.671L1.42203 8.22251C1.41583 8.21657 1.40979 8.21047 1.40391 8.20422C1.14731 7.93153 1 7.62217 1 7.19695V2.44961C1 2.05993 1.12365 1.69269 1.38158 1.41859C1.64209 1.14175 2.00233 1.00014 2.39347 1.00014Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M5.97949 6.99201C6.53178 6.99201 6.97949 6.5443 6.97949 5.99201C6.97949 5.43973 6.53178 4.99201 5.97949 4.99201C5.42721 4.99201 4.97949 5.43973 4.97949 5.99201C4.97949 6.5443 5.42721 6.99201 5.97949 6.99201ZM7.97949 5.99201C7.97949 7.09658 7.08406 7.99201 5.97949 7.99201C4.87492 7.99201 3.97949 7.09658 3.97949 5.99201C3.97949 4.88744 4.87492 3.99201 5.97949 3.99201C7.08406 3.99201 7.97949 4.88744 7.97949 5.99201Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgTag.propTypes = iconPropTypes;
