import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgRecaptcha = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M4.11993 10.7759C4.35315 10.9193 4.65983 10.8433 4.80491 10.6061 4.95 10.369 4.87856 10.0605 4.64534 9.91719L2.83351 8.80349C2.71955 8.73344 2.58178 8.71319 2.4515 8.74735 2.32121 8.78151 2.20946 8.86716 2.14165 8.98486L1.06337 10.8562C.924571 11.0971 1.00419 11.4034 1.24121 11.5404 1.47823 11.6773 1.78289 11.5931 1.92168 11.3522L2.35951 10.5923C3.08583 13.1323 5.36467 15 8.08286 15 11.1397 15 13.6409 12.6379 14.0032 9.61394 14.0361 9.33977 13.8409 9.09082 13.5672 9.05791 13.2935 9.025 13.0451 9.22058 13.0122 9.49476 12.7065 12.0462 10.6048 14 8.08286 14 5.82545 14 3.90478 12.4347 3.30741 10.2765L4.11993 10.7759ZM6.58981 4.95394C6.3626 4.8002 6.30418 4.48913 6.45932 4.25913L7.02628 3.41859C5.21343 3.8039 3.75293 5.21356 3.24946 7.05213 3.17653 7.31843 2.90194 7.47509 2.63614 7.40203 2.37034 7.32896 2.21398 7.05386 2.2869 6.78755 2.88229 4.61332 4.61457 2.91831 6.79208 2.44567L6.05344 1.91417C5.83075 1.75393 5.7812 1.44122 5.94277 1.2157 6.10433.990187 6.41582.937266 6.6385 1.0975L8.36848 2.34233C8.58668 2.49934 8.63936 2.80365 8.48734 3.02902L7.28211 4.81584C7.12697 5.04584 6.81702 5.10767 6.58981 4.95394ZM11.2342 6.57762C11.3603 6.3292 11.6597 6.22841 11.9031 6.3525L12.7562 6.78754C12.2805 5.46128 11.2925 4.39562 10.0467 3.84466 9.79454 3.73314 9.68037 3.43795 9.79168 3.18533 9.90298 2.9327 10.1976 2.81831 10.4498 2.92983 11.9314 3.58512 13.0999 4.83848 13.6744 6.39137L14.0527 5.59991C14.1729 5.34856 14.47 5.2405 14.7163 5.35855 14.9626 5.4766 15.0649 5.77606 14.9448 6.02741L14.0114 7.98009C13.9528 8.1027 13.8482 8.19689 13.7213 8.24132 13.6501 8.26626 13.5752 8.27434 13.5022 8.26594 13.3734 8.26451 13.2534 8.21306 13.1642 8.12794L11.4466 7.25212C11.2032 7.12803 11.1081 6.82605 11.2342 6.57762Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgRecaptcha.propTypes = iconPropTypes;
