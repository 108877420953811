import PropTypes, { Requireable } from 'prop-types';
import { Ref, RefObject } from 'react';
import {
  baseColorPropTypeValidator,
  colorPropTypeValidator,
  gradientPropTypeValidator,
  transparentColorPropTypeValidator,
} from './colors.ts';
import {
  enumPropTypeValidator,
  objectKeyPropTypeValidator,
  objectValuePropTypeValidator,
} from './enums.ts';
import {
  cssTypesValidator,
  literalNumberTypeValidator,
  literalStringTypeValidator,
} from './literals.ts';
import { multiplePropTypeValidator } from './multiple.ts';
import { neverPropTypeValidator } from './never.ts';
import { optionalPropTypeValidator } from './optional.ts';

export const CLPropTypes = {
  colors: {
    color: colorPropTypeValidator,
    baseColor: baseColorPropTypeValidator,
    gradient: gradientPropTypeValidator,
    transparentColor: transparentColorPropTypeValidator,
  },
  cssTypes: cssTypesValidator,
  enum: enumPropTypeValidator,
  literalNumber: literalNumberTypeValidator,
  literalString: literalStringTypeValidator,
  never: neverPropTypeValidator,
  multiple: multiplePropTypeValidator,
  objectKey: objectKeyPropTypeValidator,
  objectValue: objectValuePropTypeValidator,
  optional: optionalPropTypeValidator,
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]) as Requireable<Ref<any>>,
  refObject: PropTypes.shape({ current: PropTypes.instanceOf(Element) }) as Requireable<
    RefObject<any>
  >,
} as const;
