import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgOutdent = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.49951 0C1.22337 0 0.999512 0.223363 0.999512 0.498896V2.49448C0.999512 2.77001 1.22337 2.99337 1.49951 2.99337H14.5C14.7762 2.99337 15 2.77001 15 2.49448V0.498896C15 0.223363 14.7762 0 14.5 0H1.49951ZM1.99951 1.99558V0.997791H14V1.99558H1.99951Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M7.50049 4.02235C7.22435 4.02235 7.00049 4.24571 7.00049 4.52124 7.00049 4.79677 7.22435 5.02014 7.50049 5.02014H14.5005C14.7766 5.02014 15.0005 4.79677 15.0005 4.52124 15.0005 4.24571 14.7766 4.02235 14.5005 4.02235H7.50049ZM7.5 9.99634C7.22386 9.99634 7 10.2197 7 10.4952 7 10.7708 7.22386 10.9941 7.5 10.9941H14.5C14.7761 10.9941 15 10.7708 15 10.4952 15 10.2197 14.7761 9.99634 14.5 9.99634H7.5ZM7 7.48831C7 7.21277 7.22386 6.98941 7.5 6.98941H14.5C14.7761 6.98941 15 7.21277 15 7.48831 15 7.76384 14.7761 7.9872 14.5 7.9872H7.5C7.22386 7.9872 7 7.76384 7 7.48831Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1 12.5055C1 12.23 1.22386 12.0066 1.5 12.0066H14.5C14.7762 12.0066 15 12.23 15 12.5055V14.5011C15 14.7766 14.7762 15 14.5 15H1.5C1.22386 15 1 14.7766 1 14.5011V12.5055ZM2 13.0044V14.0022H14V13.0044H2Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M3.0786 10.7672C3.23178 10.9965 3.54221 11.0584 3.77198 10.9056C4.00174 10.7527 4.06383 10.443 3.91065 10.2137L2.09946 7.50101L3.91065 4.78047C4.06383 4.55121 4.00174 4.24146 3.77198 4.08862C3.54221 3.93578 3.23178 3.99773 3.0786 4.22699L1.08251 7.22427C0.970543 7.39185 0.970544 7.61017 1.08251 7.77775L3.0786 10.7672Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgOutdent.propTypes = iconPropTypes;
