import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgWindows = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M14.8421 1.1393C14.9428 1.23381 14.9999 1.36578 14.9999 1.50391V14.5039C14.9999 14.6416 14.9431 14.7732 14.8429 14.8677C14.7428 14.9622 14.608 15.0111 14.4706 15.003L7.47066 14.5914L7.46858 14.5912L1.47272 14.2637C1.20758 14.2492 1.00001 14.03 1 13.7644L1 2.2504C1.00001 1.98398 1.20892 1.76378 1.47501 1.75046L7.46955 1.45045L14.4682 1.00492C14.606 0.996142 14.7413 1.04478 14.8421 1.1393ZM6.99793 2.4753L1.99998 2.72544L1.99999 7.00353H6.99793V2.4753ZM6.99793 8.00353H1.99999L1.99998 13.291L6.99793 13.564V8.00353ZM7.99793 13.6207L13.9999 13.9736V8.00388L7.99793 8.00356V13.6207ZM7.99793 7.00356L13.9999 7.00388V2.03675L7.99793 2.41883V7.00356Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgWindows.propTypes = iconPropTypes;
