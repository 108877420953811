import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgPaperclip = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1.24155 7.96593C-0.461679 9.6214 -0.40499 12.3525 1.3655 13.9375C2.98231 15.3849 5.45622 15.3494 7.02985 13.8562L10.0766 10.9651C10.0821 10.9599 10.0874 10.9546 10.0926 10.9492L15.1391 5.96042C16.287 4.82565 16.287 2.98584 15.1391 1.85107C13.9912 0.716309 12.1301 0.716309 10.9823 1.85107L9.87997 2.94076C9.87046 2.95016 9.86141 2.95985 9.85283 2.96979C9.84385 2.97749 9.83508 2.98556 9.82654 2.994L3.22831 9.51684C2.55609 10.1814 2.55609 11.2588 3.22831 11.9233C3.90053 12.5879 4.99041 12.5879 5.66263 11.9233L11.8385 5.81806C12.0339 5.62482 12.0339 5.31152 11.8385 5.11828C11.643 4.92504 11.3261 4.92504 11.1306 5.11828L4.95476 11.2236C4.67349 11.5016 4.21745 11.5016 3.93617 11.2236C3.6549 10.9455 3.6549 10.4947 3.93618 10.2166L10.5344 3.69378C10.5439 3.68438 10.553 3.67469 10.5615 3.66475C10.5705 3.65705 10.5793 3.64898 10.5878 3.64054L11.6901 2.55085C12.4471 1.80256 13.6743 1.80256 14.4312 2.55085C15.1882 3.29914 15.1882 4.51235 14.4312 5.26064L9.3745 10.2596L6.33663 13.1422C5.14227 14.2755 3.26461 14.3025 2.03747 13.2039C0.693693 12.0009 0.650666 9.92809 1.94339 8.67161L6.75052 3.99926C6.94764 3.80766 6.95032 3.49437 6.75652 3.29951C6.56271 3.10464 6.2458 3.10198 6.04868 3.29358L1.24155 7.96593Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgPaperclip.propTypes = iconPropTypes;
