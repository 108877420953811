import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgParentChildScheme = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M2.99951 0V1H3.99951V0H2.99951ZM5.97852 1.00072 5.97852.000716253 6.97852.000716209 6.97852 1.00072H5.97852ZM.000089673.000847168.0000897167 1.00085 1.00009 1.00085 1.00009.000847124.000089673.000847168ZM0 3.00135V4.00135H1L1 3.00135H0ZM0 7.00488V6.00488H1L1 7.00488H0Z" />
          <path
            fillRule="evenodd"
            d="M15.9999 15.5004C15.9999 15.7765 15.7761 16.0004 15.4999 16.0004L8.50021 16.0004C8.22407 16.0004 8.00021 15.7765 8.00021 15.5004L8.00021 8.50039C8.00021 8.22425 8.22407 8.00039 8.50021 8.00039H15.4999C15.7761 8.00039 15.9999 8.22425 15.9999 8.50039V15.5004ZM9.00021 15.0004H14.9999V9.00039H9.00021V15.0004Z"
            clipRule="evenodd"
          />
          <path d="M3.03101 9.98662V8.98662H4.03101V9.98662H3.03101ZM3.03101 12.0053V13.0053H4.03101V12.0053H3.03101ZM3.00331 7.00488V6.00488H4.00331V7.00488H3.00331ZM6.00331 7.00488V6.00488H7.00331V7.00488H6.00331ZM6.00331 3.00098V4.00098H7.00331V3.00098H6.00331ZM5.97794 13.0053H6.97794V12.0053H5.97794V13.0053Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgParentChildScheme.propTypes = iconPropTypes;
