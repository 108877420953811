import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLCols255025 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
          <path d="M-.000488281 2.5C-.000488281 2.22386.223369 2 .499512 2H2.49951C2.77565 2 2.99951 2.22386 2.99951 2.5V13.5C2.99951 13.7761 2.77565 14 2.49951 14H.499512C.223369 14-.000488281 13.7761-.000488281 13.5V2.5ZM.999512 3V13H1.99951V3H.999512ZM5 2.5C5 2.22386 5.22386 2 5.5 2H10.5C10.7761 2 11 2.22386 11 2.5V13.5C11 13.7761 10.7761 14 10.5 14H5.5C5.22386 14 5 13.7761 5 13.5V2.5ZM6 3V13H10V3H6ZM13.5 2C13.2239 2 13 2.22386 13 2.5V13.5C13 13.7761 13.2239 14 13.5 14H15.5C15.7761 14 16 13.7761 16 13.5V2.5C16 2.22386 15.7761 2 15.5 2H13.5ZM14 13V3H15V13H14Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLCols255025.propTypes = iconPropTypes;
