import { useHover } from '@react-aria/interactions';
import React, { HTMLAttributes, useMemo } from 'react';
import { useOurFocusRing } from '../../../hooks/useOurFocusRing.ts';
import { Icons } from '../../Icons/components/icons.ts';
import { Tooltip } from '../../Tooltip/Tooltip.tsx';
import { TooltipPropsExtension } from '../../_utils/propPrefabs.ts';
import { ButtonIcon } from '../ButtonIcon.tsx';
import { ButtonDisplay } from '../buttonDisplay.ts';
import { ButtonSize } from '../buttonSize.ts';
import { ButtonStyle } from '../buttonStyle.ts';
import { ButtonContext } from '../utils/ButtonContext.ts';
import { shouldWrapperHandleFocus } from '../utils/stylingUtils.ts';
import { IBaseButtonProps } from './BaseButton.ts';
import { StyledButtonContent } from './StyledButtonContent.tsx';
import { StyledButtonWrapper } from './StyledButtonWrapper.tsx';

export interface IInjectedProps extends Omit<HTMLAttributes<HTMLElement>, 'children' | 'tabIndex'> {
  readonly children: JSX.Element;
  readonly $buttonDisplay: ButtonDisplay;
  readonly $buttonStyle: ButtonStyle;
  readonly tabIndex: -1 | undefined;
  readonly destructive?: boolean;
  readonly $size: ButtonSize;
  readonly $isFocusVisible: boolean;
  readonly $activated?: boolean;
}

export interface IBaseButtonComponentProps
  extends TooltipPropsExtension,
    Pick<
      IBaseButtonProps,
      | 'buttonDisplay'
      | 'buttonStyle'
      | 'disableTabulator'
      | 'destructive'
      | 'destructiveIcon'
      | 'activated'
    >,
    Required<Pick<IBaseButtonProps, 'size'>> {
  readonly renderButtonComponent: (injectedProps: IInjectedProps) => JSX.Element;
  readonly disabled?: boolean;
  readonly tooltipPlacement: Required<TooltipPropsExtension>['tooltipPlacement'];
  readonly children: React.ReactNode;
}

export const BaseButtonComponent: React.FC<IBaseButtonComponentProps> = ({
  renderButtonComponent,
  buttonDisplay = 'inline',
  buttonStyle,
  disabled,
  disableTabulator,
  children,
  destructive,
  destructiveIcon = Icons.Bin,
  size,
  tooltipShortcuts,
  tooltipMaxGridUnitsWidth,
  tooltipPlacement,
  tooltipText,
  activated,
}) => {
  // tabIndex is handled via disableTabulator prop and children is defined as button content
  const { focusProps, isFocusVisible } = useOurFocusRing(disabled);
  const { hoverProps, isHovered } = useHover({});

  const buttonContextValue = useMemo(
    () => ({
      buttonStyle,
      buttonSize: size,
    }),
    [buttonStyle, size],
  );

  return (
    <ButtonContext.Provider value={buttonContextValue}>
      <Tooltip
        tooltipText={tooltipText}
        placement={tooltipPlacement}
        shortcuts={tooltipShortcuts}
        maxGridUnitsWidth={tooltipMaxGridUnitsWidth}
        visible={isFocusVisible || isHovered}
      >
        {/* The wrapper is there for the tooltip to work on disabled buttons */}
        <StyledButtonWrapper
          buttonDisplay={buttonDisplay}
          isFocusVisible={shouldWrapperHandleFocus(buttonStyle) && isFocusVisible}
        >
          {renderButtonComponent({
            ...hoverProps,
            children: (
              <StyledButtonContent
                buttonStyle={buttonStyle}
                disabled={disabled}
                destructive={destructive}
                $size={size}
                $activated={activated}
              >
                {destructive && <ButtonIcon icon={destructiveIcon} />}
                {children}
              </StyledButtonContent>
            ),
            $buttonDisplay: buttonDisplay,
            $buttonStyle: buttonStyle,
            tabIndex: disableTabulator ? -1 : undefined,
            destructive,
            $size: size,
            $isFocusVisible: !shouldWrapperHandleFocus(buttonStyle) && isFocusVisible,
            $activated: activated,
            ...focusProps,
          })}
        </StyledButtonWrapper>
      </Tooltip>
    </ButtonContext.Provider>
  );
};
