import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgPuzzle = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M5.36522 2.48835C5.12409 2.7724 5.03429 3.10232 5.03429 3.27273V4.50861C5.03429 4.78476 4.81043 5.00861 4.53429 5.00861H2V14H4.0343V13.2727C4.0343 12.8522 4.21698 12.2958 4.60288 11.8412C5.00497 11.3675 5.63191 11 6.49745 11C7.36086 11 7.99972 11.3654 8.41593 11.8319C8.81555 12.2798 9.02461 12.8381 9.02461 13.2727V14.0072L11.0125 14.0014V11.4936C11.0125 11.2175 11.2363 10.9936 11.5125 10.9936H12.7273C12.8923 10.9936 13.2215 10.9016 13.5081 10.6537C13.7758 10.4221 14 10.0599 14 9.50642C14 8.95199 13.7748 8.58042 13.5038 8.34043C13.2141 8.08395 12.8857 7.98937 12.7273 7.98937H11.5125C11.2363 7.98937 11.0125 7.76551 11.0125 7.48937V5.00861H8.52459C8.24845 5.00861 8.02459 4.78476 8.02459 4.50861V3.27273C8.02459 3.11648 7.92916 2.7884 7.66975 2.49765C7.42692 2.2255 7.0522 2 6.49743 2C5.94479 2 5.59016 2.22337 5.36522 2.48835ZM4.60286 1.84119C5.00495 1.36753 5.6319 1 6.49743 1C7.36085 1 7.9997 1.36541 8.41592 1.83189C8.81554 2.27978 9.0246 2.83807 9.0246 3.27273V4.00861H11.5125C11.7886 4.00861 12.0125 4.23247 12.0125 4.50861V6.98937H12.7273C13.1597 6.98937 13.7177 7.19422 14.1667 7.59172C14.6343 8.00572 15 8.64268 15 9.50642C15 10.3712 14.6333 11.0025 14.1624 11.41C13.7103 11.8011 13.1531 11.9936 12.7273 11.9936H12.0125V14.5C12.0125 14.7756 11.7895 14.9992 11.5139 15L8.52605 15.0086C8.3932 15.009 8.26565 14.9565 8.17157 14.8627C8.07749 14.7689 8.02461 14.6415 8.02461 14.5086V13.2727C8.02461 13.1165 7.92918 12.7884 7.66976 12.4977C7.42694 12.2255 7.05222 12 6.49745 12C5.9448 12 5.59017 12.2234 5.36523 12.4884C5.12411 12.7724 5.0343 13.1023 5.0343 13.2727V14.5C5.0343 14.7761 4.81044 15 4.5343 15H1.5C1.22386 15 1 14.7761 1 14.5V4.50861C1 4.23247 1.22386 4.00861 1.5 4.00861H4.03429V3.27273C4.03429 2.85223 4.21696 2.29579 4.60286 1.84119Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgPuzzle.propTypes = iconPropTypes;
