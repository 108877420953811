/**
 * Custom validation for cssTypes because its using Literal Types.
 * Even though Literal Types is just string OR number we cannot use PropTypes.string | number for it.
 */
export const cssTypesValidator = (props: any, propName: string, componentName: string) => {
  const propType: string = typeof props[propName];
  if (!props[propName] || propType === 'string' || propType === 'number') {
    return null;
  }
  return new Error(
    `Invalid prop ${propName} passed to ${componentName}. Expected valid css type value.`,
  );
};

/**
 * Literal Types custom validator for just number & just string.
 * If Literal Typpe is just string OR number we cannot use PropTypes.string | number for it.
 */
export const literalNumberTypeValidator = (props: any, propName: string, componentName: string) => {
  const propType: string = typeof props[propName];
  if (!props[propName] || propType === 'number') {
    return null;
  }
  return new Error(
    `Invalid prop ${propName} passed to ${componentName}. Expected valid literal type number value.`,
  );
};

export const literalStringTypeValidator = (props: any, propName: string, componentName: string) => {
  const propType: string = typeof props[propName];
  if (!props[propName] || propType === 'string') {
    return null;
  }
  return new Error(
    `Invalid prop ${propName} passed to ${componentName}. Expected valid literal type string value.`,
  );
};
