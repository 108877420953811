import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgF = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M3.51337 0.000183306C3.50884 6.13615e-05 3.5043 0 3.49974 0C3.22428 0 3.00098 0.223858 3.00098 0.5V15.5C3.00098 15.7761 3.22428 16 3.49974 16C3.77519 16 3.9985 15.7761 3.9985 15.5V8H9.48388C9.75934 8 9.98265 7.77614 9.98265 7.5C9.98265 7.22386 9.75934 7 9.48388 7H3.9985V1H13.5022C13.7777 1 14.001 0.776142 14.001 0.5C14.001 0.223858 13.7777 0 13.5022 0H3.52701C3.52245 0 3.51791 6.13632e-05 3.51337 0.000183306Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgF.propTypes = iconPropTypes;
