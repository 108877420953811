import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgClipboardList = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M8.01904 5.49219C8.01904 5.21604 8.2429 4.99219 8.51904 4.99219H10.519C10.7952 4.99219 11.019 5.21604 11.019 5.49219 11.019 5.76833 10.7952 5.99219 10.519 5.99219H8.51904C8.2429 5.99219 8.01904 5.76833 8.01904 5.49219ZM5.51807 4.99219C5.24192 4.99219 5.01807 5.21604 5.01807 5.49219 5.01807 5.76833 5.24192 5.99219 5.51807 5.99219H6.51807C6.79421 5.99219 7.01807 5.76833 7.01807 5.49219 7.01807 5.21604 6.79421 4.99219 6.51807 4.99219H5.51807ZM8.01904 7.47412C8.01904 7.19798 8.2429 6.97412 8.51904 6.97412H10.519C10.7952 6.97412 11.019 7.19798 11.019 7.47412 11.019 7.75026 10.7952 7.97412 10.519 7.97412H8.51904C8.2429 7.97412 8.01904 7.75026 8.01904 7.47412ZM5.51807 6.97412C5.24192 6.97412 5.01807 7.19798 5.01807 7.47412 5.01807 7.75026 5.24192 7.97412 5.51807 7.97412H6.51807C6.79421 7.97412 7.01807 7.75026 7.01807 7.47412 7.01807 7.19798 6.79421 6.97412 6.51807 6.97412H5.51807ZM8.01904 9.48877C8.01904 9.21263 8.2429 8.98877 8.51904 8.98877H10.519C10.7952 8.98877 11.019 9.21263 11.019 9.48877 11.019 9.76491 10.7952 9.98877 10.519 9.98877H8.51904C8.2429 9.98877 8.01904 9.76491 8.01904 9.48877ZM5.51807 8.98877C5.24192 8.98877 5.01807 9.21263 5.01807 9.48877 5.01807 9.76491 5.24192 9.98877 5.51807 9.98877H6.51807C6.79421 9.98877 7.01807 9.76491 7.01807 9.48877 7.01807 9.21263 6.79421 8.98877 6.51807 8.98877H5.51807ZM8.01904 11.4883C8.01904 11.2121 8.2429 10.9883 8.51904 10.9883H10.519C10.7952 10.9883 11.019 11.2121 11.019 11.4883 11.019 11.7644 10.7952 11.9883 10.519 11.9883H8.51904C8.2429 11.9883 8.01904 11.7644 8.01904 11.4883ZM5.51807 10.9883C5.24192 10.9883 5.01807 11.2121 5.01807 11.4883 5.01807 11.7644 5.24192 11.9883 5.51807 11.9883H6.51807C6.79421 11.9883 7.01807 11.7644 7.01807 11.4883 7.01807 11.2121 6.79421 10.9883 6.51807 10.9883H5.51807Z" />
          <path
            fillRule="evenodd"
            d="M5.51416 0.00195312C5.23802 0.00195312 5.01416 0.225811 5.01416 0.501953V1.00195H2.5C2.22386 1.00195 2 1.22581 2 1.50195V15.502C2 15.7781 2.22386 16.002 2.5 16.002H13.4961C13.7723 16.002 13.9961 15.7781 13.9961 15.502V1.50195C13.9961 1.22581 13.7723 1.00195 13.4961 1.00195H11.0169V0.501953C11.0169 0.225811 10.793 0.00195312 10.5169 0.00195312H5.51416ZM11.0169 2.00195V2.50195C11.0169 2.7781 10.793 3.00195 10.5169 3.00195H5.51416C5.23802 3.00195 5.01416 2.7781 5.01416 2.50195V2.00195H3V15.002H12.9961V2.00195H11.0169ZM10.0169 2.00195H6.01416V1.00195H10.0169V2.00195Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgClipboardList.propTypes = iconPropTypes;
