import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgNewspaper = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3.49561 2.01758C3.21946 2.01758 2.99561 2.24144 2.99561 2.51758V9.51439C2.99561 9.79053 3.21946 10.0144 3.49561 10.0144H7.49561C7.77175 10.0144 7.99561 9.79053 7.99561 9.51439V2.51758C7.99561 2.24144 7.77175 2.01758 7.49561 2.01758H3.49561ZM3.99561 9.01439V3.01758H6.99561V9.01439H3.99561Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M9.00977 4.49707C9.00977 4.22093 9.23362 3.99707 9.50977 3.99707H12.5108C12.7869 3.99707 13.0108 4.22093 13.0108 4.49707 13.0108 4.77321 12.7869 4.99707 12.5108 4.99707H9.50977C9.23362 4.99707 9.00977 4.77321 9.00977 4.49707ZM9.50977 6.01758C9.23362 6.01758 9.00977 6.24144 9.00977 6.51758 9.00977 6.79372 9.23362 7.01758 9.50977 7.01758H12.5108C12.7869 7.01758 13.0108 6.79372 13.0108 6.51758 13.0108 6.24144 12.7869 6.01758 12.5108 6.01758H9.50977ZM9.00977 8.52832C9.00977 8.25218 9.23362 8.02832 9.50977 8.02832H12.5108C12.7869 8.02832 13.0108 8.25218 13.0108 8.52832 13.0108 8.80446 12.7869 9.02832 12.5108 9.02832H9.50977C9.23362 9.02832 9.00977 8.80446 9.00977 8.52832ZM3.49561 13.0088C3.21946 13.0088 2.99561 13.2326 2.99561 13.5088 2.99561 13.7849 3.21946 14.0088 3.49561 14.0088H12.4956C12.7717 14.0088 12.9956 13.7849 12.9956 13.5088 12.9956 13.2326 12.7717 13.0088 12.4956 13.0088H3.49561Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M2.5 0C1.67157 0 1 0.671573 1 1.5V14.5C1 15.3284 1.67157 16 2.5 16H13.5C14.3284 16 15 15.3284 15 14.5V1.5C15 0.671573 14.3284 0 13.5 0H2.5ZM2 1.5C2 1.22386 2.22386 1 2.5 1H13.5C13.7761 1 14 1.22386 14 1.5V14.5C14 14.7761 13.7761 15 13.5 15H2.5C2.22386 15 2 14.7761 2 14.5V1.5Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgNewspaper.propTypes = iconPropTypes;
