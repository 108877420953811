import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgMessages = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M11.0015 0.996099C11.5537 0.996099 12.0015 1.44317 12.0015 1.99465V9.98307C12.0015 10.5346 11.5538 10.9816 11.0015 10.9816H2.00146C1.44918 10.9816 1.00146 10.5346 1.00146 9.98307V1.99465C1.00146 1.57461 1.2612 1.21514 1.62904 1.06765C1.72169 1.01185 1.82917 0.988399 1.93449 0.998303C1.95663 0.996841 1.97896 0.996099 2.00146 0.996099H11.0015ZM2.00146 2.2648V9.98307H11.0015V2.06197L6.73301 5.87637C6.54357 6.04452 6.25809 6.04452 6.06865 5.87637L2.00146 2.2648ZM3.20231 1.99465L9.57211 1.99465L6.40083 4.83519L3.20231 1.99465Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M13.0095 5.51201C13.0095 5.23627 13.2333 5.01274 13.5095 5.01274H14.0015C14.5538 5.01274 15.0015 5.4598 15.0015 6.01129V13.9975C15.0015 14.549 14.5538 14.9961 14.0015 14.9961H5.00146C4.44918 14.9961 4.00146 14.549 4.00146 13.9975V12.4962C4.00146 12.2205 4.22532 11.997 4.50146 11.997C4.77761 11.997 5.00146 12.2205 5.00146 12.4962V13.9975H14.0015V6.01129H13.5095C13.2333 6.01129 13.0095 5.78775 13.0095 5.51201Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgMessages.propTypes = iconPropTypes;
