import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgDotsVertical = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4 1V3.98359H6.98396V1H4ZM4.90139 1.90171H6.08257V3.08189H4.90139V1.90171ZM4 7.01872V10.0023H6.98396V7.01872H4ZM4.90139 7.92043H6.08257V9.10061H4.90139V7.92043ZM4 13.0164H6.98396V16H4V13.0164ZM4.90139 13.9181V15.0983H6.08257V13.9181H4.90139ZM9.01604 1V3.98359H12V1H9.01604ZM9.91743 1.90171H11.0986V3.08189H9.91743V1.90171ZM9.01604 7.01872H12V10.0023H9.01604V7.01872ZM9.91743 7.92043V9.10061H11.0986V7.92043H9.91743ZM9.01604 13.0164V16H12V13.0164H9.01604ZM9.91743 13.9181H11.0986V15.0983H9.91743V13.9181Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgDotsVertical.propTypes = iconPropTypes;
