import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgTwoRectanglesStackedTimes = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M11.3064 4.35355C11.5016 4.15829 11.8182 4.15829 12.0135 4.35355C12.2087 4.54882 12.2087 4.8654 12.0135 5.06066L9.89058 7.18355L12.0078 9.30074C12.203 9.49601 12.203 9.81259 12.0078 10.0079C11.8125 10.2031 11.4959 10.2031 11.3007 10.0079L9.18347 7.89065L7.06062 10.0135C6.86536 10.2088 6.54877 10.2088 6.35351 10.0135C6.15825 9.81825 6.15825 9.50166 6.35351 9.3064L8.47637 7.18355L6.35349 5.06068C6.15823 4.86541 6.15823 4.54883 6.35349 4.35357C6.54876 4.15831 6.86534 4.15831 7.0606 4.35357L9.18347 6.47644L11.3064 4.35355Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M5.5 1C4.67157 1 4 1.67157 4 2.5V3H3.5C2.67157 3 2 3.67157 2 4.5V13.5C2 14.3284 2.67157 15 3.5 15H10.5C11.3284 15 12 14.3284 12 13.5V13H12.5C13.3284 13 14 12.3284 14 11.5V2.5C14 1.67157 13.3284 1 12.5 1H5.5ZM5 2.5C5 2.22386 5.22386 2 5.5 2H12.5C12.7761 2 13 2.22386 13 2.5V11.5C13 11.7761 12.7761 12 12.5 12H5.5C5.22386 12 5 11.7761 5 11.5V2.5ZM11 13H5.5C4.67157 13 4 12.3284 4 11.5V4H3.5C3.22386 4 3 4.22386 3 4.5V13.5C3 13.7761 3.22386 14 3.5 14H10.5C10.7761 14 11 13.7761 11 13.5V13Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgTwoRectanglesStackedTimes.propTypes = iconPropTypes;
