import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLCols7030 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M9.50087 2C9.77704 2 10.0009 2.22384 10.0009 2.49996V13.4996C10.0009 13.7757 9.77704 13.9995 9.50087 13.9995H.500046C.223878 13.9995 0 13.7757 0 13.4996V2.49996C0 2.22384.223878 2 .500046 2H9.50087ZM9.00082 12.9996V2.99991H1.00009V12.9996H9.00082ZM15.5 2.00049C15.7761 2.00049 16 2.22433 16 2.50044V13.5C16 13.7762 15.7761 14 15.5 14H12.4997C12.2235 14 11.9996 13.7762 11.9996 13.5V2.50044C11.9996 2.22433 12.2235 2.00049 12.4997 2.00049H15.5ZM14.9999 13.0001V3.0004H12.9997V13.0001H14.9999Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLCols7030.propTypes = iconPropTypes;
