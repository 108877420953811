import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgArrowsH = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            d="M3.85355 5.14657C4.04882 5.34183 4.04882 5.65841 3.85355 5.85368L1.70711 8.00012L14.2929 8.00012L12.1464 5.85368C11.9512 5.65841 11.9512 5.34183 12.1464 5.14657C12.3417 4.95131 12.6583 4.95131 12.8536 5.14657L15.8536 8.14657C16.0488 8.34183 16.0488 8.65841 15.8536 8.85368L12.8536 11.8537C12.6583 12.0489 12.3417 12.0489 12.1464 11.8537C11.9512 11.6584 11.9512 11.3418 12.1464 11.1466L14.2929 9.00012L1.70711 9.00012L3.85355 11.1466C4.04882 11.3418 4.04882 11.6584 3.85355 11.8537C3.65829 12.0489 3.34171 12.0489 3.14645 11.8537L0.146447 8.85368C-0.0488155 8.65841 -0.0488155 8.34183 0.146447 8.14657L3.14645 5.14657C3.34171 4.95131 3.65829 4.95131 3.85355 5.14657Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" transform="rotate(-90 8 8)" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgArrowsH.propTypes = iconPropTypes;
