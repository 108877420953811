import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLCols2 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
          <path d="M.500977 2.00342C.224834 2.00342.000976562 2.22728.000976562 2.50342V13.5042C.000976562 13.7804.224834 14.0042.500977 14.0042H6.50479C6.78093 14.0042 7.00479 13.7804 7.00479 13.5042V2.50342C7.00479 2.22728 6.78093 2.00342 6.50479 2.00342H.500977ZM1.00098 13.0042V3.00342H6.00479V13.0042H1.00098ZM9.49902 2.00342C9.22288 2.00342 8.99902 2.22728 8.99902 2.50342V13.5043C8.99902 13.7804 9.22288 14.0043 9.49902 14.0043H15.5031C15.7792 14.0043 16.0031 13.7804 16.0031 13.5043V2.50342C16.0031 2.22728 15.7792 2.00342 15.5031 2.00342H9.49902ZM9.99902 13.0043V3.00342H15.0031V13.0043H9.99902Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLCols2.propTypes = iconPropTypes;
