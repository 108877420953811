import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLCols206020 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
          <path d="M-.000488281 2.5C-.000488281 2.22386.223369 2 .499512 2H2.49951C2.77565 2 2.99951 2.22386 2.99951 2.5V13.5C2.99951 13.7761 2.77565 14 2.49951 14H.499512C.223369 14-.000488281 13.7761-.000488281 13.5V2.5ZM.999512 3V13H1.99951V3H.999512ZM4.0127 2.5C4.0127 2.22386 4.23655 2 4.5127 2H11.5127C11.7888 2 12.0127 2.22386 12.0127 2.5V13.5C12.0127 13.7761 11.7888 14 11.5127 14H4.5127C4.23655 14 4.0127 13.7761 4.0127 13.5V2.5ZM5.0127 3V13H11.0127V3H5.0127ZM13.4976 2C13.2214 2 12.9976 2.22386 12.9976 2.5V13.5C12.9976 13.7761 13.2214 14 13.4976 14H15.4976C15.7737 14 15.9976 13.7761 15.9976 13.5V2.5C15.9976 2.22386 15.7737 2 15.4976 2H13.4976ZM13.9976 13V3H14.9976V13H13.9976Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLCols206020.propTypes = iconPropTypes;
