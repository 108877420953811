import { useEffect, useState } from 'react';

enum Phase {
  NotStarted = 'notStarted',
  Started = 'started',
  Done = 'done',
  Failed = 'failed',
}

type UseOneTimeAction = (
  action: () => void | Promise<void>,
  canStart?: boolean,
) => [isDone: boolean, hasFailed: boolean];

/**
 * Use this hook in situations when you need to run an action only once during a component lifetime after a condition is met.
 * @param action - the action which will be called only one time
 * @param canStart (optional) - specifies if the action can be started, defaults to true
 *
 * Returns a tuple [isDone, hasFailed]:
 * - isDone - boolean value that means action was done
 * - hasFailed - boolean value that means action failed
 */
export const useOneTimeAction: UseOneTimeAction = (action, canStart = true) => {
  const [phase, setPhase] = useState<Phase>(Phase.NotStarted);
  const shouldFireAction = canStart && phase === Phase.NotStarted;

  useEffect(() => {
    if (shouldFireAction) {
      setPhase(Phase.Started);
      (async () => await action())()
        .then(() => setPhase(Phase.Done))
        .catch(() => setPhase(Phase.Failed));
    }
  }, [action, shouldFireAction]);

  return [phase === Phase.Done, phase === Phase.Failed];
};
