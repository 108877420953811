import classNames from 'classnames';
import React, { ChangeEventHandler, useEffect, useRef } from 'react';
import { getDataAttributeProps } from '../utils/dataAttributes/DataUiAttributes.ts';
import { OptionVariant } from './Options/createOptionComponent.tsx';

export interface ToggleProps {
  readonly id: Uuid;
  readonly accessibleLabel?: string;
  readonly autoFocus?: boolean;
  readonly checked?: boolean;
  readonly className?: string;
  readonly dataId?: string;
  readonly disabled?: boolean;
  readonly inputClassName?: string;
  readonly label?: React.ReactNode;
  // Please, use "labelClassName" to be able to click around the checkbox.
  readonly labelClassName?: string;
  readonly name?: string;
  readonly showFullSize?: boolean;
  readonly spanClassName?: string;
  readonly tabIndex?: number;
  readonly type?: string;
  readonly variant?: OptionVariant;
  readonly onBlur?: React.FocusEventHandler<HTMLInputElement>;
  readonly onFocus?: React.FocusEventHandler<HTMLInputElement>;
  readonly onToggle: (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLInputElement>,
  ) => void;
}

export const Toggle: React.FC<ToggleProps> = (props) => {
  const {
    id,
    accessibleLabel,
    autoFocus,
    checked = false,
    className,
    dataId,
    disabled,
    inputClassName,
    label,
    labelClassName,
    name,
    showFullSize,
    spanClassName,
    tabIndex,
    type,
    variant,
    onBlur,
    onFocus,
    onToggle,
    ...rest
  } = props;

  /* Radio buttons do not allow deselection,
   * thus the following two functions were introduced.
   */
  const onLabelClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (onToggle) {
      onToggle(event);
    }

    if (event.shiftKey) {
      // Stop unintentional browser text selection
      event.preventDefault();
    }
  };

  const onKeyboardClick = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === ' ') {
      if (onToggle) {
        onToggle(event);
      }
      event.preventDefault();
    }
  };

  const ignoreOnChange: ChangeEventHandler<HTMLInputElement> = (event): void => {
    // Input is controlled with onClick to capture keys and to support radio option deselection
    event.stopPropagation();
  };

  const optionalProps = !disabled && {
    onFocus,
    onBlur,
    autoFocus,
    onKeyUp: onKeyboardClick,
    onChange: ignoreOnChange,
  };

  const labelDataAttributes = getDataAttributeProps(rest);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checked) {
      inputRef.current?.setAttribute('checked', 'true');
    } else {
      inputRef.current?.removeAttribute('checked');
    }
  }, [checked]);

  return (
    <>
      <input
        id={id}
        ref={inputRef}
        tabIndex={tabIndex}
        name={name}
        className={classNames('toggle__input--hidden', inputClassName)}
        type={type}
        disabled={disabled}
        checked={checked}
        {...optionalProps}
      />
      <label
        htmlFor={id}
        className={labelClassName}
        data-id={dataId}
        data-hj-suppress=""
        // Bind onClick to the label to make sure shift-click works in FireFox
        onClick={disabled ? undefined : onLabelClick}
        {...labelDataAttributes}
      >
        {accessibleLabel && <span className="sr-only">{accessibleLabel}</span>}
        {label && <span className={spanClassName}>{label}</span>}
      </label>
    </>
  );
};

Toggle.displayName = 'Toggle';
