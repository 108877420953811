import PropTypes from 'prop-types';
import React, { useContext, WeakValidationMap } from 'react';
import styled from 'styled-components';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { getDataUiComponentAttribute } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { Badge, maxBadgeValue } from '../../Badge/Badge.tsx';
import { QuinaryButtonStyleContext } from './QuinaryButtonStyleContext.tsx';

interface IQuinaryButtonBadgeProps {
  readonly badgeValue?: number;
}

interface IQuinaryButtonBadgePositionerProps {
  readonly withValue: boolean;
}

const quinaryButtonBadgePropTypes: WeakValidationMap<IQuinaryButtonBadgeProps> = {
  badgeValue: PropTypes.number,
};

const getBadgeSpacing = ({ withValue }: IQuinaryButtonBadgePositionerProps) =>
  withValue ? px(-Spacing.XS) : 0;

const StyledQuinaryButtonBadgePositioner = styled.div<IQuinaryButtonBadgePositionerProps>`
  position: absolute;
  top: ${getBadgeSpacing};
  right: ${getBadgeSpacing};
`;

export const QuinaryButtonBadge = React.forwardRef<HTMLDivElement, IQuinaryButtonBadgeProps>(
  ({ badgeValue, ...otherProps }, forwardedRef) => {
    const buttonStyle = useContext(QuinaryButtonStyleContext);
    if (buttonStyle === undefined) {
      throw new Error('QuinaryButton.Badge must be used within a QuinaryButton component');
    }

    return (
      <StyledQuinaryButtonBadgePositioner
        withValue={!!badgeValue && badgeValue <= maxBadgeValue}
        {...getDataUiComponentAttribute(QuinaryButtonBadge)}
      >
        <Badge
          badgeStyle={buttonStyle === 'default' ? 'default' : 'inverse'}
          value={badgeValue}
          ref={forwardedRef}
          {...otherProps}
        />
      </StyledQuinaryButtonBadgePositioner>
    );
  },
);

QuinaryButtonBadge.displayName = 'QuinaryButtonBadge';
QuinaryButtonBadge.propTypes = quinaryButtonBadgePropTypes;
