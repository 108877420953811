import PropTypes, { Requireable } from 'prop-types';

type IEnumObject<TEnumValue> = ReadonlyRecord<string | number, string | TEnumValue>;

export const enumPropTypeValidator = <TEnum>(
  enumObject: IEnumObject<TEnum>,
): Requireable<TEnum> => {
  const allowedValues = Object.entries(enumObject)
    .filter((entry) => typeof entry[0] === 'string')
    .map((entry) => entry[1]) as ReadonlyArray<TEnum>;
  return PropTypes.oneOf(Object.values(allowedValues));
};

export const objectValuePropTypeValidator = <TAllowedValues>(
  propItem: ReadonlyRecord<string, TAllowedValues>,
): Requireable<TAllowedValues> => {
  return PropTypes.oneOf(Object.values(propItem));
};

export const objectKeyPropTypeValidator = <TKey extends string>(
  propItem: ReadonlyRecord<TKey, unknown>,
): Requireable<TKey> => {
  return PropTypes.oneOf(Object.keys(propItem));
};
