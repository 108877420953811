import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgTwoSquaresWithLines = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M7.49994 7.99968C7.2238 7.99968 6.99994 8.22353 6.99994 8.49968 6.99994 8.77582 7.2238 8.99968 7.49994 8.99968H11.4999C11.7761 8.99968 11.9999 8.77582 11.9999 8.49968 11.9999 8.22353 11.7761 7.99968 11.4999 7.99968H7.49994ZM7.49994 10.9997C7.2238 10.9997 6.99994 11.2235 6.99994 11.4997 6.99994 11.7758 7.2238 11.9997 7.49994 11.9997H11.4999C11.7761 11.9997 11.9999 11.7758 11.9999 11.4997 11.9999 11.2235 11.7761 10.9997 11.4999 10.9997H7.49994Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4 11.9646C2.30385 11.7219 1 10.2632 1 8.5V4.5C1 2.567 2.567 1 4.5 1H8.5C10.2632 1 11.7219 2.30385 11.9646 4H12C13.6569 4 15 5.34315 15 7V14C15 14.5523 14.5523 15 14 15H7C5.34315 15 4 13.6569 4 12V11.9646ZM2 4.5C2 3.11929 3.11929 2 4.5 2H8.5C9.70948 2 10.7184 2.85888 10.95 4H7C5.34315 4 4 5.34315 4 7V10.95C2.85888 10.7184 2 9.70948 2 8.5V4.5ZM12 5H7C5.89543 5 5 5.89543 5 7V12C5 13.1046 5.89543 14 7 14H14V7C14 5.89543 13.1046 5 12 5Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgTwoSquaresWithLines.propTypes = iconPropTypes;
