import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgThreeDotsVertical = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.00049 15.999H9.99929V12.999H7.00049V15.999ZM8.00049 14.999V13.999H8.99929V14.999H8.00049ZM7.00049 9.99902 7.00049 6.99902H9.99929V9.99902H7.00049ZM8.00049 7.99902V8.99902H8.99929V7.99902H8.00049ZM7.00049 3.99902V.999023H9.99929V3.99902H7.00049ZM8.00049 1.99902V2.99902H8.99929V1.99902H8.00049Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgThreeDotsVertical.propTypes = iconPropTypes;
