import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgHome = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M9.07971 1.45873C8.48984 0.847089 7.51016 0.847088 6.92029 1.45873L1.42029 7.16178C1.15067 7.44136 1 7.81463 1 8.20305V14.8356C1 15.4799 1.52233 16.0022 2.16667 16.0022H13.8333C14.4777 16.0022 15 15.4799 15 14.8356V8.20305C15 7.81463 14.8493 7.44136 14.5797 7.16178L9.07971 1.45873ZM7.6401 2.15291C7.83672 1.94903 8.16328 1.94903 8.3599 2.15291L13.8599 7.85596C13.9498 7.94915 14 8.07358 14 8.20305V14.8356C14 14.9276 13.9254 15.0022 13.8333 15.0022H11V9.51221C11 9.23607 10.7761 9.01221 10.5 9.01221H5.5C5.22386 9.01221 5 9.23607 5 9.51221V15.0022H2.16667C2.07462 15.0022 2 14.9276 2 14.8356V8.20305C2 8.07358 2.05022 7.94915 2.1401 7.85596L7.6401 2.15291ZM6 15.0022H10V10.0122H6V15.0022Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgHome.propTypes = iconPropTypes;
