import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgRectangleA = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
          <path d="M6.2685 10.0029L5.46841 12.1365C5.37145 12.395 5.08324 12.526 4.82468 12.4291C4.56612 12.3321 4.43512 12.0439 4.53208 11.7854L7.53208 3.78535C7.60526 3.5902 7.79182 3.46091 8.00024 3.46091C8.20867 3.46091 8.39523 3.5902 8.46841 3.78535L11.4684 11.7854C11.5654 12.0439 11.4344 12.3321 11.1758 12.4291C10.9172 12.526 10.629 12.395 10.5321 12.1365L9.73207 10.0031L6.2685 10.0029ZM9.35706 9.0031L6.64349 9.00294L8.00024 5.38492L9.35706 9.0031Z" />
          <path d="M3.49972 0.000204027L12.4997 3.92902e-10C13.3282 -1.87453e-05 13.9998 0.67156 13.9998 1.5V14.5C13.9998 15.3284 13.3282 16 12.4998 16H3.49976C2.67133 16 1.99976 15.3284 1.99976 14.5V1.5002C1.99976 0.67179 2.67131 0.000222803 3.49972 0.000204027ZM12.4997 1L3.49974 1.0002C3.22361 1.00021 2.99976 1.22407 2.99976 1.5002V14.5C2.99976 14.7761 3.22361 15 3.49976 15H12.4998C12.7759 15 12.9998 14.7761 12.9998 14.5V1.5C12.9998 1.22385 12.7759 0.999994 12.4997 1Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgRectangleA.propTypes = iconPropTypes;
