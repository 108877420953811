import PropTypes from 'prop-types';
import React, { MouseEventHandler, PropsWithChildren } from 'react';

interface IOutwardLinkProps {
  readonly className?: string;
  readonly contentEditable?: boolean;
  readonly href: string;
  readonly onClick?: MouseEventHandler<HTMLAnchorElement>;
  readonly stopClickPropagation?: boolean;
  readonly title?: string;
}

const propTypes: PropTypeMap<IOutwardLinkProps> = {
  className: PropTypes.string,
  contentEditable: PropTypes.bool,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  stopClickPropagation: PropTypes.bool,
  title: PropTypes.string,
};

export const OutwardLink = React.forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<IOutwardLinkProps>
>(
  (
    {
      children,
      className,
      contentEditable,
      href,
      onClick,
      stopClickPropagation,
      title,
      ...otherProps
    },
    forwardedRef,
  ) => (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={href}
      ref={forwardedRef}
      className={className}
      contentEditable={contentEditable}
      title={title}
      onClick={(event) => {
        if (stopClickPropagation) {
          event.stopPropagation();
        }

        if (onClick) {
          onClick(event);
        }
      }}
      {...otherProps}
    >
      {children ?? href}
    </a>
  ),
);

OutwardLink.displayName = 'OutwardLink';
OutwardLink.propTypes = propTypes;
