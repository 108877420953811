import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgBubbles = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
          <path d="M2.32124 1.00098C1.6411 1.00098 1 1.63422 1 2.51166V7.28704C1 7.68708 1.19398 8.12239 1.51408 8.46484C1.83726 8.81058 2.22585 8.99609 2.54889 8.99609H3.51238C3.78853 8.99609 4.01238 9.21995 4.01238 9.49609V11.1042L5.5757 9.18074C5.67065 9.06392 5.81317 8.99609 5.96371 8.99609H12.4581C12.7811 8.99609 13.1697 8.81058 13.4929 8.46484C13.813 8.12239 14.007 7.68708 14.007 7.28704V2.51166C14.007 1.63422 13.3659 1.00098 12.6857 1.00098H2.32124ZM0 2.51166C0 1.17735 0.998008 0.000976562 2.32124 0.000976562H12.6857C14.009 0.000976562 15.007 1.17735 15.007 2.51166V7.28704C15.007 7.99288 14.6774 8.6621 14.2234 9.14771C13.7726 9.63003 13.1367 9.99609 12.4581 9.99609H6.20164L3.90039 12.8275C3.76691 12.9917 3.54449 13.0541 3.34506 12.9833C3.14562 12.9125 3.01238 12.7238 3.01238 12.5121V9.99609H2.54889C1.87025 9.99609 1.23439 9.63003 0.783546 9.14771C0.329624 8.6621 0 7.99288 0 7.28704V2.51166Z" />
          <path d="M15.5039 9.00384C15.78 9.00384 16.0039 9.2277 16.0039 9.50384V10.6689C16.0039 12.0301 14.9236 13.0053 13.6058 13.0053L13.0126 13.0052 13.0125 15.5035C13.0125 15.7093 12.8865 15.894 12.6949 15.969 12.5033 16.0441 12.2853 15.9941 12.1455 15.8431L9.51969 13.0053H5.51715C5.24101 13.0053 5.01715 12.7814 5.01715 12.5053 5.01715 12.2291 5.24101 12.0053 5.51715 12.0053H9.73825C9.87759 12.0053 10.0106 12.0634 10.1052 12.1657L12.0126 14.227 12.0126 12.5052C12.0126 12.2291 12.2365 12.0052 12.5126 12.0052L13.6058 12.0053C14.4153 12.0053 15.0039 11.4348 15.0039 10.6689V9.50384C15.0039 9.2277 15.2277 9.00384 15.5039 9.00384ZM3.99073 3.51447C3.99073 3.23748 4.23334 3.01294 4.53261 3.01294H10.4488C10.7481 3.01294 10.9907 3.23748 10.9907 3.51447 10.9907 3.79146 10.7481 4.01601 10.4488 4.01601H4.53261C4.23334 4.01601 3.99073 3.79146 3.99073 3.51447ZM3.99073 6.51141C3.99073 6.23442 4.23334 6.00987 4.53261 6.00987H10.4488C10.7481 6.00987 10.9907 6.23442 10.9907 6.51141 10.9907 6.7884 10.7481 7.01294 10.4488 7.01294H4.53261C4.23334 7.01294 3.99073 6.7884 3.99073 6.51141Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgBubbles.propTypes = iconPropTypes;
