import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgChevronUpLine = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1.49967 3C1.22371 3 1 3.22403 1 3.50038 1 3.77674 1.22371 4.00077 1.49967 4.00077H14.491C14.767 4.00077 14.9907 3.77674 14.9907 3.50038 14.9907 3.22403 14.767 3 14.491 3H1.49967ZM14.7949 12.0954C15.0178 12.2584 15.0666 12.5714 14.9039 12.7946 14.7411 13.0178 14.4285 13.0667 14.2057 12.9037L8.00424 8.15068 1.80361 12.9037C1.58074 13.0667 1.26815 13.0178 1.10542 12.7946.942695 12.5714.991457 12.2584 1.21433 12.0954L7.7096 7.12698C7.88512 6.99865 8.12336 6.99865 8.29888 7.12698L14.7949 12.0954Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgChevronUpLine.propTypes = iconPropTypes;
