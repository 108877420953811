import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgSchemePathCirclesFlipped = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M2.99859 11.0227C4.10238 11.0227 4.99718 11.9131 4.99718 13.0114C4.99718 14.1097 4.10238 15 2.99859 15C1.8948 15 1 14.1097 1 13.0114C1 11.9131 1.8948 11.0227 2.99859 11.0227ZM2.99859 12.0171C3.55048 12.0171 3.99788 12.4622 3.99788 13.0114C3.99788 13.5605 3.55048 14.0057 2.99859 14.0057C2.44669 14.0057 1.99929 13.5605 1.99929 13.0114C1.99929 12.4622 2.44669 12.0171 2.99859 12.0171Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M11.4975 13.0074H6.50197C6.22603 13.0074 6.00233 13.23 6.00233 13.5046 6.00233 13.7792 6.22603 14.0017 6.50197 14.0017H11.4975C13.4296 14.0017 14.996 12.4432 14.996 10.5207 14.996 8.59813 13.4296 7.03961 11.4975 7.03961H11.4617C11.1857 7.03961 10.962 7.26219 10.962 7.53676 10.962 7.81133 11.1857 8.03392 11.4617 8.03392H11.4975C12.8777 8.03392 13.9967 9.14728 13.9967 10.5207 13.9967 11.8941 12.8777 13.0074 11.4975 13.0074ZM9.5023 3.02752 4.00618 3.02752C2.90239 3.02752 2.00759 3.91786 2.00759 5.01614 2.00759 6.11443 2.90239 7.00477 4.00618 7.00477H4.51006C4.78601 7.00477 5.00971 7.22735 5.00971 7.50192 5.00971 7.7765 4.78601 7.99908 4.51006 7.99908H4.00618C2.3505 7.99908 1.0083 6.66358 1.0083 5.01614 1.0083 3.36871 2.3505 2.03321 4.00618 2.03321L9.5023 2.03321C9.77825 2.03321 10.0019 2.25579 10.0019 2.53036 10.0019 2.80493 9.77825 3.02752 9.5023 3.02752Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.99506 5.57345C9.09885 5.57345 9.99365 6.46379 9.99365 7.56208C9.99365 8.66036 9.09885 9.5507 7.99506 9.5507C6.89127 9.5507 5.99647 8.66036 5.99647 7.56208C5.99647 6.46379 6.89127 5.57345 7.99506 5.57345ZM7.99506 6.56776C8.54695 6.56776 8.99435 7.01293 8.99435 7.56208C8.99435 8.11122 8.54695 8.55639 7.99506 8.55639C7.44316 8.55639 6.99576 8.11122 6.99576 7.56208C6.99576 7.01293 7.44316 6.56776 7.99506 6.56776Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M14.8505 1.84848C15.0455 1.6542 15.0453 1.33942 14.85 1.14539C14.6548 0.951363 14.3384 0.951562 14.1434 1.14584L12.5009 2.78225L11.8564 2.14015C11.6614 1.94587 11.345 1.94568 11.1498 2.1397C10.9545 2.33373 10.9543 2.64852 11.1493 2.84279L12.1473 3.8371C12.2411 3.93047 12.3682 3.98294 12.5009 3.98294C12.6335 3.98294 12.7607 3.93047 12.8544 3.8371L14.8505 1.84848Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgSchemePathCirclesFlipped.propTypes = iconPropTypes;
