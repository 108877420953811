import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLightBulb = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.375 1C4.40647 1 2 3.40647 2 6.375C2 7.75216 2.51858 9.00939 3.37033 9.96021C3.75219 10.3865 3.99084 10.8531 3.99084 11.3178V11.52C3.99084 12.3484 4.66245 13.02 5.4909 13.02L10.501 13.0198C11.3294 13.0197 12.0009 12.3482 12.0009 11.5198V11.3265C12.0009 10.8607 12.2407 10.3931 12.6241 9.96642C13.4792 9.01487 14 7.75517 14 6.375C14 3.40647 11.5935 1 8.625 1H7.375ZM3 6.375C3 3.95875 4.95875 2 7.375 2H8.625C11.0412 2 13 3.95875 13 6.375C13 7.49896 12.5768 8.5229 11.8803 9.29803C11.4111 9.82013 11.0009 10.5167 11.0009 11.3265V11.5198C11.0009 11.7959 10.7771 12.0197 10.501 12.0198L5.49086 12.02C5.21471 12.02 4.99084 11.7961 4.99084 11.52V11.3178C4.99084 11.2122 4.98386 11.1085 4.97067 11.0068H7.49326C7.7694 11.0068 7.99326 10.783 7.99326 10.5068C7.99326 10.2307 7.7694 10.0068 7.49326 10.0068H4.62999C4.48342 9.74318 4.30411 9.50389 4.11517 9.29297C3.42135 8.51845 3 7.49652 3 6.375Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M5.99951 14.5C5.99951 14.2239 6.22337 14 6.49951 14H9.49951C9.77565 14 9.99951 14.2239 9.99951 14.5C9.99951 14.7761 9.77565 15 9.49951 15H6.49951C6.22337 15 5.99951 14.7761 5.99951 14.5Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLightBulb.propTypes = iconPropTypes;
