import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgModalMaximize = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.5 1C1.22386 1 1 1.22395 1 1.50021L1 12.5048C1 12.7811 1.22386 13.005 1.5 13.005H14.5C14.7761 13.005 15 12.7811 15 12.5048L15 1.50021C15 1.22395 14.7761 1 14.5 1L1.5 1ZM2 12.0046L2 2.00042H14V12.0046H2Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M3.00293 14.999V13.9986L1.00018 13.9992 1.00018 14.4994C1.00018 14.632 1.06723 14.7593 1.18657 14.8531 1.30591 14.9469 1.46777 14.9996 1.63654 14.9996L3.00293 14.999ZM12.9902 13.9986V14.999L14.3566 14.9996C14.5254 14.9996 14.6873 14.9469 14.8066 14.8531 14.9259 14.7593 14.993 14.632 14.993 14.4994V13.9992L12.9902 13.9986ZM5.99805 13.9996H3.99805V15H5.99805V13.9996ZM7 13.9996H9V15H7V13.9996ZM11.9951 13.9996H9.99512V15H11.9951V13.9996Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgModalMaximize.propTypes = iconPropTypes;
