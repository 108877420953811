import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgChain = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M11.6526 9.35955C11.454 9.16766 11.4486 8.85113 11.6404 8.65255L13.0994 7.14275C13.6557 6.59766 14 5.83954 14 5 14 3.34315 12.6569 2 11 2 10.2001 2 9.47431 2.31237 8.93609 2.82277L7.34764 4.35937C7.14916 4.55137 6.83262 4.54611 6.64063 4.34764 6.44863 4.14916 6.45389 3.83262 6.65236 3.64063L8.24583 2.09918C8.96261 1.41844 9.93298 1 11 1 13.2091 1 15 2.79086 15 5 15 6.11661 14.5418 7.12711 13.8045 7.85219L12.3596 9.34745C12.1677 9.54602 11.8511 9.55145 11.6526 9.35955ZM4.34745 6.64044C4.54602 6.83233 4.55145 7.14887 4.35956 7.34745L2.90059 8.85725C2.34433 9.40234 2 10.1605 2 11 2 12.6569 3.34315 14 5 14 5.79986 14 6.52569 13.6876 7.06391 13.1772L8.65236 11.6406C8.85084 11.4486 9.16737 11.4539 9.35937 11.6524 9.55137 11.8508 9.54611 12.1674 9.34764 12.3594L7.75417 13.9008C7.03739 14.5816 6.06702 15 5 15 2.79086 15 1 13.2091 1 11 1 9.88339 1.45819 8.87289 2.19554 8.14781L3.64044 6.65255C3.83234 6.45397 4.14887 6.44855 4.34745 6.64044Z"
        />
        <path
          fill="currentColor"
          d="M10.3536 6.35355C10.5488 6.15829 10.5488 5.84171 10.3536 5.64645C10.1583 5.45118 9.84171 5.45118 9.64645 5.64645L5.64645 9.64645C5.45118 9.84171 5.45118 10.1583 5.64645 10.3536C5.84171 10.5488 6.15829 10.5488 6.35355 10.3536L10.3536 6.35355Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgChain.propTypes = iconPropTypes;
