import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgMediaPlayer = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.35293 4.17532C6.84282 3.91997 6.26158 3.96714 5.8118 4.21276C5.35565 4.46186 4.99805 4.93787 4.99805 5.55556V10.4545C4.99805 11.0722 5.35565 11.5482 5.8118 11.7973C6.26159 12.0429 6.84282 12.0901 7.35293 11.8347L7.37601 11.8232L11.2424 9.35985L11.2514 9.35403C11.7319 9.03332 11.998 8.5314 11.998 8.01961C11.998 7.51125 11.7325 7.02321 11.2279 6.75699L7.38078 4.18926L7.35293 4.17532ZM5.99805 5.55556C5.99805 5.35676 6.10646 5.19124 6.29109 5.09042C6.47412 4.99047 6.69692 4.97606 6.88144 5.05826L10.7221 7.62171L10.75 7.63565C10.9247 7.72313 10.998 7.86736 10.998 8.01961C10.998 8.17754 10.9157 8.37405 10.7001 8.51965L6.88557 10.9499C6.70025 11.0342 6.47549 11.0203 6.29109 10.9196C6.10646 10.8188 5.99805 10.6533 5.99805 10.4545V5.55556Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgMediaPlayer.propTypes = iconPropTypes;
