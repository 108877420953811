import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLTextCol = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1.50459 1.00488C1.22834 1.00488 1.00439 1.22874 1.00439 1.50488C1.00439 1.78103 1.22834 2.00488 1.50459 2.00488H10.508C10.7843 2.00488 11.0082 1.78103 11.0082 1.50488C11.0082 1.22874 10.7843 1.00488 10.508 1.00488H1.50459Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M12.5034 1.00488C12.2272 1.00488 12.0032 1.22874 12.0032 1.50488V14.503C12.0032 14.7791 12.2272 15.003 12.5034 15.003H14.5042C14.7805 15.003 15.0044 14.7791 15.0044 14.503V1.50488C15.0044 1.22874 14.7805 1.00488 14.5042 1.00488H12.5034ZM13.0036 14.003V2.00488H14.004V14.003H13.0036Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M1.00439 3.50488C1.00439 3.22874 1.22834 3.00488 1.50459 3.00488H10.508C10.7843 3.00488 11.0082 3.22874 11.0082 3.50488 11.0082 3.78103 10.7843 4.00488 10.508 4.00488H1.50459C1.22834 4.00488 1.00439 3.78103 1.00439 3.50488ZM1.50459 5.00488C1.22834 5.00488 1.00439 5.22874 1.00439 5.50488 1.00439 5.78103 1.22834 6.00488 1.50459 6.00488H10.508C10.7843 6.00488 11.0082 5.78103 11.0082 5.50488 11.0082 5.22874 10.7843 5.00488 10.508 5.00488H1.50459ZM1.00439 7.50488C1.00439 7.22874 1.22834 7.00488 1.50459 7.00488H10.508C10.7843 7.00488 11.0082 7.22874 11.0082 7.50488 11.0082 7.78103 10.7843 8.00488 10.508 8.00488H1.50459C1.22834 8.00488 1.00439 7.78103 1.00439 7.50488ZM1.50459 9.00488C1.22834 9.00488 1.00439 9.22874 1.00439 9.50488 1.00439 9.78102 1.22834 10.0049 1.50459 10.0049H10.508C10.7843 10.0049 11.0082 9.78102 11.0082 9.50488 11.0082 9.22874 10.7843 9.00488 10.508 9.00488H1.50459ZM1.00439 11.5156C1.00439 11.2395 1.22834 11.0156 1.50459 11.0156H10.508C10.7843 11.0156 11.0082 11.2395 11.0082 11.5156 11.0082 11.7918 10.7843 12.0156 10.508 12.0156H1.50459C1.22834 12.0156 1.00439 11.7918 1.00439 11.5156ZM1.50459 13.0205C1.22834 13.0205 1.00439 13.2444 1.00439 13.5205 1.00439 13.7966 1.22834 14.0205 1.50459 14.0205H6.50651C6.78275 14.0205 7.0067 13.7966 7.0067 13.5205 7.0067 13.2444 6.78275 13.0205 6.50651 13.0205H1.50459Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLTextCol.propTypes = iconPropTypes;
