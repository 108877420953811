import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgArrowEnterSquare = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M0 8.49744C-1.20777e-08 8.22129 0.22399 7.99744 0.500297 7.99744L8.29781 7.99744L6.65039 6.35099C6.45501 6.15573 6.45501 5.83915 6.65039 5.64388C6.84577 5.44862 7.16254 5.44862 7.35791 5.64388L9.8594 8.14388C10.0548 8.33915 10.0548 8.65573 9.8594 8.85099L7.35791 11.351C7.16254 11.5463 6.84577 11.5463 6.65039 11.351C6.45501 11.1557 6.45501 10.8391 6.65039 10.6439L8.29781 8.99744H0.500296C0.22399 8.99744 1.20777e-08 8.77358 0 8.49744Z" />
          <path d="M1.48819 0.99646C1.21188 0.99646 0.98789 1.22032 0.98789 1.49646V5.50376C0.98789 5.7799 1.21188 6.00376 1.48819 6.00376C1.76449 6.00376 1.98848 5.7799 1.98848 5.50376V1.99646L14.9994 1.99646V14.9965H1.98848V11.5001C1.98848 11.224 1.76449 11.0001 1.48819 11.0001C1.21188 11.0001 0.98789 11.224 0.98789 11.5001V15.4965C0.98789 15.7726 1.21188 15.9965 1.48819 15.9965L15.4997 15.9965C15.776 15.9965 16 15.7726 16 15.4965V1.49646C16 1.22032 15.776 0.99646 15.4997 0.99646L1.48819 0.99646Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" transform="rotate(-90 8 8)" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgArrowEnterSquare.propTypes = iconPropTypes;
