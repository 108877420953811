import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgKentico = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 17 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M6.83817.0935112C6.83817.0935112 6.83848 2.5171 6.83848 4.15507 6.83817 5.62241 9.19284 5.58804 9.19284 4.13804V1.23757C9.19284-.485395 6.83817.0935112 6.83817.0935112ZM12.7724 1.56632C12.7724 1.56632 11.0583 3.28085 9.90036 4.43866 8.86286 5.47601 10.5518 7.11741 11.5772 6.09179L13.6281 4.04038C14.8469 2.8221 12.7724 1.56632 12.7724 1.56632ZM8.01536 6.28851C8.96083 6.28851 9.72661 7.05491 9.72661 7.99959 9.72661 8.94443 8.96067 9.71068 8.01536 9.71099 7.0702 9.71099 6.30426 8.94443 6.30426 7.99959 6.30426 7.05491 7.0702 6.28851 8.01536 6.28851ZM6.13084 11.5612C4.97272 12.7192 3.25881 14.4335 3.25881 14.4335 3.25881 14.4335 1.18444 13.1778 2.40303 11.959L4.45397 9.90854C5.47928 8.88244 7.1685 10.5235 6.13084 11.5612ZM4.45427 6.11491C3.2963 4.95679 1.58224 3.24304 1.58224 3.24304 1.58224 3.24304 2.83786 1.1682 4.05661 2.38726L6.10755 4.43835C7.13286 5.46366 5.49208 7.15241 4.45427 6.11491ZM14.4487 12.7568C14.4487 12.7568 12.7349 11.0431 11.5769 9.88463 10.5394 8.84698 8.89832 10.536 9.92411 11.5615L11.9751 13.6128C13.193 14.8312 14.4487 12.7568 14.4487 12.7568ZM11.86 6.82226H15.922C15.922 6.82226 16.5013 9.17709 14.7774 9.17709H11.8768C10.4271 9.17709 10.3929 6.82226 11.86 6.82226ZM.109116 9.17713H4.17114C5.63848 9.17713 5.60426 6.82257 4.15411 6.82257H1.25349C-.46979 6.82257.109116 9.17713.109116 9.17713ZM9.193 11.8442V15.9062C9.193 15.9062 6.83817 16.4849 6.83817 14.762 6.83817 13.8264 6.83817 12.6981 6.83848 11.8609 6.83817 10.411 9.193 10.377 9.193 11.8442Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgKentico.propTypes = iconPropTypes;
