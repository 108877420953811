import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgArrowDown = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            d="M8.50001 0C8.77615 0 9.00001 0.223858 9.00001 0.5L9.00001 14.1674L11.1237 11.7403C11.3056 11.5325 11.6214 11.5115 11.8293 11.6933C12.0371 11.8751 12.0581 12.191 11.8763 12.3988L8.8763 15.8274L8.87356 15.8305C8.86987 15.8347 8.86612 15.8387 8.8623 15.8428C8.81952 15.8878 8.77015 15.9232 8.71702 15.9487C8.71425 15.9501 8.71147 15.9514 8.70868 15.9527C8.69695 15.9581 8.68507 15.963 8.67305 15.9674C8.63714 15.9806 8.59928 15.9899 8.56003 15.9946C8.5396 15.997 8.51907 15.9982 8.49856 15.9982C8.49434 15.9981 8.49013 15.9981 8.48591 15.998C8.47034 15.9975 8.45495 15.9964 8.43976 15.9946C8.40561 15.9905 8.37252 15.9829 8.34085 15.9723C8.32889 15.9683 8.31712 15.9638 8.30558 15.9589C8.27746 15.9471 8.25015 15.9325 8.22404 15.9152C8.21348 15.9082 8.2032 15.9008 8.19322 15.893C8.18281 15.8849 8.17272 15.8764 8.16299 15.8675C8.14692 15.8528 8.13201 15.8374 8.11829 15.8212L5.12372 12.3988C4.94188 12.191 4.96294 11.8751 5.17075 11.6933C5.37857 11.5115 5.69445 11.5325 5.8763 11.7403L8.00001 14.1674L8.00001 0.5C8.00001 0.223858 8.22386 0 8.50001 0Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" transform="rotate(-90 8 8)" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgArrowDown.propTypes = iconPropTypes;
