import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgCbUncheck = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M13.0029 2.00317H3.00293C2.45064 2.00317 2.00293 2.45089 2.00293 3.00317V13.0032C2.00293 13.5555 2.45064 14.0032 3.00293 14.0032H13.0029C13.5552 14.0032 14.0029 13.5555 14.0029 13.0032V3.00317C14.0029 2.45089 13.5552 2.00317 13.0029 2.00317ZM3.00293 1.00317C1.89836 1.00317 1.00293 1.8986 1.00293 3.00317V13.0032C1.00293 14.1077 1.89836 15.0032 3.00293 15.0032H13.0029C14.1075 15.0032 15.0029 14.1077 15.0029 13.0032V3.00317C15.0029 1.8986 14.1075 1.00317 13.0029 1.00317H3.00293Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgCbUncheck.propTypes = iconPropTypes;
