import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLoop = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M8 2C4.68629 2 2 4.68629 2 8 2 9.69017 2.6982 11.2164 3.82322 12.3075 4.02145 12.4998 4.02629 12.8163 3.83404 13.0145 3.64179 13.2128 3.32525 13.2176 3.12702 13.0253 1.81583 11.7537 1 9.97168 1 8 1 4.13401 4.13401 1 8 1 11.866 1 15 4.13401 15 8 15 11.866 11.866 15 8 15H1.5C1.22386 15 1 14.7761 1 14.5 1 14.2239 1.22386 14 1.5 14H8C11.3137 14 14 11.3137 14 8 14 4.68629 11.3137 2 8 2ZM13.5 14C13.2239 14 13 14.2239 13 14.5 13 14.7761 13.2239 15 13.5 15H14.5C14.7761 15 15 14.7761 15 14.5 15 14.2239 14.7761 14 14.5 14H13.5Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLoop.propTypes = iconPropTypes;
