import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgDeviceRotate = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M2 1.5C2 1.22386 1.77614 1 1.5 1 1.22386 1 1 1.22386 1 1.5V4.5C1 4.77614 1.22386 5 1.5 5H4.5C4.77614 5 5 4.77614 5 4.5 5 4.22386 4.77614 4 4.5 4H2.64302C3.80512 2.6608 5.48576 2 7.49998 2 11.0898 2 14 4.91015 14 8.5 14 10.62 12.9856 12.5029 11.4136 13.6902 11.1933 13.8567 11.1496 14.1702 11.316 14.3906 11.4824 14.6109 11.796 14.6546 12.0163 14.4882 13.8277 13.12 15 10.9468 15 8.5 15 4.35786 11.6421 1 7.49998 1 5.31382 1 3.37878 1.70802 2 3.2183V1.5ZM4.35355 10.6464C4.15829 10.4512 3.84171 10.4512 3.64645 10.6464 3.45118 10.8417 3.45118 11.1583 3.64645 11.3536L4.64645 12.3536C4.84171 12.5488 5.15829 12.5488 5.35355 12.3536 5.54882 12.1583 5.54882 11.8417 5.35355 11.6464L4.35355 10.6464Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M6.49784 4.53175C7.04195 3.98764 7.93703 3.96003 8.49019 4.51319L11.4986 7.52155C12.0517 8.07471 12.0241 8.96979 11.48 9.5139L6.42775 14.5662C5.88364 15.1103 4.98855 15.1379 4.4354 14.5847L1.42703 11.5763C0.873871 11.0232 0.90148 10.1281 1.44559 9.584L6.49784 4.53175ZM7.78308 5.22029C7.63957 5.07678 7.37793 5.06587 7.20495 5.23885L2.1527 10.2911C1.97972 10.4641 1.99062 10.7257 2.13414 10.8692L5.1425 13.8776C5.28602 14.0211 5.54766 14.032 5.72064 13.859L10.7729 8.8068C10.9459 8.63382 10.935 8.37218 10.7915 8.22866L7.78308 5.22029Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgDeviceRotate.propTypes = iconPropTypes;
