import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgBuilding = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M6.5083 3.97266C6.78444 3.97266 7.0083 4.19651 7.0083 4.47266V5.47266C7.0083 5.7488 6.78444 5.97266 6.5083 5.97266 6.23216 5.97266 6.0083 5.7488 6.0083 5.47266V4.47266C6.0083 4.19651 6.23216 3.97266 6.5083 3.97266ZM7.0083 7.46729C7.0083 7.19114 6.78444 6.96729 6.5083 6.96729 6.23216 6.96729 6.0083 7.19114 6.0083 7.46729V8.46729C6.0083 8.74343 6.23216 8.96729 6.5083 8.96729 6.78444 8.96729 7.0083 8.74343 7.0083 8.46729V7.46729ZM7.0083 10.4714C7.0083 10.1953 6.78444 9.97144 6.5083 9.97144 6.23216 9.97144 6.0083 10.1953 6.0083 10.4714V11.4714C6.0083 11.7476 6.23216 11.9714 6.5083 11.9714 6.78444 11.9714 7.0083 11.7476 7.0083 11.4714V10.4714ZM10.0093 4.47266C10.0093 4.19651 9.78542 3.97266 9.50928 3.97266 9.23314 3.97266 9.00928 4.19651 9.00928 4.47266V5.47266C9.00928 5.7488 9.23314 5.97266 9.50928 5.97266 9.78542 5.97266 10.0093 5.7488 10.0093 5.47266V4.47266ZM9.50537 9.97144C9.78151 9.97144 10.0054 10.1953 10.0054 10.4714V11.4714C10.0054 11.7476 9.78151 11.9714 9.50537 11.9714 9.22923 11.9714 9.00537 11.7476 9.00537 11.4714V10.4714C9.00537 10.1953 9.22923 9.97144 9.50537 9.97144ZM9.99951 7.46729C9.99951 7.19114 9.77565 6.96729 9.49951 6.96729 9.22337 6.96729 8.99951 7.19114 8.99951 7.46729V8.46729C8.99951 8.74343 9.22337 8.96729 9.49951 8.96729 9.77565 8.96729 9.99951 8.74343 9.99951 8.46729V7.46729Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M2.51367 1C2.23753 1 2.01367 1.22386 2.01367 1.5V14.0017H1.5C1.22386 14.0017 1 14.2256 1 14.5017C1 14.7779 1.22386 15.0017 1.5 15.0017H2.50893L2.51367 15.0017H13.5137L13.5185 15.0017H14.5C14.7761 15.0017 15 14.7779 15 14.5017C15 14.2256 14.7761 14.0017 14.5 14.0017H14.0137V1.5C14.0137 1.22386 13.7898 1 13.5137 1H2.51367ZM13.0137 14.0017V2H3.01367V14.0017L13.0137 14.0017Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgBuilding.propTypes = iconPropTypes;
