import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLMenuCols3 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1.5.99707C1.22386.99707 1 1.22093 1 1.49707 1 1.77321 1.22386 1.99707 1.5 1.99707H2.5C2.77614 1.99707 3 1.77321 3 1.49707 3 1.22093 2.77614.99707 2.5.99707H1.5ZM1.5 2.99707C1.22386 2.99707 1 3.22093 1 3.49707 1 3.77321 1.22386 3.99707 1.5 3.99707H2.5C2.77614 3.99707 3 3.77321 3 3.49707 3 3.22093 2.77614 2.99707 2.5 2.99707H1.5ZM1 5.50879C1 5.23265 1.22386 5.00879 1.5 5.00879H2.5C2.77614 5.00879 3 5.23265 3 5.50879 3 5.78493 2.77614 6.00879 2.5 6.00879H1.5C1.22386 6.00879 1 5.78493 1 5.50879Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.5.99707C4.22386.99707 4 1.22093 4 1.49707V14.4971C4 14.7732 4.22386 14.9971 4.5 14.9971H6.5C6.77614 14.9971 7 14.7732 7 14.4971V1.49707C7 1.22093 6.77614.99707 6.5.99707H4.5ZM5 13.9971V1.99707H6V13.9971H5ZM8 1.49707C8 1.22093 8.22386.99707 8.5.99707H10.5C10.7761.99707 11 1.22093 11 1.49707V14.4971C11 14.7732 10.7761 14.9971 10.5 14.9971H8.5C8.22386 14.9971 8 14.7732 8 14.4971V1.49707ZM9 1.99707V13.9971H10V1.99707H9ZM12.5.99707C12.2239.99707 12 1.22093 12 1.49707V14.4971C12 14.7732 12.2239 14.9971 12.5 14.9971H14.5C14.7761 14.9971 15 14.7732 15 14.4971V1.49707C15 1.22093 14.7761.99707 14.5.99707H12.5ZM13 13.9971V1.99707H14V13.9971H13Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLMenuCols3.propTypes = iconPropTypes;
