import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgUserCheckbox = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M14.1401 10.7751C14.2834 10.5389 14.2081 10.2313 13.972 10.088C13.7359 9.94476 13.4284 10.0201 13.2852 10.2562L11.8441 12.6325L11.1092 11.6758C10.9409 11.4567 10.627 11.4156 10.4081 11.5839C10.1891 11.7522 10.148 12.0662 10.3162 12.2853L11.4962 13.8215C11.5967 13.9524 11.7553 14.0252 11.9201 14.0161C12.0849 14.0071 12.2345 13.9173 12.3201 13.7762L14.1401 10.7751Z" />
          <path
            fillRule="evenodd"
            d="M3.11245 8.31074C1.85044 7.51384 1.01213 6.10691 1.01213 4.50402C1.01213 2.01893 3.02714 0.00488281 5.51213 0.00488281C7.99713 0.00488281 10.0121 2.01893 10.0121 4.50402C10.0121 5.95051 9.32944 7.2374 8.26876 8.06042C8.33838 8.02363 8.41774 8.0028 8.50195 8.0028H15.502C15.7781 8.0028 16.002 8.22674 16.002 8.50298V15.5047C16.002 15.7809 15.7781 16.0049 15.502 16.0049H8.50195C8.22581 16.0049 8.00195 15.7809 8.00195 15.5047V8.50298C8.00195 8.36722 8.05602 8.24409 8.14379 8.15397C7.4037 8.6883 6.49465 9.00315 5.51213 9.00315L5.48088 9.00305C3.05383 9.00739 2.00061 9.80892 1.50778 10.6302C0.998867 11.4782 0.999819 12.4713 1.00046 13.143L1.00051 14.0048H6.50051C6.77665 14.0048 7.00051 14.2287 7.00051 14.505C7.00051 14.7812 6.77665 15.0051 6.50051 15.0051H0.500508C0.224366 15.0051 0.000508223 14.7812 0.000508223 14.505V13.2122L0.000273798 13.11C-0.0019238 12.4542 -0.00609499 11.2093 0.650403 10.1153C1.121 9.33111 1.894 8.67476 3.11245 8.31074ZM2.01213 4.50402C2.01213 2.57198 3.57885 1.00523 5.51213 1.00523C7.44542 1.00523 9.01213 2.57198 9.01213 4.50402C9.01213 6.43605 7.44542 8.0028 5.51213 8.0028L5.50073 8.00268L5.48383 8.00269C3.56358 7.98748 2.01213 6.42662 2.01213 4.50402ZM9.00195 15.0045V9.00315H15.002V15.0045H9.00195Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgUserCheckbox.propTypes = iconPropTypes;
