import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgBrandGooglePlus = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M6.43359 3.97095C4.5006 3.97095 2.93359 5.53795 2.93359 7.47095C2.93359 9.40394 4.5006 10.9709 6.43359 10.9709C8.36659 10.9709 9.93359 9.40394 9.93359 7.47095C9.93359 7.19481 9.70974 6.97095 9.43359 6.97095H7.03359C6.75745 6.97095 6.53359 7.19481 6.53359 7.47095C6.53359 7.74709 6.75745 7.97095 7.03359 7.97095H8.88358C8.65195 9.11207 7.64307 9.97095 6.43359 9.97095C5.05288 9.97095 3.93359 8.85166 3.93359 7.47095C3.93359 6.09024 5.05288 4.97095 6.43359 4.97095C7.09489 4.97095 7.69527 5.22705 8.14274 5.64643C8.34423 5.83527 8.66064 5.82501 8.84948 5.62353C9.03831 5.42205 9.02806 5.10563 8.82658 4.91679C8.20113 4.33061 7.35882 3.97095 6.43359 3.97095Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1 3C1 1.89543 1.89543 1 3 1H13C14.1046 1 15 1.89543 15 3V13C15 14.1046 14.1046 15 13 15H3C1.89543 15 1 14.1046 1 13V3ZM3 2H13C13.5523 2 14 2.44772 14 3V7.44232C13.9691 7.19583 13.7588 7.00513 13.5039 7.00513H13.0039V6.50513C13.0039 6.22898 12.78 6.00513 12.5039 6.00513C12.2278 6.00513 12.0039 6.22898 12.0039 6.50513V7.00513H11.5039C11.2278 7.00513 11.0039 7.22898 11.0039 7.50513C11.0039 7.78127 11.2278 8.00513 11.5039 8.00513H12.0039V8.50513C12.0039 8.78127 12.2278 9.00513 12.5039 9.00513C12.78 9.00513 13.0039 8.78127 13.0039 8.50513V8.00513H13.5039C13.7588 8.00513 13.9691 7.81443 14 7.56793V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13V3C2 2.44772 2.44772 2 3 2Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgBrandGooglePlus.propTypes = iconPropTypes;
