import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgNote = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M15 1.50002C15 1.35609 14.938 1.21915 14.8299 1.12421C14.7217 1.02927 14.578 0.985553 14.4353 1.00423L5.43362 2.18286C5.18477 2.21545 4.99866 2.42758 4.99866 2.67865V10.4997C4.58097 10.1858 4.06178 9.99984 3.49916 9.99984C2.11891 9.99984 1 11.1192 1 12.4999C1 13.8807 2.11891 15 3.49916 15C4.87872 15 5.99721 13.8818 5.99832 12.502C5.99832 12.5015 5.99832 12.5009 5.99832 12.5004V3.11749L14.0003 2.06975V9.49574C13.5826 9.18188 13.0635 8.9959 12.5008 8.9959C11.1206 8.9959 10.0017 10.1152 10.0017 11.496C10.0017 12.8767 11.1206 13.9961 12.5008 13.9961C13.8811 13.9961 15 12.8767 15 11.496C15 11.4556 14.999 11.4154 14.9971 11.3755C14.999 11.3578 15 11.3399 15 11.3217V1.50002ZM4.99866 12.4999C4.99866 11.6715 4.32731 10.9999 3.49916 10.9999C2.67101 10.9999 1.99966 11.6715 1.99966 12.4999C1.99966 13.3284 2.67101 14 3.49916 14C4.32731 14 4.99866 13.3284 4.99866 12.4999ZM12.5008 9.99593C11.6727 9.99593 11.0013 10.6675 11.0013 11.496C11.0013 12.3244 11.6727 12.996 12.5008 12.996C13.329 12.996 14.0003 12.3244 14.0003 11.496C14.0003 10.6675 13.329 9.99593 12.5008 9.99593Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgNote.propTypes = iconPropTypes;
