import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgSchemePathCircles = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M2.99716 5.00559C4.10016 5.00559 4.99432 4.10891 4.99432 3.00279C4.99432 1.89668 4.10016 1 2.99716 1C1.89416 1 1 1.89668 1 3.00279C1 4.10891 1.89416 5.00559 2.99716 5.00559ZM2.99716 4.00419C3.54866 4.00419 3.99574 3.55585 3.99574 3.00279C3.99574 2.44974 3.54866 2.0014 2.99716 2.0014C2.44566 2.0014 1.99858 2.44974 1.99858 3.00279C1.99858 3.55585 2.44566 4.00419 2.99716 4.00419Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M6.01391 2.48351C6.01391 2.20699 6.23744 1.98282 6.5132 1.98282H11.4549C13.3851 1.98282 14.9499 3.55201 14.9499 5.48771 14.9499 7.4234 13.3851 8.9926 11.4549 8.9926H11.4163C11.1405 8.9926 10.917 8.76843 10.917 8.4919 10.917 8.21537 11.1405 7.9912 11.4163 7.9912H11.4549C12.8336 7.9912 13.9513 6.87035 13.9513 5.48771 13.9513 4.10507 12.8336 2.98421 11.4549 2.98421H6.5132C6.23744 2.98421 6.01391 2.76004 6.01391 2.48351ZM9.52299 12.9941H3.99819C2.89519 12.9941 2.00103 12.1005 2.00103 10.9944 2.00103 9.8883 2.89519 8.99162 3.99819 8.99162H4.48659C4.76234 8.99162 4.98588 8.76745 4.98588 8.49092 4.98588 8.21439 4.76234 7.99022 4.48659 7.99022H3.99819C2.34369 7.99022 1.00245 9.33524 1.00245 10.9944 1.00245 12.6536 2.34369 13.9955 3.99819 13.9955H9.52299C9.79874 13.9955 10.0223 13.7714 10.0223 13.4948 10.0223 13.2183 9.79874 12.9941 9.52299 12.9941Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.98179 9.99008C9.08479 9.99008 9.97895 9.0934 9.97895 7.98729C9.97895 6.88117 9.08479 5.98449 7.98179 5.98449C6.87878 5.98449 5.98462 6.88117 5.98462 7.98729C5.98462 9.0934 6.87878 9.99008 7.98179 9.99008ZM7.98179 8.98868C8.53329 8.98868 8.98037 8.54034 8.98037 7.98729C8.98037 7.43423 8.53329 6.98589 7.98179 6.98589C7.43028 6.98589 6.98321 7.43423 6.98321 7.98729C6.98321 8.54034 7.43028 8.98868 7.98179 8.98868Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M14.8536 12.8507C15.0487 12.6553 15.0488 12.3383 14.8539 12.1426C14.659 11.947 14.3429 11.9469 14.1478 12.1423L12.5017 13.7915L11.8551 13.1437C11.66 12.9482 11.3439 12.9484 11.149 13.144C10.9541 13.3396 10.9542 13.6567 11.1493 13.8521L12.1488 14.8535C12.3437 15.0488 12.6596 15.0488 12.8546 14.8535L14.8536 12.8507Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgSchemePathCircles.propTypes = iconPropTypes;
