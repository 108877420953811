import React from 'react';
import { IconSize } from '../../../tokens/quarks/iconSize.ts';
import { getDataUiComponentAttribute } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { Chevron, IChevronProps, chevronPropTypes } from '../../Chevron/Chevron.tsx';
import { pick } from '../../_utils/pick.ts';
import { StyledQuinaryButtonIconWrapper } from './QuinaryButtonIcon.tsx';

interface IQuinaryButtonChevronProps
  extends Pick<IChevronProps, 'children' | 'direction' | 'screenReaderText'> {}

const propTypes: PropTypeMap<IQuinaryButtonChevronProps> = pick(chevronPropTypes, [
  'children',
  'direction',
  'screenReaderText',
]);

export const QuinaryButtonChevron: React.FC<IQuinaryButtonChevronProps> = (props) => (
  <StyledQuinaryButtonIconWrapper {...getDataUiComponentAttribute(QuinaryButtonChevron)}>
    <Chevron size={IconSize.S} {...props} />
  </StyledQuinaryButtonIconWrapper>
);

QuinaryButtonChevron.displayName = 'QuinaryButtonChevron';
QuinaryButtonChevron.propTypes = propTypes;
