import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgChevronLeftCircle = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M8.78564 11.8496C8.97888 12.0469 9.29545 12.0502 9.49271 11.8569C9.68998 11.6637 9.69324 11.3471 9.5 11.1499L6.41418 7.99976L9.5 4.84965C9.69324 4.65238 9.68998 4.33582 9.49271 4.14258C9.29545 3.94934 8.97888 3.9526 8.78564 4.14987L5.35707 7.64987C5.16664 7.84426 5.16664 8.15525 5.35707 8.34965L8.78564 11.8496Z" />
          <path
            fillRule="evenodd"
            d="M7.99976 15.9998C12.418 15.9998 15.9998 12.418 15.9998 7.99976C15.9998 3.58147 12.418 -0.000244683 7.99976 -0.00024449C3.58147 -0.000244297 -0.000243984 3.58147 -0.000243791 7.99976C-0.000243598 12.418 3.58147 15.9998 7.99976 15.9998ZM7.99976 14.9998C11.8658 14.9998 14.9998 11.8658 14.9998 7.99976C14.9998 4.13376 11.8658 0.999755 7.99976 0.999756C4.13376 0.999756 0.999756 4.13376 0.999756 7.99976C0.999756 11.8658 4.13376 14.9998 7.99976 14.9998Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgChevronLeftCircle.propTypes = iconPropTypes;
