import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLCols4 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M.5 2C.223858 2 0 2.22386 0 2.5V13.4986C0 13.7748.223858 13.9986.5 13.9986H2.5C2.77614 13.9986 3 13.7748 3 13.4986V2.5C3 2.22386 2.77614 2 2.5 2H.5ZM1 12.9986V3H2V12.9986H1ZM8.5 2C8.22386 2 8 2.22386 8 2.5V13.4986C8 13.7748 8.22386 13.9986 8.5 13.9986H10.5C10.7761 13.9986 11 13.7748 11 13.4986V2.5C11 2.22386 10.7761 2 10.5 2H8.5ZM9 12.9986V3H10V12.9986H9ZM4 2.5C4 2.22386 4.22386 2 4.5 2H6.5C6.77614 2 7 2.22386 7 2.5V13.4986C7 13.7748 6.77614 13.9986 6.5 13.9986H4.5C4.22386 13.9986 4 13.7748 4 13.4986V2.5ZM5 3V12.9986H6V3H5ZM12.5 2C12.2239 2 12 2.22386 12 2.5V13.4986C12 13.7748 12.2239 13.9986 12.5 13.9986H14.5C14.7761 13.9986 15 13.7748 15 13.4986V2.5C15 2.22386 14.7761 2 14.5 2H12.5ZM13 12.9986V3H14V12.9986H13Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLCols4.propTypes = iconPropTypes;
