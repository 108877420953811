import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgFunnel = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M1.00003 0.509859L1 0.504043C1 0.228095 1.22386 0.00439453 1.5 0.00439453H14.5C14.7761 0.00439453 15 0.228095 15 0.504043L15 0.508047C15.0017 0.619257 14.9664 0.73172 14.8918 0.825677L9.95591 7.04778V12.9614C9.95591 13.107 9.89231 13.2454 9.78177 13.3403L6.81971 15.8837C6.67152 16.0109 6.46275 16.0403 6.2852 15.9588C6.10765 15.8773 5.99385 15.7 5.99385 15.5047V7.04641L1.10669 0.823765C1.03356 0.730647 0.998771 0.619678 1.00003 0.509859ZM2.51921 1.00369L4.08084 2.99206L11.8972 2.99206L13.4745 1.00369H2.51921ZM6.88718 6.56528L4.86566 3.99136L11.1044 3.99136L9.06409 6.56337C8.99403 6.65168 8.95591 6.76106 8.95591 6.87375V12.7319L6.99385 14.4166V6.87375C6.99385 6.7619 6.95628 6.65327 6.88718 6.56528Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgFunnel.propTypes = iconPropTypes;
