import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgEarth = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M6.29289 5L5 6.29289V8.70711L6.29289 10H8V11.618L9.19098 14H10.7771L12.25 11.6434V9.70711L13.4571 8.5L12.25 7.29289V6.29289L10.9571 5H6.29289ZM6 6.70711L6.70711 6H10.5429L11.25 6.70711V7.70711L12.0429 8.5L11.25 9.29289V11.3566L10.2229 13H9.80902L9 11.382V9H6.70711L6 8.29289V6.70711Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM7.5 1.01758C7.66515 1.00593 7.83188 1 8 1C8.91993 1 9.79841 1.17745 10.6031 1.5H8.73241L7.92229 2.71518L7.5 2.29289V1.01758ZM6.5 1.16109C4.0094 1.70485 2.01441 3.57452 1.29189 5.99325L3.55571 7.80451L2.53896 12.3796C3.82191 13.9773 5.79152 15 8 15C11.866 15 15 11.866 15 8C15 7.30503 14.8987 6.63371 14.7101 6H13.8333L11.5 4.25V2.5H9.26759L8.07771 4.28482L6.5 2.70711V1.16109ZM14.3264 5C13.8923 4.08627 13.2661 3.2815 12.5 2.63789V3.75L14.1667 5H14.3264ZM1.77547 11.2056L2.44431 8.19597L1.05893 7.08754C1.02005 7.38619 1 7.69075 1 8C1 9.15525 1.27985 10.2451 1.77547 11.2056Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgEarth.propTypes = iconPropTypes;
