import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgIdCards = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M3.01807 1.5027C3.01807 1.22669 3.24192 1.00293 3.51807 1.00293H9.51807C9.79421 1.00293 10.0181 1.22669 10.0181 1.5027 10.0181 1.77872 9.79421 2.00248 9.51807 2.00248H3.51807C3.24192 2.00248 3.01807 1.77872 3.01807 1.5027ZM0 3.50278C0 3.22676.223858 3.003.5 3.003H3.50978C3.78592 3.003 4.00978 3.22676 4.00978 3.50278 4.00978 3.77879 3.78592 4.00255 3.50978 4.00255H1V11.9991H13.0229V4.00255H9.50451C9.22837 4.00255 9.00451 3.77879 9.00451 3.50278 9.00451 3.22676 9.22837 3.003 9.50451 3.003H13.5229C13.7991 3.003 14.0229 3.22676 14.0229 3.50278V12.4988C14.0229 12.7748 13.7991 12.9986 13.5229 12.9986H.5C.223858 12.9986 0 12.7748 0 12.4988V3.50278Z" />
          <path d="M16.005 6.41357C16.005 6.13755 15.7811 5.9138 15.505 5.9138 15.2288 5.9138 15.005 6.13755 15.005 6.41357V14.0034H1.63086C1.35472 14.0034 1.13086 14.2271 1.13086 14.5032 1.13086 14.7792 1.35472 15.0029 1.63086 15.0029H15.505C15.7811 15.0029 16.005 14.7792 16.005 14.5032V6.41357ZM9.01807 6.49752C9.01807 6.2215 9.24192 5.99774 9.51807 5.99774H11.5181C11.7942 5.99774 12.0181 6.2215 12.0181 6.49752 12.0181 6.77353 11.7942 6.99729 11.5181 6.99729H9.51807C9.24192 6.99729 9.01807 6.77353 9.01807 6.49752Z" />
          <path d="M9.51807 8.00172C9.24192 8.00172 9.01807 8.22548 9.01807 8.50149C9.01807 8.77751 9.24192 9.00127 9.51807 9.00127H11.5181C11.7942 9.00127 12.0181 8.77751 12.0181 8.50149C12.0181 8.22548 11.7942 8.00172 11.5181 8.00172H9.51807Z" />
          <path
            fillRule="evenodd"
            d="M2.51221 7.02462C2.51221 5.92055 3.40764 5.02553 4.51221 5.02553C5.61678 5.02553 6.51221 5.92055 6.51221 7.02462C6.51221 7.54685 6.31187 8.02231 5.98382 8.37841C6.14369 8.47445 6.28849 8.59051 6.41671 8.72718C6.85882 9.19844 7.03223 9.83891 7.03223 10.523C7.03223 10.7991 6.80837 11.0228 6.53223 11.0228C6.25608 11.0228 6.03223 10.7991 6.03223 10.523C6.03223 9.9953 5.89864 9.63622 5.68725 9.4109C5.4813 9.19137 5.13083 9.02372 4.53223 9.02372C3.93363 9.02372 3.58315 9.19137 3.3772 9.4109C3.16582 9.63622 3.03223 9.9953 3.03223 10.523C3.03223 10.7991 2.80837 11.0228 2.53223 11.0228C2.25608 11.0228 2.03223 10.7991 2.03223 10.523C2.03223 9.83891 2.20563 9.19844 2.64775 8.72718C2.7691 8.59782 2.90532 8.48693 3.0551 8.39399C2.71846 8.03624 2.51221 7.55451 2.51221 7.02462ZM4.51221 6.02507C3.95992 6.02507 3.51221 6.47259 3.51221 7.02462C3.51221 7.57666 3.95992 8.02417 4.51221 8.02417C5.06449 8.02417 5.51221 7.57666 5.51221 7.02462C5.51221 6.47259 5.06449 6.02507 4.51221 6.02507Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgIdCards.propTypes = iconPropTypes;
