import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgOctothorpe = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3 5.5C3 5.22386 3.22386 5 3.5 5H13.5C13.7761 5 14 5.22386 14 5.5 14 5.77614 13.7761 6 13.5 6H3.5C3.22386 6 3 5.77614 3 5.5ZM2 10.5C2 10.2239 2.22386 10 2.5 10H12.5C12.7761 10 13 10.2239 13 10.5 13 10.7761 12.7761 11 12.5 11H2.5C2.22386 11 2 10.7761 2 10.5Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M6.58953 2.00815C6.86122 2.05755 7.04142 2.31784 6.99202 2.58953L4.99202 13.5895C4.94262 13.8612 4.68233 14.0414 4.41064 13.992 4.13896 13.9426 3.95875 13.6823 4.00815 13.4106L6.00815 2.41064C6.05755 2.13896 6.31784 1.95875 6.58953 2.00815ZM11.5895 2.00815C11.8612 2.05755 12.0414 2.31784 11.992 2.58953L9.99202 13.5895C9.94262 13.8612 9.68233 14.0414 9.41064 13.992 9.13896 13.9426 8.95875 13.6823 9.00815 13.4106L11.0082 2.41064C11.0575 2.13896 11.3178 1.95875 11.5895 2.00815Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgOctothorpe.propTypes = iconPropTypes;
