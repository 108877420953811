import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgTab = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M6.48553 1.00021L1.49999 1.00029C1.22385 1.00029 1 1.22415 1 1.50029V14.5C1 14.7762 1.22386 15 1.5 15H14.5C14.7761 15 15 14.7762 15 14.5V5.50099V1.50007C15 1.36746 14.9473 1.24028 14.8535 1.14652C14.7598 1.05275 14.6326 1.00007 14.5 1.00007L10.5294 1.00014C10.5255 1.00005 10.5215 1 10.5176 1C10.5136 1 10.5096 1.00005 10.5057 1.00014L6.51445 1.0002C6.50965 1.00007 6.50483 1 6.5 1C6.49516 1 6.49034 1.00007 6.48553 1.00021ZM10.0176 2.00015L7 2.0002V5H10.0176V2.00015ZM11.0176 2.00013V5H14V2.00008L11.0176 2.00013ZM14 6H6.5C6.22386 6 6 5.77614 6 5.5V2.00021L2 2.00028V14H14V6Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgTab.propTypes = iconPropTypes;
