import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgCup = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1 2.5C1 2.22386 1.22371 2 1.49968 2H12.4926C12.7685 2 12.9922 2.22386 12.9922 2.5V3.01114H14.5003C14.7763 3.01114 15 3.235 15 3.51114V7.50731C15 7.78345 14.7763 8.00731 14.5003 8.00731H11.9123L10.5168 11.6813C10.4431 11.8754 10.2572 12.0037 10.0497 12.0037H3.94254C3.73507 12.0037 3.54918 11.8754 3.47546 11.6813L1.0326 5.25012C1.01105 5.19338 1 5.13318 1 5.07247V2.5ZM12.2922 7.00731H14.0006V4.01114H12.9922V5.07247C12.9922 5.13318 12.9812 5.19338 12.9596 5.25012L12.2922 7.00731ZM1.99935 3V4.98065L4.28717 11.0037H9.70507L11.9929 4.98065V3H1.99935Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M1.01171 13.501C1.01171 13.2248 1.23542 13.001 1.51139 13.001L12.5043 13.001C12.7802 13.001 13.004 13.2248 13.004 13.501C13.0039 13.7771 12.7802 14.001 12.5043 14.001L1.51139 14.001C1.23542 14.001 1.01171 13.7771 1.01171 13.501Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgCup.propTypes = iconPropTypes;
