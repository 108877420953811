import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgBoxDisc = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 17"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M4.5 6C4.22386 6 4 6.22386 4 6.5C4 6.77614 4.22386 7 4.5 7H9.5C9.77614 7 10 6.77614 10 6.5C10 6.22386 9.77614 6 9.5 6H4.5Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M10.0122 11.5059C10.0122 12.3343 10.6838 13.0059 11.5122 13.0059C12.3406 13.0059 13.0122 12.3343 13.0122 11.5059C13.0122 10.6774 12.3406 10.0059 11.5122 10.0059C10.6838 10.0059 10.0122 10.6774 10.0122 11.5059ZM11.5122 12.0059C11.2361 12.0059 11.0122 11.782 11.0122 11.5059C11.0122 11.2297 11.2361 11.0059 11.5122 11.0059C11.7883 11.0059 12.0122 11.2297 12.0122 11.5059C12.0122 11.782 11.7883 12.0059 11.5122 12.0059Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M0 1.5V13.5266C0 14.355 0.671571 15.0266 1.5 15.0266H8.65499C9.42726 15.6501 10.4099 16.0234 11.4797 16.0234C13.965 16.0234 15.9797 14.0087 15.9797 11.5234C15.9797 9.98678 15.2095 8.63002 14.0341 7.81815V1.5C14.0341 0.671573 13.3625 0 12.5341 0H1.5C0.671573 0 0 0.671572 0 1.5ZM1.5 1C1.22386 1 1 1.22386 1 1.5V3H13.0341V1.5C13.0341 1.22386 12.8102 1 12.5341 1H1.5ZM6.97974 11.5234C6.97974 12.4499 7.2597 13.3109 7.73963 14.0266H1.5C1.22386 14.0266 1 13.8027 1 13.5266V4H13.0341V7.29909C12.5496 7.12079 12.0261 7.02344 11.4797 7.02344C8.99446 7.02344 6.97974 9.03816 6.97974 11.5234ZM11.4797 15.0234C13.4127 15.0234 14.9797 13.4564 14.9797 11.5234C14.9797 9.59044 13.4127 8.02344 11.4797 8.02344C9.54674 8.02344 7.97974 9.59044 7.97974 11.5234C7.97974 13.4564 9.54674 15.0234 11.4797 15.0234Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgBoxDisc.propTypes = iconPropTypes;
