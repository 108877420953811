import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgArrowRightCircle = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M4 8.50098C4 8.22483 4.22386 8.00098 4.5 8.00098H11.2891L9.64694 6.35882C9.45167 6.16356 9.45167 5.84698 9.64694 5.65172C9.8422 5.45646 10.1588 5.45646 10.354 5.65172L12.8536 8.15128C13.0489 8.34654 13.0489 8.66312 12.8536 8.85839L10.354 11.3579C10.1588 11.5532 9.8422 11.5532 9.64693 11.3579C9.45167 11.1627 9.45167 10.8461 9.64693 10.6508L11.2968 9.00098L4.5 9.00098C4.22386 9.00098 4 8.77712 4 8.50098Z" />
          <path
            fillRule="evenodd"
            d="M1 8.49296C1 4.33607 4.35957 1.00476 8.5 1.00476C12.6404 1.00476 16 4.33607 16 8.49295C16 12.6476 12.6426 16.0048 8.5 16.0048C4.35737 16.0048 1 12.6476 1 8.49296ZM8.5 2.00476C4.90844 2.00476 2 4.89177 2 8.49296C2 12.0963 4.91064 15.0048 8.5 15.0048C12.0894 15.0048 15 12.0963 15 8.49295C15 4.89177 12.0916 2.00476 8.5 2.00476Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" transform="rotate(-90 8 8)" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgArrowRightCircle.propTypes = iconPropTypes;
