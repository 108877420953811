import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgCrop = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M3.5 0C3.77614 0 4 .223858 4 .5V2H3V.5C3 .223858 3.22386 0 3.5 0ZM13 3H.5C.223857 3 0 3.22386 0 3.5 0 3.77614.223857 4 .5 4H12L12 15.5C12 15.7761 12.2239 16 12.5 16 12.7761 16 13 15.7761 13 15.5L13 3Z"
        />
        <path
          fill="currentColor"
          d="M4 5H3V13L11 13V12H4L4 5ZM15.5 13C15.7761 13 16 12.7761 16 12.5 16 12.2239 15.7761 12 15.5 12H14V13H15.5Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgCrop.propTypes = iconPropTypes;
