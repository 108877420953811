import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgPause = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1 2C1 1.44772 1.44745 1 1.99941 1H5.99238C6.54434 1 6.99179 1.44772 6.99179 2V14C6.99179 14.5523 6.54434 15 5.99238 15H1.99941C1.44745 15 1 14.5523 1 14V2ZM1.99941 2H5.99238L5.99238 14 1.99941 14 1.99941 2ZM9.00355 2C9.00355 1.44772 9.451 1 10.003 1H14.0006C14.5525 1 15 1.44772 15 2V14C15 14.5523 14.5525 15 14.0006 15H10.003C9.451 15 9.00355 14.5523 9.00355 14V2ZM10.003 2H14.0006V14L10.003 14V2Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgPause.propTypes = iconPropTypes;
