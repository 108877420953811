import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgBrandMstranslator = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M10.466 6.2947C10.3813 6.12531 10.2082 6.01831 10.0188 6.01831C9.82941 6.01831 9.65628 6.12531 9.57158 6.2947L8.69865 8.04057C8.66702 8.02677 8.63346 8.01606 8.59829 8.00888C8.06563 7.90016 7.61893 7.68536 7.25445 7.39485C7.65755 6.80123 7.93554 6.03195 8.00295 4.99902H8.49829C8.77443 4.99902 8.99829 4.77517 8.99829 4.49902C8.99829 4.22288 8.77443 3.99902 8.49829 3.99902H7.53457L7.5188 3.99878L7.50302 3.99902H5.51407C5.50883 3.99886 5.50357 3.99878 5.49829 3.99878C5.49301 3.99878 5.48775 3.99886 5.48252 3.99902H4.49829C4.22215 3.99902 3.99829 4.22288 3.99829 4.49902C3.99829 4.77517 4.22215 4.99902 4.49829 4.99902H5.02139C5.10038 5.85329 5.38418 6.71429 5.90592 7.42489C5.513 7.81418 5.07497 7.99873 4.49829 7.99873C4.22215 7.99873 3.99829 8.22259 3.99829 8.49873C3.99829 8.77487 4.22215 8.99873 4.49829 8.99873C5.33952 8.99873 6.02221 8.70964 6.59344 8.15127C7.03968 8.51612 7.58636 8.79702 8.24193 8.95402L7.07159 11.2947C6.94809 11.5417 7.0482 11.842 7.29519 11.9655C7.54218 12.089 7.84252 11.9889 7.96601 11.7419L8.83722 9.99951H11.2004L12.0716 11.7419C12.1951 11.9889 12.4954 12.089 12.7424 11.9655C12.9894 11.842 13.0895 11.5417 12.966 11.2947L10.466 6.2947ZM6.56273 6.61222C6.26613 6.13508 6.08938 5.57855 6.02553 4.99902H7.00058C6.94798 5.69214 6.78532 6.21016 6.56273 6.61222ZM10.0188 7.63634L9.33722 8.99951H10.7004L10.0188 7.63634Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3.07756 1.00195C1.9347 1.00195 1.00171 1.93495 1.00171 3.07781V12.9269C1.00171 14.0698 1.9347 15.0028 3.07756 15.0028H12.9267C14.0695 15.0028 15.0025 14.0698 15.0025 12.9269V3.07781C15.0025 1.93495 14.0695 1.00195 12.9267 1.00195H3.07756ZM2.00171 3.07781C2.00171 2.48723 2.48699 2.00195 3.07756 2.00195H12.9267C13.5172 2.00195 14.0025 2.48723 14.0025 3.07781V12.9269C14.0025 13.5175 13.5172 14.0028 12.9267 14.0028H3.07756C2.48699 14.0028 2.00171 13.5175 2.00171 12.9269V3.07781Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgBrandMstranslator.propTypes = iconPropTypes;
