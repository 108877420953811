import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLListArticle = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M8.51167 1H1.51899C1.24313 1 1.01951 1.22345 1.01951 1.49909V2.49726C1.01951 2.7729 1.24313 2.99634 1.51899 2.99634H8.51167C8.78753 2.99634 9.01115 2.7729 9.01115 2.49726V1.49909C9.01115 1.22345 8.78753 1 8.51167 1ZM8.51021 8.99074H1.51752C1.24167 8.99074 1.01805 9.21419 1.01805 9.48983V10.488C1.01805 10.7636 1.24167 10.9871 1.51752 10.9871H8.51021C8.78606 10.9871 9.00969 10.7636 9.00969 10.488V9.48983C9.00969 9.21419 8.78606 8.99074 8.51021 8.99074ZM1.49948 4.00037C1.22362 4.00037 1 4.22381 1 4.49945 1 4.77509 1.22362 4.99854 1.49948 4.99854L14.4859 4.99854C14.7617 4.99854 14.9854 4.77509 14.9854 4.49945 14.9854 4.22382 14.7617 4.00037 14.4859 4.00037L1.49948 4.00037ZM1.01463 12.5009C1.01463 12.2253 1.23826 12.0018 1.51411 12.0018L14.5005 12.0018C14.7764 12.0018 15 12.2253 15 12.5009 15 12.7766 14.7764 13 14.5005 13L1.51411 13C1.23826 13 1.01463 12.7766 1.01463 12.5009ZM1.51899 5.98404C1.24313 5.98404 1.01951 6.20749 1.01951 6.48312 1.01951 6.75876 1.24313 6.98221 1.51899 6.98221L11.5085 6.98221C11.7844 6.98221 12.008 6.75876 12.008 6.48313 12.008 6.20749 11.7844 5.98404 11.5085 5.98404L1.51899 5.98404Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLListArticle.propTypes = iconPropTypes;
