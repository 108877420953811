import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgRectangleH = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M15 12.5L14.9998 3.49999C14.9998 3.22385 14.7759 3 14.4998 3L1.50002 3C1.22388 3 1.00002 3.22386 1.00002 3.5L1.00002 12.5C1.00002 12.7761 1.22388 13 1.50002 13L14.5 13C14.7761 13 15 12.7761 15 12.5ZM15.9998 3.49997L16 12.5C16 13.3284 15.3284 14 14.5 14L1.50002 14C0.671596 14 2.33467e-05 13.3284 2.33829e-05 12.5L2.37763e-05 3.5C2.38125e-05 2.67157 0.671597 2 1.50002 2L14.4998 2C15.3282 2 15.9998 2.67155 15.9998 3.49997Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgRectangleH.propTypes = iconPropTypes;
