import PropTypes, { Requireable, Validator } from 'prop-types';

export const unrequired: <T>(validator: Validator<T> | undefined) => Requireable<T> = (
  validator,
) => {
  return PropTypes.oneOfType([
    ...(validator ? [validator] : []),
    PropTypes.oneOf([null, undefined]),
  ]);
};
