import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgGauge = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M3.19623 14.137C3.39669 14.3269 3.40524 14.6434 3.21532 14.8439 3.02541 15.0443 2.70894 15.0529 2.50848 14.863.964333 13.4001 0 11.3256 0 9.02632 0 4.59515 3.58007 1 8 1 9.60953 1 11.1093 1.4774 12.3647 2.29876 12.5957 2.44995 12.6605 2.75984 12.5093 2.99092 12.3581 3.22199 12.0482 3.28675 11.8172 3.13556 10.7194 2.41733 9.40899 2 8 2 4.13565 2 1 5.14413 1 9.02632 1 11.0404 1.84354 12.8555 3.19623 14.137ZM13.4915 14.863C13.2911 15.0529 12.9746 15.0443 12.7847 14.8439 12.5948 14.6434 12.6033 14.3269 12.8038 14.137 14.1565 12.8555 15 11.0404 15 9.02632 15 7.97888 14.772 6.98631 14.3636 6.09471 14.2486 5.84365 14.3589 5.5469 14.61 5.4319 14.861 5.31691 15.1578 5.42721 15.2728 5.67827 15.7398 6.69798 16 7.83237 16 9.02632 16 11.3256 15.0357 13.4001 13.4915 14.863Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M9.54703 6.46019C9.09246 6.18289 8.55834 6.02312 7.98691 6.02312C6.33006 6.02312 4.98691 7.36627 4.98691 9.02312C4.98691 10.68 6.33006 12.0231 7.98691 12.0231C9.64377 12.0231 10.9869 10.68 10.9869 9.02312C10.9869 8.6487 10.9183 8.29029 10.793 7.95979L14.499 3.93544C14.5163 3.91728 14.532 3.89804 14.546 3.87791C14.6881 3.67586 14.6619 3.39491 14.4753 3.22305C14.2911 3.05344 14.0139 3.04833 13.8243 3.20027L9.54703 6.46019ZM7.98691 11.0231C6.88234 11.0231 5.98691 10.1277 5.98691 9.02312C5.98691 7.91855 6.88234 7.02312 7.98691 7.02312C9.09148 7.02312 9.98691 7.91855 9.98691 9.02312C9.98691 10.1277 9.09148 11.0231 7.98691 11.0231Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgGauge.propTypes = iconPropTypes;
