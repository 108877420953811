import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLHeaderCols2Footer = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.50577 0C1.22968 0 1.00586.22407 1.00586.500473V2.50485C1.00586 2.78126 1.22968 3.00533 1.50577 3.00533H14.5001C14.7762 3.00533 15 2.78126 15 2.50485V.500473C15 .22407 14.7762 0 14.5001 0H1.50577ZM2.00568 2.00438V1.00095H14.0002V2.00438H2.00568ZM1.49991 4.00819C1.22382 4.00819 1 4.23226 1 4.50866V11.5153C1 11.7917 1.22382 12.0158 1.49991 12.0158H6.49902C6.77512 12.0158 6.99894 11.7917 6.99894 11.5153V4.50866C6.99894 4.23226 6.77512 4.00819 6.49902 4.00819H1.49991ZM1.99982 11.0148V5.00913H5.99911V11.0148H1.99982ZM1 13.4976C1 13.2212 1.22382 12.9972 1.49991 12.9972H14.5C14.7761 12.9972 14.9999 13.2212 14.9999 13.4976V15.4995C14.9999 15.7759 14.7761 16 14.5 16H1.49991C1.22382 16 1 15.7759 1 15.4995V13.4976ZM1.99982 13.9981V14.9991H14.0001V13.9981H1.99982ZM9.49849 4.00819C9.2224 4.00819 8.99858 4.23226 8.99858 4.50866V11.5153C8.99858 11.7917 9.2224 12.0158 9.49849 12.0158H14.5C14.7761 12.0158 14.9999 11.7917 14.9999 11.5153V4.50866C14.9999 4.23226 14.7761 4.00819 14.5 4.00819H9.49849ZM9.9984 11.0148V5.00913H14.0001V11.0148H9.9984Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLHeaderCols2Footer.propTypes = iconPropTypes;
