import styled, { css } from 'styled-components';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { IBaseButtonComponentProps } from './BaseButtonComponent.tsx';

interface IStyledButtonWrapperProps extends Pick<IBaseButtonComponentProps, 'buttonDisplay'> {
  readonly isFocusVisible: boolean;
}

export const StyledButtonWrapper = styled.div<IStyledButtonWrapperProps>`
  ${({ isFocusVisible }) => isFocusVisible && shadowFocusStyles};
  border-radius: ${px(BorderRadius.Pill)};
  ${(props) =>
    props.buttonDisplay === 'block'
      ? css`
      display: block;
      width: 100%;
    `
      : css`display: inline-block;`};`;
