import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgOne = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M6.93615 1.3121C7.19854 1.10975 7.52054 1 7.85187 1H9.50032C10.3286 1 11 1.67157 11 2.5V13.5C11 14.3284 10.3286 15 9.50032 15H8.51098C7.68272 15 6.99944 14.3284 6.99944 13.5V5.04545C6.99944 4.76931 7.22325 4.54545 7.49933 4.54545C7.77541 4.54545 7.99922 4.76931 7.99922 5.04545V13.5C7.99922 13.7761 8.23489 14 8.51098 14H9.50032C9.7764 14 10.0002 13.7761 10.0002 13.5V2.5C10.0002 2.22386 9.7764 2 9.50032 2H7.85187C7.74142 2 7.63409 2.03658 7.54663 2.10403L3.19738 5.11342C2.8196 5.40476 3.02558 6.00938 3.50262 6.00938L5.49888 6.00088C5.77496 6.00088 5.99877 6.22473 5.99877 6.50088C5.99877 6.77702 5.77496 7.00088 5.49888 7.00088L3.50262 7.00938C2.0715 7.00938 1.45355 5.19552 2.5869 4.32148L6.93615 1.3121Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgOne.propTypes = iconPropTypes;
