import React from 'react';
import styled, { css } from 'styled-components';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { transition250 } from '../../../tokens/quarks/transitions.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { Anchor, RouterLink, RouterLinkProps } from '../../Anchor/Anchor.tsx';
import { ButtonDisplay } from '../buttonDisplay.ts';
import { ButtonSize } from '../buttonSize.ts';
import { ButtonStyle } from '../buttonStyle.ts';
import {
  getSizeButtonStyles,
  getSpecificButtonStyles,
  getTypographyStyles,
} from '../utils/stylingUtils.ts';

const baseButtonStyles = css<IBaseStyledButtonProps>`
  &::before {
    ${({ $isFocusVisible }) => $isFocusVisible && shadowFocusStyles};
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: ${px(BorderRadius.Pill)};
  }

  border-radius: ${px(BorderRadius.Pill)};
  border-width: 0;
  border-style: solid;
  cursor: pointer;
  margin-bottom: 0;
  outline: none;
  position: relative;
  touch-action: manipulation;
  transition: background-color ${transition250};
  transition-property: background-color, color, border-color;

  user-select: none;
  white-space: nowrap;

  &:hover,
  &:focus,
  & {
    outline: none;
    text-decoration: none;
  }

  &:active {
    outline: none;
  }

  ${({ $buttonDisplay }) =>
    $buttonDisplay === 'block' &&
    css`
    display: block;
    width: 100%;
  `};
  ${({ $buttonStyle, $size }) => getSizeButtonStyles($buttonStyle, $size)};
  ${({ $size }) => getTypographyStyles($size)};
`;

// Basic button props
interface IBaseStyledButtonProps {
  readonly $buttonDisplay?: ButtonDisplay;
  readonly $buttonStyle: ButtonStyle;
  readonly $size: ButtonSize;
  readonly disabled?: boolean;
  readonly destructive?: boolean;
  readonly $isFocusVisible: boolean;
}

// Button
export interface IStyledButtonProps
  extends IBaseStyledButtonProps,
    React.ButtonHTMLAttributes<HTMLButtonElement> {
  readonly disabled?: boolean;
  readonly $activated?: boolean;
}

export const StyledButton = styled.button<IStyledButtonProps>`
  ${baseButtonStyles};
  ${({ disabled }) =>
    disabled &&
    css`
    &,
    &[disabled] {
      cursor: not-allowed;
    }
  `};
  ${({ disabled, $buttonStyle, destructive }) =>
    getSpecificButtonStyles($buttonStyle, disabled, destructive)};
`;

StyledButton.displayName = 'StyledButton';

export interface IStyledRouterLinkButtonProps extends IBaseStyledButtonProps, RouterLinkProps {}

export const StyledRouterLinkButton = styled(RouterLink)<IStyledRouterLinkButtonProps>`
  ${baseButtonStyles};
  ${({ $buttonStyle, destructive }) => getSpecificButtonStyles($buttonStyle, false, destructive)};
  display: flex;
`;
StyledRouterLinkButton.displayName = 'StyledRouterLinkButton';

// Styled Anchor Button
export interface IStyledAnchorButtonProps
  extends IBaseStyledButtonProps,
    React.AnchorHTMLAttributes<HTMLAnchorElement> {}

export const StyledAnchorButton = styled(Anchor)<IStyledAnchorButtonProps>`
  ${baseButtonStyles};
  ${({ $buttonStyle, destructive }) => getSpecificButtonStyles($buttonStyle, false, destructive)};
  display: flex;
`;

StyledAnchorButton.displayName = 'StyledAnchorButton';
