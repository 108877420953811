import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgTwoRectanglesV = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M9.50891 0C9.23216 0 9.00781.224584 9.00781.501623V12.5094C9.00781 12.7865 9.23216 13.0111 9.50891 13.0111H15.4989C15.7757 13.0111 16 12.7865 16 12.5094V.501623C16 .224584 15.7757 0 15.4989 0H9.50891ZM10.01 12.0078V1.00325H14.9978V12.0078H10.01ZM.501101 3.00595C.224351 3.00595 0 3.23053 0 3.50757V15.4984C0 15.7754.224351 16 .501101 16H6.51431C6.79106 16 7.01542 15.7754 7.01542 15.4984V3.50757C7.01542 3.23053 6.79106 3.00595 6.51431 3.00595H.501101ZM1.0022 14.9968V4.00919H6.01321V14.9968H1.0022Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgTwoRectanglesV.propTypes = iconPropTypes;
