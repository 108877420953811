import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgHeart = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M0.00341797 5.71874C0.00341797 3.43167 1.52393 1.00342 4.31038 1.00342C5.65031 1.00342 6.67282 1.49563 7.35563 1.98424C7.62141 2.17443 7.83704 2.36489 8.00198 2.52747C8.16692 2.36489 8.38254 2.17443 8.64833 1.98424C9.33114 1.49563 10.3536 1.00342 11.6936 1.00342C14.48 1.00342 16.0005 3.43167 16.0005 5.71874C16.0005 6.93479 15.4339 7.93194 14.8057 8.69807C14.3449 9.26001 13.8074 9.74374 13.4154 10.0965C13.2839 10.2149 13.1688 10.3185 13.0784 10.4054C12.695 10.7741 11.7449 11.6648 10.7931 12.5572C9.689 13.5924 8.5826 14.6297 8.35569 14.8568C8.26191 14.9507 8.13466 15.0034 8.00198 15.0034C7.86929 15.0034 7.74205 14.9507 7.64827 14.8568C7.40039 14.6087 6.26526 13.5448 5.14976 12.4992C4.2192 11.627 3.3023 10.7677 2.92556 10.4054C2.8352 10.3185 2.72005 10.2149 2.58851 10.0965C2.19655 9.74373 1.65907 9.26001 1.1983 8.69807C0.570095 7.93194 0.00341797 6.93479 0.00341797 5.71874ZM8.40847 3.56943C8.31458 3.70052 8.16323 3.77829 8.00198 3.77829C7.84087 3.77829 7.68965 3.70066 7.59575 3.5698L7.58915 3.56102C7.58236 3.55209 7.57085 3.53724 7.55466 3.51731C7.52224 3.47742 7.47125 3.4174 7.40198 3.3439C7.2631 3.19654 7.05284 2.99722 6.77369 2.79747C6.21635 2.39864 5.39306 2.00342 4.31038 2.00342C2.25161 2.00342 1.00342 3.79048 1.00342 5.71874C1.00342 6.61034 1.41732 7.38806 1.97158 8.06401C2.37955 8.56156 2.82119 8.95906 3.20369 9.30332C3.35284 9.43758 3.49301 9.56373 3.61868 9.68457C3.97048 10.0229 4.86361 10.8602 5.7844 11.7234C6.63509 12.5209 7.50939 13.3406 8.00224 13.8093C8.48087 13.3546 9.32619 12.5619 10.1586 11.7813C11.1011 10.8974 12.027 10.0291 12.3853 9.68457C12.5109 9.56373 12.6511 9.43757 12.8003 9.30332C13.1828 8.95905 13.6244 8.56155 14.0324 8.06401C14.5866 7.38806 15.0005 6.61034 15.0005 5.71874C15.0005 3.79048 13.7523 2.00342 11.6936 2.00342C10.6109 2.00342 9.78761 2.39864 9.23027 2.79747C8.95112 2.99722 8.74085 3.19654 8.60198 3.3439C8.53271 3.4174 8.48171 3.47742 8.4493 3.51731C8.43311 3.53724 8.4216 3.55209 8.41481 3.56102L8.40847 3.56943Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgHeart.propTypes = iconPropTypes;
