import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgDialogWindow = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M13.0039 2.98926 12.0039 2.98926V3.98926H13.0039V2.98926ZM10.0015 2.98926H11.0015V3.98926H10.0015V2.98926Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1 2.68182C1 2.23621 1.12602 1.81792 1.39331 1.50203C1.66691 1.17868 2.05859 1 2.5 1H13.5C13.9414 1 14.3331 1.17868 14.6067 1.50203C14.874 1.81792 15 2.23621 15 2.68182V13.3182C15 13.7638 14.874 14.1821 14.6067 14.498C14.3331 14.8213 13.9414 15 13.5 15H2.5C2.05859 15 1.66691 14.8213 1.39331 14.498C1.12602 14.1821 1 13.7638 1 13.3182V2.68182ZM2 2.68182C2 2.41834 2.07398 2.24572 2.15669 2.14797C2.23309 2.05768 2.34141 2 2.5 2H13.5C13.6586 2 13.7669 2.05768 13.8433 2.14797C13.926 2.24572 14 2.41834 14 2.68182V5.9751L2 5.9751V2.68182ZM14 6.9751V13.3182C14 13.5817 13.926 13.7543 13.8433 13.852C13.7669 13.9423 13.6586 14 13.5 14H2.5C2.34141 14 2.23309 13.9423 2.15669 13.852C2.07398 13.7543 2 13.5817 2 13.3182V6.9751L14 6.9751Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgDialogWindow.propTypes = iconPropTypes;
