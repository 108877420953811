import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgShareApple = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M8.49996 10C8.22382 10 7.99996 9.77614 7.99996 9.5L7.99996 1.70715L6.35355 3.35355C6.15829 3.54882 5.84171 3.54882 5.64645 3.35355C5.45118 3.15829 5.45118 2.84171 5.64645 2.64645L8.14269 0.150202C8.15693 0.135663 8.17205 0.121989 8.18796 0.109267C8.2191 0.0843302 8.25254 0.0636615 8.28748 0.0472614C8.35197 0.0169449 8.42399 0 8.49996 0C8.56176 0 8.62095 0.0112127 8.67559 0.0317147C8.74049 0.0560053 8.80136 0.0942496 8.85355 0.146447L11.3536 2.64645C11.5488 2.84171 11.5488 3.15829 11.3536 3.35355C11.1583 3.54882 10.8417 3.54882 10.6464 3.35355L8.99996 1.70707L8.99996 9.5C8.99996 9.77614 8.7761 10 8.49996 10Z"
        />
        <path
          fill="currentColor"
          d="M2.5 16C2.22386 16 2 15.7761 2 15.5V5.5C2 5.22386 2.22386 5 2.5 5H6.5C6.77614 5 7 5.22386 7 5.5C7 5.77614 6.77614 6 6.5 6H3V15H14V6H10.5C10.2239 6 10 5.77614 10 5.5C10 5.22386 10.2239 5 10.5 5H14.5C14.7761 5 15 5.22386 15 5.5V15.5C15 15.7761 14.7761 16 14.5 16H2.5Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgShareApple.propTypes = iconPropTypes;
