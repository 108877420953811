import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLeaflet = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M5.49815 6.00303C5.77412 6.00303 5.99784 6.22689 5.99784 6.50303V7.50178C5.99784 7.77792 5.77412 8.00178 5.49815 8.00178 5.22218 8.00178 4.99847 7.77792 4.99847 7.50178V6.50303C4.99847 6.22689 5.22218 6.00303 5.49815 6.00303ZM10.0031 8.49424C10.0031 8.2181 9.77942 7.99424 9.50345 7.99424 9.22748 7.99424 9.00376 8.2181 9.00376 8.49424V9.49424C9.00376 9.77038 9.22748 9.99424 9.50345 9.99424 9.77942 9.99424 10.0031 9.77038 10.0031 9.49424V8.49424Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M5.24301 1.06962C5.32615 1.02047 5.42003 0.997353 5.51329 1.00036C5.60705 0.991304 5.70442 1.00863 5.79396 1.05589L9.54288 3.03439L14.3074 1.03887C14.4616 0.97427 14.6379 0.991151 14.7772 1.08385C14.9164 1.17655 15 1.33278 15 1.5001V12.4443C15 12.647 14.8777 12.8297 14.6903 12.9068L9.73333 14.9461C9.5942 15.0181 9.42347 15.0237 9.2738 14.9462L5.47458 12.9785L1.73175 14.9425C1.57686 15.0237 1.39077 15.0181 1.24111 14.9275C1.09145 14.8369 1 14.6747 1 14.4997V3.86329C1 3.68645 1.09336 3.52276 1.24552 3.4328L5.24301 1.06962ZM9.00376 13.6803V12.1365C9.00376 11.8603 9.22748 11.6365 9.50345 11.6365C9.77942 11.6365 10.0031 11.8603 10.0031 12.1365V13.7539L14.0006 12.1093V2.25142L10.0031 3.9257V5.5098C10.0031 5.78594 9.77938 6.0098 9.50341 6.0098C9.22744 6.0098 9.00373 5.78594 9.00373 5.5098V3.88042L5.99686 2.29354V3.52247C5.99686 3.79861 5.77315 4.02247 5.49718 4.02247C5.22121 4.02247 4.99749 3.79861 4.99749 3.52247V2.37624L1.99937 4.14863V13.6729L5.00339 12.0966V10.5134C5.00339 10.2372 5.2271 10.0134 5.50307 10.0134C5.77904 10.0134 6.00276 10.2372 6.00276 10.5134V12.126L9.00376 13.6803Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLeaflet.propTypes = iconPropTypes;
