import { UnreachableCaseException } from '@kontent-ai/errors';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { colorIconDefaultInverse, colorIconDisabled } from '../../tokens/decision/colors.ts';
import { IconSize } from '../../tokens/quarks/iconSize.ts';
import { px } from '../../tokens/utils/utils.ts';
import { AnimatedProgressIcon } from '../Icons/AnimatedProgressIcon.tsx';
import { Icons } from '../Icons/components/icons.ts';
import { Icon } from '../Icons/types.ts';
import { ButtonSize } from './buttonSize.ts';
import { StyledButton } from './components/StyledButton.tsx';
import {
  buttonIconColorVariableName,
  buttonIconHoverColorVariableName,
  buttonIconSizeVariableName,
} from './utils/cssVariableNames.ts';

interface IButtonIconProps {
  readonly className?: string;
  readonly icon: Icon;
}

const buttonIconPropTypes: PropTypeMap<IButtonIconProps> = {
  className: PropTypes.string,
  icon: PropTypes.oneOf([...Object.values(Icons), AnimatedProgressIcon]).isRequired,
};

export const getButtonIconSize = (size: ButtonSize) => {
  switch (size) {
    case 'small':
      return css`${px(IconSize.S)}`;
    case 'medium':
      return css`${px(IconSize.M)}`;
    case 'large':
      return css`${px(IconSize.L)}`;
    default:
      throw UnreachableCaseException(size);
  }
};

export const getDestructiveIconHoverColor = (disabled?: boolean, destructive?: boolean) =>
  destructive
    ? disabled
      ? colorIconDisabled
      : colorIconDefaultInverse
    : `var(${buttonIconColorVariableName})`;

const StyledIcon = styled.div`
  display: block;
  color: var(${buttonIconColorVariableName});

  ${StyledButton}:hover &&,
  ${StyledButton}:active && {
    color: var(${buttonIconHoverColorVariableName});
  }

  font-size: var(${buttonIconSizeVariableName});
`;

export const ButtonIcon: React.FC<IButtonIconProps> = ({ icon, className }) => (
  <StyledIcon as={icon} className={className} />
);

ButtonIcon.displayName = 'ButtonIcon';
ButtonIcon.propTypes = buttonIconPropTypes;
