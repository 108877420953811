import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgArrowsTogetherH = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M2.13579 4.14685C2.33084 3.95136 2.64742 3.95101 2.8429 4.14605L6.83163 8.1258C6.93491 8.2174 7 8.3511 7 8.5 7 8.6489 6.93491 8.7826 6.83164 8.8742L2.8429 12.854C2.64742 13.049 2.33084 13.0486 2.13579 12.8532 1.94075 12.6577 1.94111 12.3411 2.13659 12.146L5.28974 9H.5C.223858 9 0 8.77614 0 8.5 0 8.22386.223858 8 .5 8H5.28974L2.13659 4.85395C1.94111 4.65891 1.94075 4.34233 2.13579 4.14685ZM13.8465 12.8536C13.6515 13.0488 13.3354 13.0488 13.1404 12.8536L9.14623 8.85355C8.95126 8.65829 8.95126 8.34171 9.14623 8.14645L13.1404 4.14645C13.3354 3.95118 13.6515 3.95118 13.8465 4.14645 14.0414 4.34171 14.0414 4.65829 13.8465 4.85355L10.7046 8H15.5007C15.7765 8 16 8.22386 16 8.5 16 8.77614 15.7765 9 15.5007 9H10.7046L13.8465 12.1464C14.0414 12.3417 14.0414 12.6583 13.8465 12.8536Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgArrowsTogetherH.propTypes = iconPropTypes;
