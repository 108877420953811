import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLCalendarNumberArticle = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M5.49876 1.99707C5.77475 1.99707 5.99848 2.22093 5.99848 2.49707V2.99707H6.49623C6.77222 2.99707 6.99594 3.22093 6.99594 3.49707V5.4875L6.99598 5.49316L6.99594 5.49883V9.5C6.99594 9.77614 6.77222 10 6.49623 10H0.49971C0.223728 10 0 9.77614 0 9.5V3.49707C0 3.22093 0.223728 2.99707 0.49971 2.99707H0.997957V2.49729C0.997957 2.22115 1.22168 1.99729 1.49767 1.99729C1.77365 1.99729 1.99738 2.22115 1.99738 2.49729V2.99707H4.99905V2.49707C4.99905 2.22093 5.22278 1.99707 5.49876 1.99707ZM1.51273 3.99707H5.99652V4.99316H0.999421V3.99707H1.48261L1.49767 3.99729L1.51273 3.99707ZM5.99652 5.99316H0.999421V9H5.99652V5.99316Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M8.00073 7.49805C8.00073 7.2219 8.22446 6.99805 8.50044 6.99805L15.4964 6.99805C15.7724 6.99805 15.9961 7.2219 15.9961 7.49805 15.9961 7.77419 15.7724 7.99805 15.4964 7.99805L8.50044 7.99805C8.22446 7.99805 8.00073 7.77419 8.00073 7.49805ZM8.00073 9.5C8.00073 9.22386 8.22446 9 8.50044 9H15.4964C15.7724 9 15.9961 9.22386 15.9961 9.5 15.9961 9.77614 15.7724 10 15.4964 10H8.50044C8.22446 10 8.00073 9.77614 8.00073 9.5ZM8.50044 11.0005C8.22446 11.0005 8.00073 11.2243 8.00073 11.5005 8.00073 11.7766 8.22446 12.0005 8.50044 12.0005L15.5003 12.0005C15.7763 12.0005 16 11.7766 16 11.5005 16 11.2243 15.7763 11.0005 15.5003 11.0005L8.50044 11.0005ZM8.00073 13.5C8.00073 13.2239 8.22446 13 8.50044 13L15.5003 13C15.7763 13 16 13.2239 16 13.5 16 13.7761 15.7763 14 15.5003 14L8.50044 14C8.22446 14 8.00073 13.7761 8.00073 13.5ZM8.50044 3.00781H13.4975C13.7735 3.00781 13.9973 3.23167 13.9973 3.50781V4.50781C13.9973 4.78395 13.7735 5.00781 13.4975 5.00781H8.50044C8.22446 5.00781 8.00073 4.78395 8.00073 4.50781V3.50781C8.00073 3.23167 8.22446 3.00781 8.50044 3.00781Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLCalendarNumberArticle.propTypes = iconPropTypes;
