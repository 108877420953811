import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgPercentSign = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M15.8536 0.146447C16.0488 0.341709 16.0488 0.658291 15.8536 0.853553L0.853553 15.8536C0.658291 16.0488 0.341709 16.0488 0.146447 15.8536C-0.0488155 15.6583 -0.0488155 15.3417 0.146447 15.1464L15.1464 0.146447C15.3417 -0.0488155 15.6583 -0.0488155 15.8536 0.146447Z" />
          <path
            fillRule="evenodd"
            d="M9 12.5C9 10.567 10.567 9 12.5 9 14.433 9 16 10.567 16 12.5 16 14.433 14.433 16 12.5 16 10.567 16 9 14.433 9 12.5ZM12.5 10C11.1193 10 10 11.1193 10 12.5 10 13.8807 11.1193 15 12.5 15 13.8807 15 15 13.8807 15 12.5 15 11.1193 13.8807 10 12.5 10ZM3.57.45058e-9C1.5677.45058e-97.45058e-9 1.5677.45058e-9 3.57.45058e-9 5.433 1.567 7 3.5 7 5.433 7 7 5.433 7 3.5 7 1.567 5.4337.45058e-9 3.57.45058e-9ZM1 3.5C1 2.11929 2.11929 1 3.5 1 4.88071 1 6 2.11929 6 3.5 6 4.88071 4.88071 6 3.5 6 2.11929 6 1 4.88071 1 3.5Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgPercentSign.propTypes = iconPropTypes;
