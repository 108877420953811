import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLLogotypeMenuVCol = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.00049 2.99819C1.00049 1.89462 1.89723 1 3.00342 1C4.10961 1 5.00636 1.89462 5.00636 2.99819C5.00636 4.10176 4.10961 4.99638 3.00342 4.99638C1.89723 4.99638 1.00049 4.10176 1.00049 2.99819ZM3.00342 1.99909C2.45033 1.99909 2.00196 2.4464 2.00196 2.99819C2.00196 3.54997 2.45033 3.99728 3.00342 3.99728C3.55652 3.99728 4.00489 3.54997 4.00489 2.99819C4.00489 2.4464 3.55652 1.99909 3.00342 1.99909Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M1 6.5077C1 6.23181 1.22419 6.00815 1.50073 6.00815H4.50513C4.78168 6.00815 5.00587 6.23181 5.00587 6.5077 5.00587 6.78359 4.78168 7.00725 4.50513 7.00725H1.50073C1.22419 7.00725 1 6.78359 1 6.5077ZM1 8.50589C1 8.23 1.22419 8.00634 1.50073 8.00634H4.50513C4.78168 8.00634 5.00587 8.23 5.00587 8.50589 5.00587 8.78178 4.78168 9.00544 4.50513 9.00544H1.50073C1.22419 9.00544 1 8.78178 1 8.50589ZM1.50073 10.0045C1.22419 10.0045 1 10.2282 1 10.5041 1 10.78 1.22419 11.0036 1.50073 11.0036H4.50513C4.78168 11.0036 5.00587 10.78 5.00587 10.5041 5.00587 10.2282 4.78168 10.0045 4.50513 10.0045H1.50073Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M6.48753 6.00815C6.21098 6.00815 5.9868 6.23181 5.9868 6.5077V14.5005C5.9868 14.7763 6.21098 15 6.48753 15H14.4993C14.7758 15 15 14.7763 15 14.5005V6.5077C15 6.23181 14.7758 6.00815 14.4993 6.00815H6.48753ZM13.9985 14.0009H6.98826V7.00725H13.9985V14.0009Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLLogotypeMenuVCol.propTypes = iconPropTypes;
