import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgChainSlash = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1.14645 1.14645C1.34171.951184 1.65829.951184 1.85355 1.14645L4.85355 4.14645C5.04882 4.34171 5.04882 4.65829 4.85355 4.85355 4.65829 5.04882 4.34171 5.04882 4.14645 4.85355L1.14645 1.85355C.951184 1.65829.951184 1.34171 1.14645 1.14645ZM12.1374 8.15572C11.9473 8.35598 11.9555 8.67245 12.1557 8.86259 12.356 9.05273 12.6725 9.04453 12.8626 8.84428L13.8061 7.8506C14.5425 7.12563 15 6.1158 15 5 15 2.79086 13.2091 1 11 1 9.93298 1 8.96263 1.41845 8.24584 2.0992L7.15517 3.13793C6.95521 3.32838 6.94749 3.64486 7.13793 3.84483 7.32838 4.04479 7.64486 4.05251 7.84483 3.86207L8.93448 2.8243C9.47284 2.31311 10.1995 2 11 2 12.6569 2 14 3.34315 14 5 14 5.83954 13.6558 6.59779 13.0995 7.14288L13.0931 7.14919 12.1374 8.15572ZM3.84428 7.13741C4.04453 7.32755 4.05273 7.64402 3.86259 7.84428L2.9069 8.85081 2.90046 8.85712C2.3442 9.40221 2 10.1605 2 11 2 12.6569 3.34315 14 5 14 5.80055 14 6.52694 13.6871 7.0653 13.1759L8.15517 12.1379C8.35514 11.9475 8.67162 11.9552 8.86207 12.1552 9.05251 12.3551 9.04479 12.6716 8.84483 12.8621L7.75467 13.9003C7.03788 14.5811 6.06702 15 5 15 2.79086 15 1 13.2091 1 11 1 9.8842 1.45751 8.87437 2.19392 8.1494L3.13741 7.15572C3.32755 6.95547 3.64402 6.94727 3.84428 7.13741ZM11.8536 11.1464C11.6583 10.9512 11.3417 10.9512 11.1464 11.1464 10.9512 11.3417 10.9512 11.6583 11.1464 11.8536L14.1464 14.8536C14.3417 15.0488 14.6583 15.0488 14.8536 14.8536 15.0488 14.6583 15.0488 14.3417 14.8536 14.1464L11.8536 11.1464Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgChainSlash.propTypes = iconPropTypes;
