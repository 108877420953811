import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgChevronRightCircle = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M7.21436 11.8501C7.02112 12.0474 6.70455 12.0507 6.50729 11.8574C6.31002 11.6642 6.30676 11.3476 6.5 11.1504L9.58582 8.00024L6.5 4.85013C6.30676 4.65287 6.31002 4.3363 6.50729 4.14306C6.70455 3.94983 7.02112 3.95309 7.21436 4.15035L10.6429 7.65035C10.8334 7.84475 10.8334 8.15574 10.6429 8.35013L7.21436 11.8501Z" />
          <path
            fillRule="evenodd"
            d="M8.00024 16.0002C3.58196 16.0002 0.00024169 12.4185 0.000241884 8.00024C0.000242077 3.58196 3.58196 0.000243598 8.00024 0.000243791C12.4185 0.000243984 16.0002 3.58196 16.0002 8.00025C16.0002 12.4185 12.4185 16.0002 8.00024 16.0002ZM8.00024 15.0002C4.13424 15.0002 1.00024 11.8662 1.00024 8.00024C1.00024 4.13424 4.13424 1.00024 8.00024 1.00024C11.8662 1.00024 15.0002 4.13424 15.0002 8.00025C15.0002 11.8662 11.8662 15.0002 8.00024 15.0002Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgChevronRightCircle.propTypes = iconPropTypes;
