import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgDocBanSign = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M5.00146 10C5.00146 8.34315 6.34461 7 8.00146 7C9.65832 7 11.0015 8.34315 11.0015 10C11.0015 11.6569 9.65832 13 8.00146 13C6.34461 13 5.00146 11.6569 5.00146 10ZM9.72406 8.98317C9.90031 9.28112 10.0015 9.62875 10.0015 10C10.0015 11.1046 9.10603 12 8.00146 12C7.63021 12 7.28258 11.8988 6.98464 11.7226L9.72406 8.98317ZM9.0166 8.27641L6.27788 11.0151C6.10224 10.7176 6.00146 10.3706 6.00146 10C6.00146 8.89543 6.8969 8 8.00146 8C8.37202 8 8.71904 8.10077 9.0166 8.27641Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3 0H9.2L14 4.57143V15C14 15.5523 13.5523 16 13 16H3C2.44772 16 2 15.5523 2 15V1C2 0.447715 2.44772 0 3 0ZM13 15H3L3 1H7.99561V4.5C7.99561 5.32843 8.66718 6 9.49561 6H13V15ZM13 5L8.99561 1.18629V4.5C8.99561 4.77614 9.21946 5 9.49561 5L13 5Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgDocBanSign.propTypes = iconPropTypes;
