import { Validator } from 'prop-types';

type Rest = [componentName: string, location: string, propFullName: string];

export const optionalPropTypeValidator =
  <T>(typeValidator: Validator<NonNullable<T>>): Validator<NonNullable<T> | undefined> =>
  (props: AnyObject, propName: string, ...rest: Rest) => {
    const propValue = props[propName];

    if (propValue === undefined) {
      return null;
    }

    if (propValue === null) {
      return new Error(
        `The property \`${propName}\` is marked as required, but its value is \`null\`.`,
      );
    }

    return typeValidator(props, propName, ...rest);
  };
