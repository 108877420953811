import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgUser = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.98257 1.00488C5.77446 1.00488 3.98362 2.79333 3.98362 5.00049C3.98362 6.38249 4.68572 7.60033 5.75274 8.31776C4.67363 8.64464 3.86148 9.2032 3.27217 9.8599C2.34392 10.8943 1.99854 12.1393 1.99854 13.0028V14.5009C1.99854 14.7771 2.22239 15.0009 2.49854 15.0009H13.4979C13.7741 15.0009 13.9979 14.7771 13.9979 14.5009V13.0028C13.9979 12.0621 13.6561 10.8128 12.7205 9.79334C12.1283 9.14802 11.3144 8.61248 10.2366 8.30136C11.29 7.58185 11.9815 6.37201 11.9815 5.00049C11.9815 2.79333 10.1907 1.00488 7.98257 1.00488ZM4.98362 5.00049C4.98362 3.34651 6.32584 2.00488 7.98257 2.00488C9.63929 2.00488 10.9815 3.34651 10.9815 5.00049C10.9815 6.65448 9.63929 7.99611 7.98257 7.99611C6.32584 7.99611 4.98362 6.65448 4.98362 5.00049ZM2.99854 13.0028C2.99854 12.3682 3.26423 11.366 4.01644 10.5278C4.75328 9.70667 5.98813 9.00846 7.99823 9.00846C10.0178 9.00846 11.2515 9.6716 11.9838 10.4695C12.7287 11.2811 12.9979 12.2789 12.9979 13.0028V14.0009H2.99854V13.0028Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgUser.propTypes = iconPropTypes;
