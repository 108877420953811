import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgChevronDoubleDown = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M14.7771 3.93918C15.0069 3.78238 15.0693 3.46426 14.9163 3.22862 14.7633 2.99299 14.453 2.92909 14.2231 3.08588L8.00005 7.33027 1.77705 3.08588C1.54716 2.92909 1.23677 2.99299 1.08379 3.22862.930812 3.46426.993163 3.78238 1.22306 3.93918L7.72306 8.3725C7.89084 8.48693 8.10926 8.48693 8.27705 8.3725L14.7771 3.93918ZM14.7771 9.48086C15.0069 9.32406 15.0693 9.00593 14.9163 8.7703 14.7633 8.53467 14.453 8.47076 14.2231 8.62756L8.00005 12.8719 1.77705 8.62756C1.54716 8.47076 1.23677 8.53467 1.08379 8.7703.930812 9.00593.993163 9.32406 1.22306 9.48086L7.72306 13.9142C7.89084 14.0286 8.10926 14.0286 8.27705 13.9142L14.7771 9.48086Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgChevronDoubleDown.propTypes = iconPropTypes;
