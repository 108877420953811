import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgFolder = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M0 3.50342C0 2.12271 1.11929 1.00342 2.5 1.00342H6.85286C7.38301 1.00342 7.87379 1.28327 8.14377 1.73952L8.71684 2.75463C8.80683 2.90672 8.97042 3 9.14714 3H13.5037C14.3321 3 15.0037 3.67157 15.0037 4.5V6.00531H16V12.5034C16 13.8841 14.8807 15.0034 13.5 15.0034H2.5C1.11929 15.0034 0 13.8841 0 12.5034V3.50342ZM14.0037 6.00531H4.52454C4.2484 6.00531 4.02454 6.22917 4.02454 6.50531C4.02454 6.78145 4.2484 7.00531 4.52454 7.00531H15V12.5034C15 13.3318 14.3284 14.0034 13.5 14.0034H2.5C1.67157 14.0034 1 13.3318 1 12.5034V3.50342C1 2.67499 1.67157 2.00342 2.5 2.00342H6.85286C7.02958 2.00342 7.19317 2.0967 7.28316 2.24878L7.85623 3.2639C8.12621 3.72015 8.61699 4 9.14714 4H13.5037C13.7798 4 14.0037 4.22386 14.0037 4.5V6.00531Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgFolder.propTypes = iconPropTypes;
