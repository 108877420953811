import { ComponentProps } from 'react';
import {
  colorAlertActive,
  colorAlertBackgroundInverse,
  colorAlertHover,
  colorAlertText,
  colorAlertTextInverse,
  colorBackgroundDisabled,
  colorBackgroundHoverInverse,
  colorIconDefaultInverse,
  colorIconDisabled,
  colorPrimaryHoverInverse,
  colorTextDefault,
  colorTextDefaultInverse,
} from '../../../../tokens/decision/colors.ts';
import { BaseColor, Color } from '../../../../tokens/quarks/colors.ts';
import { QuinaryButton } from '../QuinaryButton.tsx';

export interface IQuinaryButtonTokens {
  readonly backgroundColor: Color;
  readonly backgroundColorHover: Color;
  readonly backgroundColorDisabled: Color;
  readonly textColor: BaseColor;
  readonly textColorHover: BaseColor;
  readonly textColorDisabled: BaseColor;
  readonly iconColor: BaseColor;
  readonly iconColorHover: BaseColor;
  readonly iconColorDisabled: BaseColor;
}

const defaultTokens: IQuinaryButtonTokens = {
  backgroundColor: BaseColor.Transparent,
  backgroundColorDisabled: colorBackgroundDisabled,
  backgroundColorHover: colorPrimaryHoverInverse,
  iconColor: colorTextDefault,
  iconColorDisabled: colorIconDisabled,
  iconColorHover: colorTextDefault,
  textColor: colorTextDefault,
  textColorDisabled: colorIconDisabled,
  textColorHover: colorTextDefault,
};

const activatedDefaultTokens: IQuinaryButtonTokens = {
  ...defaultTokens,
  backgroundColor: colorPrimaryHoverInverse,
};

const inverseTokens: IQuinaryButtonTokens = {
  backgroundColor: BaseColor.Transparent,
  backgroundColorDisabled: BaseColor.Transparent,
  backgroundColorHover: colorBackgroundHoverInverse,
  iconColor: colorIconDefaultInverse,
  iconColorDisabled: colorIconDisabled,
  iconColorHover: colorIconDefaultInverse,
  textColor: colorTextDefaultInverse,
  textColorDisabled: colorIconDisabled,
  textColorHover: colorTextDefaultInverse,
};

const activatedInverseTokens: IQuinaryButtonTokens = {
  ...inverseTokens,
  backgroundColor: colorBackgroundHoverInverse,
};

const destructiveTokens: IQuinaryButtonTokens = {
  backgroundColor: BaseColor.Transparent,
  backgroundColorDisabled: colorBackgroundDisabled,
  backgroundColorHover: colorAlertHover,
  iconColor: colorAlertText,
  iconColorDisabled: colorIconDisabled,
  iconColorHover: colorTextDefaultInverse,
  textColor: colorAlertText,
  textColorDisabled: colorIconDisabled,
  textColorHover: colorTextDefaultInverse,
};

const activatedDestructiveTokens: IQuinaryButtonTokens = {
  ...destructiveTokens,
  backgroundColor: colorAlertActive,
  iconColor: colorTextDefaultInverse,
  textColor: colorTextDefaultInverse,
};

const destructiveInverseTokens: IQuinaryButtonTokens = {
  ...inverseTokens,
  backgroundColorHover: colorAlertActive,
  iconColor: colorAlertTextInverse,
  iconColorHover: colorTextDefaultInverse,
  textColor: colorAlertTextInverse,
  textColorHover: colorTextDefaultInverse,
};

const activatedDestructiveInverseTokens: IQuinaryButtonTokens = {
  ...destructiveInverseTokens,
  ...activatedDestructiveTokens,
};

const errorTokens: IQuinaryButtonTokens = {
  backgroundColor: colorAlertBackgroundInverse,
  backgroundColorDisabled: colorBackgroundDisabled,
  backgroundColorHover: colorAlertHover,
  iconColor: colorTextDefaultInverse,
  iconColorDisabled: colorIconDisabled,
  iconColorHover: colorTextDefaultInverse,
  textColor: colorTextDefaultInverse,
  textColorDisabled: colorIconDisabled,
  textColorHover: colorTextDefaultInverse,
};

const activatedErrorTokens: IQuinaryButtonTokens = {
  ...errorTokens,
  backgroundColor: colorAlertActive,
};

export const getQuinaryButtonTokens = (
  props: Pick<ComponentProps<typeof QuinaryButton>, 'activated' | 'buttonStyle'>,
): IQuinaryButtonTokens => {
  const { activated, buttonStyle } = props;

  switch (buttonStyle) {
    case 'inverse':
      return activated ? activatedInverseTokens : inverseTokens;

    case 'destructive':
      return activated ? activatedDestructiveTokens : destructiveTokens;

    case 'destructive-inverse':
      return activated ? activatedDestructiveInverseTokens : destructiveInverseTokens;

    case 'error-selected':
      return activated ? activatedErrorTokens : errorTokens;

    default:
      return activated ? activatedDefaultTokens : defaultTokens;
  }
};
