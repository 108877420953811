import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgIntegrationScheme = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M0.0160966 0.503906C0.0160966 0.227764 0.239722 0.00390625 0.515578 0.00390625H4.50909C4.78495 0.00390625 5.00857 0.227764 5.00857 0.503906V4.5C5.00857 4.77614 4.78495 5 4.50909 5H3.01201V7.99951H8.99701V5H7.50491C7.22905 5 7.00543 4.77614 7.00543 4.5V0.503906C7.00543 0.227764 7.22905 0.00390625 7.50491 0.00390625H11.5008C11.7766 0.00390625 12.0002 0.227764 12.0002 0.503906V4.5C12.0002 4.77614 11.7766 5 11.5008 5H9.99598V7.99951H15.4839C15.7598 7.99951 15.9834 8.22337 15.9834 8.49951C15.9834 8.77565 15.7598 8.99951 15.4839 8.99951H13.9987V10.9946L13.9986 11.0039H15.5005C15.7764 11.0039 16 11.2278 16 11.5039V15.5039C16 15.78 15.7764 16.0039 15.5005 16.0039H11.5047C11.2288 16.0039 11.0052 15.78 11.0052 15.5039V11.5039C11.0052 11.2278 11.2288 11.0039 11.5047 11.0039H12.9998L12.9997 10.9946V8.99951H9.51877C9.51138 8.99984 9.50396 9 9.49649 9C9.48903 9 9.4816 8.99984 9.47422 8.99951H7.0064V10.9946L7.00632 11.0039H8.5044C8.78026 11.0039 9.00389 11.2278 9.00389 11.5039V15.5039C9.00389 15.78 8.78026 16.0039 8.5044 16.0039H4.50899C4.23314 16.0039 4.00951 15.78 4.00951 15.5039V11.5039C4.00951 11.2278 4.23314 11.0039 4.50899 11.0039H6.00752L6.00744 10.9946V8.99951H2.5348C2.52742 8.99984 2.51999 9 2.51253 9C2.50507 9 2.49764 8.99984 2.49026 8.99951H0.499482C0.223626 8.99951 0 8.77565 0 8.49951C0 8.22337 0.223626 7.99951 0.499482 7.99951H2.01305V5H0.515578C0.239722 5 0.0160966 4.77614 0.0160966 4.5V0.503906ZM1.01506 1.00391V4H4.00961V1.00391H1.01506ZM8.00439 1.00391V4H11.0013V1.00391H8.00439ZM12.0041 12.0039V15.0039H15.001V12.0039H12.0041ZM5.00848 12.0039V15.0039H8.00492V12.0039H5.00848Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgIntegrationScheme.propTypes = iconPropTypes;
