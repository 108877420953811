import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLock = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 17"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M8.5 10C7.67157 10 7 10.6716 7 11.5V12.5C7 13.3284 7.67157 14 8.5 14C9.32843 14 10 13.3284 10 12.5V11.5C10 10.6716 9.32843 10 8.5 10ZM8 11.5C8 11.2239 8.22386 11 8.5 11C8.77614 11 9 11.2239 9 11.5V12.5C9 12.7761 8.77614 13 8.5 13C8.22386 13 8 12.7761 8 12.5V11.5Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3.99817 4.5C3.99817 2.01472 6.01289 0 8.49817 0C10.9834 0 12.9982 2.01472 12.9982 4.5V8.00195H15.5C15.7761 8.00195 16 8.22581 16 8.50195V13.502C16 14.8827 14.8807 16.002 13.5 16.002H3.5C2.11929 16.002 1 14.8827 1 13.502V8.50195C1 8.36934 1.05268 8.24217 1.14645 8.1484C1.24021 8.05463 1.36739 8.00195 1.5 8.00195H3.99817V4.5ZM11.9982 4.5V8.00195H4.99817V4.5C4.99817 2.567 6.56517 1 8.49817 1C10.4312 1 11.9982 2.567 11.9982 4.5ZM2 9.00195V13.502C2 14.3304 2.67157 15.002 3.5 15.002H13.5C14.3284 15.002 15 14.3304 15 13.502V9.00195H2Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLock.propTypes = iconPropTypes;
