import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgArrowRightRect = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M2.00052 3.83333C2.00052 2.85807 2.86907 2 4.00157 2H6.50287C6.77916 2 7.00313 1.77614 7.00313 1.5C7.00313 1.22386 6.77916 1 6.50287 1H4.00157C2.38263 1 1 2.24193 1 3.83333V13.1667C1 14.7581 2.38263 16 4.00157 16H6.50287C6.77916 16 7.00313 15.7761 7.00313 15.5C7.00313 15.2239 6.77916 15 6.50287 15H4.00157C2.86907 15 2.00052 14.1419 2.00052 13.1667V3.83333Z"
        />
        <path
          fill="currentColor"
          d="M11.8953 5.88241C12.0907 5.68715 12.4074 5.68715 12.6028 5.88241L14.8535 8.13194C15.0488 8.3272 15.0488 8.64378 14.8535 8.83904L12.6028 11.0886C12.4074 11.2838 12.0907 11.2838 11.8953 11.0886C11.6999 10.8933 11.6999 10.5767 11.8953 10.3815L13.292 8.98547H5.49698C5.22069 8.98547 4.99672 8.76162 4.99672 8.48547C4.99672 8.20933 5.22069 7.98547 5.49698 7.98547H13.292L11.8953 6.58952C11.6999 6.39425 11.6999 6.07767 11.8953 5.88241Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgArrowRightRect.propTypes = iconPropTypes;
