import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgWatch = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M2.97514 0.146424C3.17042 -0.048808 3.48703 -0.048808 3.68231 0.146424L5.80382 2.26742C5.9991 2.46265 5.9991 2.77918 5.80382 2.97441L2.97514 5.8024C2.77986 5.99763 2.46325 5.99763 2.26797 5.8024L0.14646 3.68141C-0.0488198 3.48618 -0.04882 3.16964 0.14646 2.97441L2.97514 0.146424ZM3.32872 1.20692L1.20721 3.32791L2.62155 4.7419L4.74306 2.62091L3.32872 1.20692Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M8.02301 5.94634C8.02301 5.67024 7.79913 5.44642 7.52296 5.44642C7.24679 5.44642 7.02292 5.67024 7.02292 5.94634V7.99147C7.02292 8.12406 7.0756 8.25121 7.16937 8.34496L8.53305 9.70839C8.72833 9.90362 9.04494 9.90363 9.24022 9.7084C9.43551 9.51318 9.43552 9.19664 9.24024 9.00141L8.02301 7.78441V5.94634Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.91067 3.40377C5.42517 3.40377 3.41027 5.41817 3.41027 7.90307 3.41027 10.388 5.42517 12.4024 7.91067 12.4024 10.3962 12.4024 12.4111 10.388 12.4111 7.90307 12.4111 5.41817 10.3962 3.40377 7.91067 3.40377ZM4.41036 7.90307C4.41036 5.97037 5.97751 4.40361 7.91067 4.40361 9.84384 4.40361 11.411 5.97037 11.411 7.90307 11.411 9.83577 9.84384 11.4025 7.91067 11.4025 5.97751 11.4025 4.41036 9.83577 4.41036 7.90307ZM13.732 10.1976C13.5368 10.0024 13.2201 10.0024 13.0249 10.1976L10.1962 13.0256C10.0009 13.2208 10.0009 13.5374 10.1962 13.7326L12.3177 15.8536C12.513 16.0488 12.8296 16.0488 13.0249 15.8536L15.8535 13.0256C16.0488 12.8304 16.0488 12.5138 15.8535 12.3186L13.732 10.1976ZM11.2569 13.3791 13.3784 11.2581 14.7928 12.6721 12.6713 14.7931 11.2569 13.3791Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgWatch.propTypes = iconPropTypes;
