import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgUsers = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M14.0061 3.9909C14.0061 4.97073 13.5327 5.84052 12.8011 6.38604C13.2812 6.60895 13.6876 6.92383 14.0159 7.30991C14.689 8.10142 15 9.15078 15 10.2177L15 12.4934C15 12.6259 14.9472 12.753 14.8533 12.8467C14.7594 12.9404 14.632 12.993 14.4991 12.993H7.4872C7.21059 12.993 6.98635 12.7693 6.98634 12.4934L6.98631 10.0226C6.98631 9.04211 7.30365 8.04142 7.97621 7.27873C8.30475 6.90616 8.71126 6.59833 9.19203 6.37944C8.46545 5.83352 7.99584 4.96679 7.99584 3.9909C7.99584 2.33907 9.34129 1 11.001 1C12.6607 1 14.0061 2.33907 14.0061 3.9909ZM11.001 5.98248C12.1097 5.98248 13.0044 5.0886 13.0044 3.9909C13.0044 2.8932 12.1097 1.99932 11.001 1.99932C9.89229 1.99932 8.99755 2.8932 8.99755 3.9909C8.99755 5.0886 9.89229 5.98248 11.001 5.98248ZM7.98802 10.0226L7.98805 11.9937H13.9983L13.9983 10.2177C13.9983 9.33398 13.74 8.53027 13.252 7.95638C12.7737 7.39395 12.0411 7.01122 10.9931 7.01122C9.9443 7.01122 9.20801 7.39481 8.72831 7.93879C8.23982 8.49274 7.98802 9.2476 7.98802 10.0226Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M4.90492 4.00772C3.86747 4.00772 2.99413 4.88494 2.99413 6.00758C2.99413 7.09278 3.81019 7.94866 4.8017 8.00454C4.85567 8.00274 4.91022 8.00183 4.96533 8.00183C5.24194 8.00183 5.46618 8.22553 5.46618 8.50149C5.46618 8.77186 5.25092 8.99208 4.98204 9.00088C4.95691 9.00475 4.93115 9.00676 4.90492 9.00676C4.86876 9.00676 4.83277 9.00609 4.79696 9.00476C3.83536 9.04646 3.16522 9.44567 2.72118 9.99409C2.24001 10.5884 2.00171 11.3885 2.00171 12.1697V14.0007H7.51113C7.78774 14.0007 8.01198 14.2244 8.01198 14.5003C8.01198 14.7763 7.78774 15 7.51113 15H1.50086C1.22424 15 1 14.7763 1 14.5003V12.1697C1 11.203 1.29189 10.169 1.94192 9.36616C2.26242 8.97032 2.66462 8.63786 3.14779 8.40025C2.4429 7.84958 1.99242 6.97715 1.99242 6.00758C1.99242 4.36933 3.27854 3.0084 4.90492 3.0084C5.64898 3.0084 6.32648 3.29617 6.83862 3.76467C7.0425 3.95117 7.05622 4.26724 6.86928 4.47063C6.68233 4.67402 6.3655 4.68771 6.16162 4.50121C5.8235 4.1919 5.38411 4.00772 4.90492 4.00772Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgUsers.propTypes = iconPropTypes;
