import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgBraces = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M4.5 15C3.60801 15 2.96014 14.6549 2.54661 14.1364 2.1478 13.6364 2 13.0181 2 12.5V10.5C2 9.89611 1.96098 9.55972 1.85359 9.28475 1.74876 9.01633 1.55913 8.75753 1.15026 8.35732 1.05467 8.26376 1.00056 8.13581 1 8.00206.999453 7.86831 1.05251 7.73992 1.14732 7.64558 1.55377 7.24112 1.7447 6.98125 1.85099 6.71151 1.95919 6.43692 2 6.10153 2 5.5V3.5C2 2.98187 2.1478 2.3636 2.54661 1.8636 2.96014 1.34514 3.60801 1 4.5 1 4.77614 1 5 1.22386 5 1.5 5 1.77614 4.77614 2 4.5 2 3.89199 2 3.53986 2.22203 3.32839 2.48716 3.1022 2.77074 3 3.15248 3 3.5V5.5C3 6.12363 2.96238 6.61874 2.78137 7.07811 2.64996 7.4116 2.45345 7.70036 2.19129 7.99766 2.45654 8.29525 2.65395 8.58522 2.78507 8.92097 2.9648 9.38116 3 9.87803 3 10.5V12.5C3 12.8475 3.1022 13.2293 3.32839 13.5128 3.53986 13.778 3.89199 14 4.5 14 4.77614 14 5 14.2239 5 14.5 5 14.7761 4.77614 15 4.5 15ZM11.5 1C12.392 1 13.0399 1.34514 13.4534 1.8636 13.8522 2.3636 14 2.98187 14 3.5V5.5C14 6.10389 14.039 6.44028 14.1464 6.71525 14.2512 6.98367 14.4409 7.24247 14.8497 7.64268 14.9453 7.73624 14.9994 7.86419 15 7.99794 15.0005 8.13169 14.9475 8.26008 14.8527 8.35442 14.4462 8.75888 14.2553 9.01875 14.149 9.28849 14.0408 9.56308 14 9.89847 14 10.5V12.5C14 13.0181 13.8522 13.6364 13.4534 14.1364 13.0399 14.6549 12.392 15 11.5 15 11.2239 15 11 14.7761 11 14.5 11 14.2239 11.2239 14 11.5 14 12.108 14 12.4601 13.778 12.6716 13.5128 12.8978 13.2293 13 12.8475 13 12.5V10.5C13 9.87637 13.0376 9.38126 13.2186 8.92189 13.35 8.5884 13.5466 8.29964 13.8087 8.00234 13.5435 7.70475 13.3461 7.41478 13.2149 7.07903 13.0352 6.61884 13 6.12197 13 5.5V3.5C13 3.15248 12.8978 2.77074 12.6716 2.48716 12.4601 2.22203 12.108 2 11.5 2 11.2239 2 11 1.77614 11 1.5 11 1.22386 11.2239 1 11.5 1Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgBraces.propTypes = iconPropTypes;
