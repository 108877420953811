import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgMenu = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1 3.5C1 3.22386 1.22389 3 1.50008 3H14.4999C14.7761 3 15 3.22386 15 3.5 15 3.77614 14.7761 4 14.4999 4H1.50008C1.22389 4 1 3.77614 1 3.5ZM1 7.5C1 7.22386 1.22389 7 1.50008 7H14.4999C14.7761 7 15 7.22386 15 7.5 15 7.77614 14.7761 8 14.4999 8H1.50008C1.22389 8 1 7.77614 1 7.5ZM1.50008 11C1.22389 11 1 11.2239 1 11.5 1 11.7761 1.22389 12 1.50008 12H14.4999C14.7761 12 15 11.7761 15 11.5 15 11.2239 14.7761 11 14.4999 11H1.50008Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgMenu.propTypes = iconPropTypes;
