import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgRibbon = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M6.00106 9.99999C6.00132 9.99999 6.00081 9.99999 6.00106 9.99999L2.49984 9.99999C1.6715 9.99999 1 9.32842 1 8.49999V4.50008C1 3.67166 1.67149 3.00008 2.49982 3.00007L7.95988 3C8.81108 2.99999 9.27302 3.99587 8.7233 4.64582L7.57716 6.00094L14.5 6.00094C14.6933 6.00094 14.8693 6.11236 14.952 6.28708C15.0346 6.46181 15.0092 6.66854 14.8866 6.818L12.2811 9.99541L14.8869 13.1836C15.0091 13.3331 15.0343 13.5397 14.9515 13.7142C14.8688 13.8887 14.6929 14 14.4998 14H6.50089C6.36829 14 6.24112 13.9473 6.14736 13.8535C6.05361 13.7598 6.00093 13.6326 6.00094 13.5L6.00106 9.99999ZM2.49984 4.00007L7.95989 4L6.23679 6.03728C6.21244 6.06607 6.18984 6.09609 6.16905 6.12717C6.06607 6.21875 6.00119 6.35226 6.00119 6.50092L6.00118 6.64112C6.0006 6.65508 6.0003 6.66908 6.0003 6.6831V8.99999H2.49984C2.22373 8.99999 1.99989 8.77614 1.99989 8.49999V4.50008C1.99989 4.22393 2.22373 4.00008 2.49984 4.00007ZM13.4454 13H7.00085L7.00107 7.00094L13.4435 7.00094L11.2484 9.67782C11.0974 9.86193 11.0972 10.127 11.2479 10.3113L13.4454 13Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgRibbon.propTypes = iconPropTypes;
