import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgChoiceMultiScheme = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 17"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M8.87142 1.49675C8.87142 1.22078 9.09938 0.99707 9.38058 0.99707H15.4907C15.7719 0.99707 15.9998 1.22078 15.9998 1.49675V7.49291C15.9998 7.76887 15.7719 7.99259 15.4907 7.99259H9.38058C9.09938 7.99259 8.87142 7.76887 8.87142 7.49291V5.00329H3.77441V5.7401L6.29412 8.85689C6.44117 9.03878 6.44117 9.29612 6.29412 9.47802L3.77441 12.5948V12.9897H8.87043V10.5012C8.87043 10.2253 9.09839 10.0016 9.37959 10.0016H15.4908C15.772 10.0016 16 10.2253 16 10.5012V16.4974C16 16.7734 15.772 16.9971 15.4908 16.9971H9.37959C9.09839 16.9971 8.87043 16.7734 8.87043 16.4974V13.989H3.26525C2.98405 13.989 2.7561 13.7653 2.7561 13.4894V12.7508L0.110288 9.47802C-0.0367628 9.29612 -0.0367628 9.03878 0.110288 8.85689L2.7561 5.58412V4.50361C2.7561 4.22765 2.98405 4.00393 3.26525 4.00393H8.87142V1.49675ZM3.2096 11.6856C3.20509 11.686 3.20059 11.6866 3.19612 11.6872L1.15909 9.16745L3.2022 6.6402L5.24532 9.16745L3.2096 11.6856ZM9.88973 6.99323V1.99643H14.9815V6.99323H9.88973ZM9.88874 11.0009V15.9977H14.9817V11.0009H9.88874Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgChoiceMultiScheme.propTypes = iconPropTypes;
