import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgModalMinimize = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M13.0008 2H14.9996L15 1.63086C15 1.46355 14.9472 1.30308 14.8531 1.18477 14.7591 1.06647 14.6316 1 14.4986 1L13.0012 1 13.0008 2ZM14.9941 14.4961C14.9941 14.7722 14.7704 14.9961 14.4944 14.9961H1.49979C1.22376 14.9961 1 14.7722 1 14.4961 1 14.22 1.22376 13.9961 1.49979 13.9961H14.4944C14.7704 13.9961 14.9941 14.22 14.9941 14.4961ZM1.00039 11.9961H2.99916L2.99878 12.9961H1.50138C1.3684 12.9961 1.24088 12.9296 1.14685 12.8113 1.05282 12.693 1 12.5326 1 12.3652L1.00039 11.9961ZM3.00502 1.99707H1.00624L1.00586 1.62793C1.00586 1.46062 1.05868 1.30015 1.15271 1.18184 1.24673 1.06354 1.37426.997071 1.50724.997071L3.00463.997071 3.00502 1.99707ZM14.9938 11.9961 12.995 11.9961 12.9954 12.9961H14.4928C14.6257 12.9961 14.7533 12.9296 14.8473 12.8113 14.9413 12.693 14.9941 12.5326 14.9941 12.3652L14.9938 11.9961ZM15 3 15 5H14.0004V3H15ZM2.00544 5.00391 2.00544 3.00391 1.00586 3.00391 1.00586 5.00391H2.00544ZM15 5.99609V7.99609H14.0004V5.99609H15ZM15 11.004V9.00403H14.0004V11.004L15 11.004ZM2.00544 5.99609 2.00544 7.99609H1.00586L1.00586 5.99609H2.00544ZM2.00544 10.9766 2.00544 8.97656H1.00586L1.00586 10.9766H2.00544ZM12.0013 2 10.0021 2V1L12.0013 1V2ZM10.0021 12.9961H12.0013V11.9961H10.0021V12.9961ZM9.00251 2 7.00335 2V1L9.00251 1V2ZM7.00335 12.9961H9.00251V11.9961H7.00335V12.9961ZM6.00376 2 4.0046 2V1L6.00376 1V2ZM4.0046 12.9961H6.00377V11.9961H4.0046V12.9961Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgModalMinimize.propTypes = iconPropTypes;
