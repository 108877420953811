import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgSchemeCirclesTriangle = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M12.5031 14.9951C11.2945 14.9951 10.2861 14.1396 10.0524 13.0022L5.95833 13.0022C5.72653 14.1421 4.71695 15 3.50663 15C2.12495 15 1.00488 13.882 1.00488 12.5028C1.00488 11.145 2.09053 10.0403 3.44264 10.0064L6.27237 5.2987C5.79825 4.84426 5.50314 4.20514 5.50314 3.49721C5.50314 2.11804 6.62321 1 8.00488 1C9.38656 1 10.5066 2.11804 10.5066 3.49721C10.5066 4.20822 10.2089 4.84983 9.73119 5.30463L12.5543 10.0012C13.9123 10.0284 15.0049 11.1358 15.0049 12.4979C15.0049 13.8771 13.8848 14.9951 12.5031 14.9951ZM12.5031 13.9962C11.6958 13.9962 11.0373 13.36 11.0035 12.5624C11.0058 12.5428 11.007 12.5229 11.007 12.5028C11.007 12.4818 11.0057 12.4611 11.0032 12.4408C11.0332 11.6398 11.6933 10.9996 12.5031 10.9996C13.3321 10.9996 14.0042 11.6704 14.0042 12.4979C14.0042 13.3254 13.3321 13.9962 12.5031 13.9962ZM8.88361 5.83604L11.5099 10.2052C10.7687 10.5256 10.2134 11.1937 10.0505 12.0033L5.95833 12.0033C5.79338 11.1922 5.23454 10.5238 4.48977 10.2058L7.11819 5.83304C7.39379 5.93733 7.69266 5.99443 8.00488 5.99443C8.3141 5.99443 8.61022 5.93843 8.88361 5.83604ZM5.00279 12.5028C5.00279 12.4822 5.00404 12.4619 5.00646 12.442C4.97449 11.6427 4.31523 11.0045 3.50663 11.0045C2.67762 11.0045 2.00558 11.6753 2.00558 12.5028C2.00558 13.3303 2.67762 14.0011 3.50663 14.0011C4.31523 14.0011 4.97449 13.3629 5.00646 12.5636C5.00404 12.5437 5.00279 12.5234 5.00279 12.5028ZM8.00488 4.99554C7.17588 4.99554 6.50384 4.32472 6.50384 3.49721C6.50384 2.66971 7.17588 1.99889 8.00488 1.99889C8.83389 1.99889 9.50593 2.66971 9.50593 3.49721C9.50593 4.32472 8.83389 4.99554 8.00488 4.99554Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" transform="rotate(90 8 8)" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgSchemeCirclesTriangle.propTypes = iconPropTypes;
