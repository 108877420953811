import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgXmlTag = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M10.4779 1.64718C10.5591 1.38324 10.411 1.10345 10.147 1.02224 9.88311.941034 9.60332 1.08916 9.52211 1.35309L5.52211 14.3531C5.4409 14.617 5.58903 14.8968 5.85296 14.978 6.11689 15.0592 6.39668 14.9111 6.47789 14.6472L10.4779 1.64718ZM3.73943 2.06141C3.98185 2.19365 4.07118 2.49736 3.93895 2.73979L1.06954 8.00036 3.93895 13.2609C4.07118 13.5034 3.98185 13.8071 3.73943 13.9393 3.497 14.0715 3.19328 13.9822 3.06105 13.7398L.0610523 8.23979C-.0203508 8.09055-.0203508 7.91017.0610523 7.76094L3.06105 2.26094C3.19328 2.01851 3.497 1.92918 3.73943 2.06141ZM12.2606 2.06146C12.503 1.92923 12.8067 2.01856 12.9389 2.26098L15.9389 7.76098C16.0204 7.91022 16.0204 8.0906 15.9389 8.23983L12.9389 13.7398C12.8067 13.9823 12.503 14.0716 12.2606 13.9394 12.0181 13.8071 11.9288 13.5034 12.0611 13.261L14.9305 8.00041 12.0611 2.73983C11.9288 2.49741 12.0182 2.19369 12.2606 2.06146Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgXmlTag.propTypes = iconPropTypes;
