import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgArrowRightTopSquare = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3.5 2C2.67157 2 2 2.67157 2 3.5V12.5C2 13.3284 2.67157 14 3.5 14H12.5C13.3284 14 14 13.3284 14 12.5V8.51043C14 8.23428 14.2239 8.01043 14.5 8.01043C14.7761 8.01043 15 8.23428 15 8.51043V12.5C15 13.8807 13.8807 15 12.5 15H3.5C2.11929 15 1 13.8807 1 12.5V3.5C1 2.11929 2.11929 1 3.5 1H7.50285C7.77899 1 8.00285 1.22386 8.00285 1.5C8.00285 1.77614 7.77899 2 7.50285 2H3.5ZM9.99934 1.5C9.99934 1.22386 10.2232 1 10.4993 1H14.5C14.6326 1 14.7598 1.05268 14.8536 1.14645C14.9473 1.24021 15 1.36739 15 1.5V5.49767C15 5.77382 14.7761 5.99767 14.5 5.99767C14.2239 5.99767 14 5.77382 14 5.49767V2.70711L8.94446 7.76264C8.7492 7.95791 8.43262 7.95791 8.23736 7.76264C8.04209 7.56738 8.04209 7.2508 8.23736 7.05554L13.2929 2H10.4993C10.2232 2 9.99934 1.77614 9.99934 1.5Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgArrowRightTopSquare.propTypes = iconPropTypes;
