import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgHatMoustache = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.53846 2.00244C4.30469 2.00244 4.10211 2.16443 4.0507 2.39248L3.37575 5.38648L2.98975 7.00652H1.85597L0.847463 6.0319C0.648894 5.84 0.332358 5.84541 0.140459 6.04398C-0.0514389 6.24255 -0.0460311 6.55908 0.152538 6.75098L1.30638 7.86607C1.39961 7.95616 1.5242 8.00652 1.65385 8.00652H3.3744C3.38151 8.00668 3.3886 8.00668 3.39566 8.00652H14.3462C14.4758 8.00652 14.6004 7.95616 14.6936 7.86607L15.8475 6.75098C16.046 6.55908 16.0514 6.24255 15.8595 6.04398C15.6676 5.84541 15.3511 5.84 15.1525 6.0319L14.144 7.00652H13.0097L12.6214 5.38641L11.9494 2.39292C11.8981 2.16466 11.6955 2.00244 11.4615 2.00244H4.53846ZM4.01774 7.00652L4.2577 5.99941H11.74L11.9814 7.00652H4.01774ZM4.4881 4.99941H11.5097L11.0613 3.00244H4.93829L4.4881 4.99941Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M3.99463 11.5005C3.99463 11.2243 3.77077 11.0005 3.49463 11.0005C3.21849 11.0005 2.99463 11.2243 2.99463 11.5005C2.99463 12.209 3.72885 13.0005 5.18162 13.0005C5.57488 13.0005 5.88164 12.9226 6.14605 12.7936C6.36067 12.6889 6.54035 12.5513 6.67907 12.4451C6.69993 12.4291 6.71986 12.4139 6.73884 12.3995C6.89368 12.2826 7.02743 12.1913 7.20381 12.123C7.37923 12.0551 7.62268 12.0005 7.99322 12.0005C8.36376 12.0005 8.60757 12.0551 8.78299 12.123C8.95937 12.1913 9.09312 12.2826 9.24796 12.3995C9.26694 12.4139 9.28687 12.4291 9.30773 12.4451C9.44646 12.5513 9.62613 12.6889 9.84075 12.7936C10.1052 12.9226 10.4119 13.0005 10.8052 13.0005C12.258 13.0005 12.9922 12.209 12.9922 11.5005C12.9922 11.2243 12.7683 11.0005 12.4922 11.0005C12.216 11.0005 11.9922 11.2243 11.9922 11.5005C11.9922 11.4961 11.9915 11.4981 11.989 11.5051C11.9673 11.5653 11.8106 12.0005 10.8052 12.0005C10.5548 12.0005 10.3991 11.9534 10.2793 11.8949C10.1606 11.837 10.0657 11.7648 9.92218 11.6558L9.85053 11.6015C9.67425 11.4684 9.45192 11.3097 9.14411 11.1905C8.83535 11.0709 8.46616 11.0005 7.99322 11.0005C7.52028 11.0005 7.15145 11.0709 6.84269 11.1905C6.53488 11.3097 6.31255 11.4684 6.13627 11.6015L6.06462 11.6558C5.92114 11.7648 5.82624 11.837 5.70753 11.8949C5.58767 11.9534 5.43203 12.0005 5.18162 12.0005C4.1762 12.0005 4.01953 11.5653 3.99784 11.5051C3.99533 11.4981 3.99463 11.4961 3.99463 11.5005Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgHatMoustache.propTypes = iconPropTypes;
