import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgBrandSharepoint = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M4.5088 6C4.10613 6 3.73581 6.18409 3.47177 6.44298C3.2063 6.70326 3.01038 7.07649 3.01038 7.5C3.01038 7.99516 3.25744 8.3216 3.54295 8.53588C3.67531 8.63522 3.81719 8.71235 3.93928 8.77429C4.00074 8.80547 4.05093 8.82993 4.09711 8.85244C4.15766 8.88195 4.21138 8.90813 4.27459 8.9417C4.36235 8.98831 4.43918 9.02663 4.50806 9.06098C4.65452 9.13402 4.76511 9.18917 4.86854 9.26809C4.98193 9.3546 5.00804 9.41389 5.00804 9.5C5.00804 9.60939 4.95524 9.73619 4.8467 9.84265C4.73675 9.95052 4.60768 10 4.5088 10H3.50975C3.23394 10 3.01034 10.2239 3.01034 10.5C3.01034 10.7761 3.23394 11 3.50975 11H4.5088C4.9115 11 5.28177 10.8159 5.54574 10.5569C5.81113 10.2966 6.00687 9.9234 6.00687 9.5C6.00687 9.00936 5.75118 8.68424 5.47397 8.47273C5.28907 8.33165 5.04804 8.2116 4.87408 8.12496C4.82364 8.09983 4.77882 8.07751 4.74266 8.0583C4.67204 8.0208 4.58383 7.97752 4.50663 7.93965C4.46267 7.91808 4.4223 7.89827 4.39077 7.88228C4.2839 7.82806 4.20385 7.78211 4.14207 7.73574C4.0393 7.65861 4.00921 7.60309 4.00921 7.5C4.00921 7.39073 4.06199 7.26395 4.17063 7.15743C4.2807 7.04952 4.40988 7 4.5088 7H5.50745C5.78327 7 6.00687 6.77615 6.00687 6.5C6.00687 6.22386 5.78327 6 5.50745 6H4.5088Z" />
          <path
            fillRule="evenodd"
            d="M7.99181 0.500003C7.99181 0.33527 7.91076 0.181108 7.77513 0.0878469C7.6395 -0.0054142 7.46668 -0.0258057 7.31311 0.0333299L1.32014 2.34102C1.12727 2.41529 1 2.60081 1 2.80769V13.1923C1 13.3992 1.12727 13.5847 1.32014 13.659L7.31311 15.9667C7.46668 16.0258 7.6395 16.0054 7.77513 15.9122C7.91076 15.8189 7.99181 15.6647 7.99181 15.5V14.9998L8.00449 15C9.18705 15 10.2255 14.1004 10.4546 12.9672C12.0535 12.6843 13.3912 11.6453 14.0868 10.2336C15.1695 10.072 16 9.13724 16 8.0083C16 6.87936 15.1695 5.94461 14.0868 5.78301C13.3924 4.37386 12.0584 3.3361 10.4634 3.05099C10.2533 1.89631 9.20292 0.972415 8.00449 0.972415L7.99181 0.972573V0.500003ZM8.00449 14L7.99181 14.0002V10.9998L8.00449 11C8.79409 11 9.50273 11.7095 9.50273 12.5C9.50273 13.2905 8.79409 14 8.00449 14ZM8.00449 10L7.99181 10.0002V5.97226L8.00449 5.97241C9.14382 5.97241 10.1494 5.1374 10.4263 4.06291C11.5122 4.29707 12.436 4.96925 13.0033 5.88641C12.1305 6.19536 11.5053 7.02873 11.5053 8.0083C11.5053 8.98789 12.1305 9.82126 13.0033 10.1302C12.4378 11.0444 11.5181 11.7153 10.4367 11.9515C10.1754 10.8568 9.1586 10 8.00449 10ZM7.99181 1.97226V4.97257L8.00449 4.97241C8.79409 4.97241 9.50273 4.26294 9.50273 3.47241C9.50273 2.68189 8.79409 1.97241 8.00449 1.97241L7.99181 1.97226ZM1.99883 12.8489V3.15109L6.99298 1.22802V14.772L1.99883 12.8489ZM12.5041 8.0083C12.5041 7.31795 13.0631 6.7583 13.7526 6.7583C14.4422 6.7583 15.0012 7.31795 15.0012 8.0083C15.0012 8.69866 14.4422 9.2583 13.7526 9.2583C13.0631 9.2583 12.5041 8.69866 12.5041 8.0083Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgBrandSharepoint.propTypes = iconPropTypes;
