import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgArrowURight = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M15 10.311C15 10.3623 14.9919 10.4118 14.9769 10.4582C14.9537 10.5304 14.913 10.5983 14.8548 10.6556L11.6048 13.8556C11.4102 14.0472 11.0936 14.0483 10.8977 13.858C10.7017 13.6678 10.7006 13.3582 10.8952 13.1666L13.2989 10.7999L6.31818 10.7999C3.94765 10.7999 2 8.77144 2 6.39996C2 4.02848 3.94765 2 6.31818 2L14.5 2C14.7761 2 15 2.21888 15 2.48888C15 2.75889 14.7761 2.97777 14.5 2.97777L6.31818 2.97777C4.54326 2.97777 3 4.52513 3 6.39996C3 8.2748 4.54326 9.82216 6.31818 9.82216L13.2988 9.82216L10.8952 7.45556C10.7006 7.26397 10.7017 6.95443 10.8977 6.76417C11.0936 6.57392 11.4102 6.575 11.6048 6.76658L14.8548 9.96656C14.9023 10.0134 14.9382 10.0672 14.9624 10.1247C14.9866 10.1821 15 10.2451 15 10.311Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgArrowURight.propTypes = iconPropTypes;
