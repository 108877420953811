import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgDesktop = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.65385 1.99902C1.19781 1.99902 0.777297 2.14895 0.468258 2.44999C0.158084 2.75214 0 3.16798 0 3.62256V10.3665C0 10.8211 0.158084 11.2369 0.468258 11.539C0.777297 11.8401 1.19781 11.99 1.65385 11.99H5.84338C5.8403 12.0817 5.82717 12.1906 5.7953 12.3135C5.68695 12.731 5.34147 13.384 4.25892 14.0793C4.07228 14.1991 3.98704 14.4276 4.04956 14.6402C4.11208 14.8529 4.30742 14.999 4.5293 14.999H11.5293C11.7512 14.999 11.9465 14.8529 12.009 14.6402C12.0716 14.4276 11.9863 14.1991 11.7997 14.0793C10.7171 13.384 10.3716 12.731 10.2633 12.3135C10.2314 12.1906 10.2183 12.0817 10.2152 11.99H14.3462C14.8022 11.99 15.2227 11.8401 15.5317 11.539C15.8419 11.2369 16 10.8211 16 10.3665V3.62256C16 3.16798 15.8419 2.75214 15.5317 2.44999C15.2227 2.14895 14.8022 1.99902 14.3462 1.99902H1.65385ZM1 3.62256C1 3.40275 1.07268 3.2566 1.16636 3.16535C1.26116 3.07299 1.41757 2.99812 1.65385 2.99812H14.3462C14.5824 2.99812 14.7388 3.07299 14.8336 3.16535C14.9273 3.2566 15 3.40275 15 3.62256V10.3665C15 10.5863 14.9273 10.7324 14.8336 10.8237C14.7388 10.916 14.5824 10.9909 14.3462 10.9909H1.65385C1.41757 10.9909 1.26116 10.916 1.16636 10.8237C1.07268 10.7324 1 10.5863 1 10.3665V3.62256ZM6.7633 12.5642C6.81725 12.3563 6.83946 12.1668 6.84344 12.0017H9.21516C9.21914 12.1668 9.24135 12.3563 9.2953 12.5642C9.40929 13.0035 9.65715 13.4966 10.1291 13.9999H5.92954C6.40145 13.4966 6.64931 13.0035 6.7633 12.5642Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgDesktop.propTypes = iconPropTypes;
