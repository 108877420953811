import { UnreachableCaseException } from '@kontent-ai/errors';
import { css } from 'styled-components';
import { borderWidthHighEmphasis } from '../../../tokens/decision/border.ts';
import {
  colorAlertBackgroundInverse,
  colorAlertHover,
  colorAlertIcon,
  colorBackgroundDisabled,
  colorBackgroundHoverInverse,
  colorBorderAlert,
  colorBorderDisabled,
  colorIconDisabled,
  colorPrimary,
  colorPrimaryHover,
  colorPrimaryHoverInverse,
  colorTextDefault,
} from '../../../tokens/decision/colors.ts';
import { Size } from '../../../tokens/decision/spacing.ts';
import { BaseColor } from '../../../tokens/quarks/colors.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { Typography } from '../../../tokens/quarks/typography.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { ButtonSize } from '../buttonSize.ts';
import { ButtonStyle } from '../buttonStyle.ts';
import { colorTextOnDark } from '../decisionTokens.ts';

// buttons with border and transparent bg should have focus ring around button border
// and focus inner shadow should be visible under border - so it needs to be handled by wrapper around button
export const shouldWrapperHandleFocus = (buttonStyle: ButtonStyle): boolean => {
  switch (buttonStyle) {
    case 'primary':
    case 'primary-inverse':
      return false;
    case 'secondary':
    case 'secondary-inverse':
    case 'tertiary':
      return true;
    default:
      throw UnreachableCaseException(buttonStyle);
  }
};

interface IActivableButton {
  readonly $activated?: boolean;
}

// Utils / StylingUtils
export const getTypographyStyles = (buttonSize: ButtonSize) => {
  switch (buttonSize) {
    case 'small':
      return Typography.ActionSmall;
    case 'medium':
      return Typography.ActionMedium;
    case 'large':
      return Typography.ActionLarge;
    default:
      throw UnreachableCaseException(buttonSize);
  }
};

const getButtonSizeStyles = (
  buttonSize: Size,
  horizontalPadding: Spacing,
  borderWidth: number,
) => css`
  border-width: ${px(borderWidth)};
  border-style: solid;
  height: ${px(buttonSize)};
  padding: 0 ${px(horizontalPadding - borderWidth)};
`;

export const getSizeButtonStyles = (buttonStyle: ButtonStyle, size: ButtonSize) => {
  const borderWidth = getBorderWidth(buttonStyle);

  switch (size) {
    case 'small':
      return getButtonSizeStyles(Size.S, Spacing.L, borderWidth);
    case 'medium':
      return getButtonSizeStyles(Size.M, Spacing.XL, borderWidth);
    case 'large':
      return getButtonSizeStyles(Size.L, Spacing.XXL, borderWidth);
    default:
      throw UnreachableCaseException(size);
  }
};

const getButtonDisabledStyles = (bgColorDisabled: string, borderColorDisabled: string) => css`
  &:disabled {
    background: ${bgColorDisabled};
    border-color: ${borderColorDisabled};
    box-shadow: none;
  }
`;

const getPrimaryButtonStyles = (
  bgColor: string,
  bgColorHover: string,
  bgColorDisabled: string,
  disabled?: boolean,
) =>
  disabled
    ? getButtonDisabledStyles(bgColorDisabled, BaseColor.Transparent)
    : css<IActivableButton>`
    background: ${bgColor};

    &:active,
    &:hover {
      background: ${bgColorHover};
    }

    ${({ $activated }) =>
      $activated &&
      css`
      background: ${bgColorHover};
    `}
  }`;

const getSecondaryButtonStyles = (
  bgColor: string,
  bgColorHover: string,
  bgColorDisabled: string,
  borderColor: string,
  borderColorDisabled: string,
  borderColorHover: string,
  disabled?: boolean,
) =>
  disabled
    ? getButtonDisabledStyles(bgColorDisabled, borderColorDisabled)
    : css<IActivableButton>`
    background: ${bgColor};
    border-color: ${borderColor};

    &:active,
    &:hover {
      background: ${bgColorHover};
      background-clip: padding-box;
      border-color: ${borderColorHover};
    }

    ${({ $activated }) =>
      $activated &&
      css`
      background: ${bgColorHover};
      background-clip: padding-box;
      border-color: ${borderColorHover};
    `}
  `;

const getSpecificDestructiveButtonDisabledStyles = (buttonStyle: ButtonStyle) => {
  switch (buttonStyle) {
    case 'primary':
    case 'primary-inverse':
    case 'secondary':
    case 'secondary-inverse':
    case 'tertiary':
      return getButtonDisabledStyles(colorBackgroundDisabled, BaseColor.Transparent);
    default:
      throw UnreachableCaseException(buttonStyle);
  }
};

const getDestructiveBackgroundStyles = (bgColor: string) => css<IActivableButton>`
  background: ${bgColor};

  &:active,
  &:hover {
    background: ${colorAlertHover};
  }

  ${({ $activated }) => $activated && css`background: ${colorAlertHover};`}
`;

const getDestructiveBorderStyles = (borderColor: string) => css<IActivableButton>`
  border-color: ${borderColor};

  &:active,
  &:hover {
    border-color: ${BaseColor.Transparent};
  }

  ${({ $activated }) => $activated && css`border-color: ${BaseColor.Transparent};`}
`;

const getSpecificDestructiveStyles = (buttonStyle: ButtonStyle) => {
  switch (buttonStyle) {
    case 'primary':
    case 'primary-inverse':
      return css`
        ${getDestructiveBackgroundStyles(colorAlertBackgroundInverse)}
        ${getDestructiveBorderStyles(BaseColor.Transparent)}
      `;
    case 'secondary':
    case 'tertiary':
      return css`
        ${getDestructiveBackgroundStyles(BaseColor.White)}
        ${getDestructiveBorderStyles(colorBorderAlert)}
      `;
    case 'secondary-inverse':
      return css`
        ${getDestructiveBackgroundStyles(BaseColor.White)}
        ${getDestructiveBorderStyles(BaseColor.Transparent)}
      `;
    default:
      throw UnreachableCaseException(buttonStyle);
  }
};

export const getSpecificButtonStyles = (
  buttonStyle: ButtonStyle,
  disabled?: boolean,
  destructive?: boolean,
) => {
  if (destructive) {
    return disabled
      ? getSpecificDestructiveButtonDisabledStyles(buttonStyle)
      : getSpecificDestructiveStyles(buttonStyle);
  }
  switch (buttonStyle) {
    case 'primary':
      return css`
        ${getPrimaryButtonStyles(
          colorPrimary,
          colorPrimaryHover,
          colorBackgroundDisabled,
          disabled,
        )}
      `;
    case 'primary-inverse':
      return getPrimaryButtonStyles(
        BaseColor.White,
        colorPrimaryHoverInverse,
        colorBackgroundDisabled,
        disabled,
      );
    case 'secondary':
    case 'tertiary':
      return getSecondaryButtonStyles(
        BaseColor.White,
        colorPrimaryHoverInverse,
        colorBackgroundDisabled,
        colorPrimary,
        BaseColor.Transparent,
        colorPrimary,
        disabled,
      );
    case 'secondary-inverse':
      return getSecondaryButtonStyles(
        BaseColor.Transparent,
        colorBackgroundHoverInverse,
        BaseColor.Transparent,
        BaseColor.White,
        colorBorderDisabled,
        BaseColor.White,
        disabled,
      );
    default:
      throw UnreachableCaseException(buttonStyle);
  }
};

export const getBorderWidth = (buttonStyle: ButtonStyle) => {
  switch (buttonStyle) {
    case 'secondary':
    case 'secondary-inverse':
    case 'tertiary':
      return borderWidthHighEmphasis;
    default:
      return 0;
  }
};

const getButtonIconColor = (buttonStyle: ButtonStyle, disabled?: boolean) => {
  if (disabled) {
    switch (buttonStyle) {
      case 'primary':
      case 'secondary-inverse':
      case 'primary-inverse':
      case 'secondary':
      case 'tertiary':
        return colorIconDisabled;
      default:
        throw UnreachableCaseException(buttonStyle);
    }
  }

  switch (buttonStyle) {
    case 'primary':
    case 'secondary-inverse':
      return colorTextOnDark;
    case 'primary-inverse':
      return colorPrimary;
    case 'secondary':
    case 'tertiary':
      return colorTextDefault;
    default:
      throw UnreachableCaseException(buttonStyle);
  }
};

const getDestructiveButtonIconColor = (
  buttonStyle: ButtonStyle,
  disabled?: boolean,
  activated?: boolean,
) => {
  if (disabled) {
    return colorIconDisabled;
  }
  switch (buttonStyle) {
    case 'primary':
    case 'primary-inverse':
      return colorTextOnDark;
    case 'secondary':
    case 'secondary-inverse':
    case 'tertiary':
      return activated ? colorTextOnDark : colorAlertIcon;
    default:
      throw UnreachableCaseException(buttonStyle);
  }
};

export const getIconColor = (
  buttonStyle: ButtonStyle,
  disabled?: boolean,
  destructive?: boolean,
  activated?: boolean,
) => {
  if (destructive) {
    return getDestructiveButtonIconColor(buttonStyle, disabled, activated);
  }
  return getButtonIconColor(buttonStyle, disabled);
};
