import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgPiechartLines = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.26352 1.35751C7.34229 1.62218 7.19159 1.90059 6.92692 1.97936C5.23494 2.4829 4.00199 4.05061 4.00199 5.90499C4.00199 8.16667 5.83547 10.0001 8.09722 10.0001C9.95146 10.0001 11.5191 8.76742 12.0228 7.07573C12.1016 6.81107 12.38 6.66041 12.6447 6.73921C12.9093 6.81801 13.06 7.09645 12.9812 7.3611C12.3547 9.4651 10.4059 11.0001 8.09722 11.0001C5.28321 11.0001 3.00199 8.71897 3.00199 5.90499C3.00199 3.59612 4.53734 1.64717 6.64167 1.0209C6.90634 0.942136 7.18475 1.09284 7.26352 1.35751Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M9.00195 2.12565V5.00013H11.8801C11.5183 3.5952 10.4091 2.48685 9.00195 2.12565ZM12.0075 5.00001C12.0075 5.00004 12.0071 5.00011 12.0064 5.00013L12.0075 5.00001ZM8.99559 1.0987C10.9618 1.4945 12.5108 3.04197 12.9072 5.00653 13.0164 5.5479 12.5583 6.00013 12.006 6.00013H9.00195C8.44967 6.00013 8.00195 5.55242 8.00195 5.00013V2.00013C8.00195 1.44785 8.45417.989717 8.99559 1.0987ZM1 12.4971C1 12.2209 1.22386 11.9971 1.5 11.9971L14.5 11.9971C14.7761 11.9971 15 12.2209 15 12.4971 15 12.7732 14.7761 12.9971 14.5 12.9971L1.5 12.9971C1.22386 12.9971 1 12.7732 1 12.4971ZM1 14.5005C1 14.2243 1.22386 14.0005 1.5 14.0005L14.5 14.0005C14.7761 14.0005 15 14.2243 15 14.5005 15 14.7766 14.7761 15.0005 14.5 15.0005L1.5 15.0005C1.22386 15.0005 1 14.7766 1 14.5005Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgPiechartLines.propTypes = iconPropTypes;
