import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgRectangleAInverted = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M2.99998 0.000221636L13 2.50994e-10C13.5523 -1.22405e-05 14 0.447707 14 1V15C14 15.5523 13.5523 16 13 16H3C2.44772 16 2 15.5523 2 15V1.00022C2 0.447946 2.4477 0.000233876 2.99998 0.000221636ZM7.94447 3.46092C8.15289 3.46092 8.33945 3.5902 8.41264 3.78535L11.4126 11.7854C11.5096 12.0439 11.3786 12.3321 11.12 12.4291C10.8615 12.526 10.5733 12.395 10.4763 12.1365L9.67373 9.99627L6.21528 9.99608L5.41264 12.1365C5.31568 12.395 5.02747 12.526 4.76891 12.4291C4.51035 12.3321 4.37935 12.0439 4.47631 11.7854L7.47631 3.78535C7.54949 3.5902 7.73605 3.46092 7.94447 3.46092ZM7.94447 5.38492L9.29872 8.99625L6.59028 8.9961L7.94447 5.38492Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgRectangleAInverted.propTypes = iconPropTypes;
