import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgBucketShovel = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M9.99463 1.5C9.99463 1.22386 10.2185 1 10.4946 1L14.4946 1C14.7708 1 14.9946 1.22386 14.9946 1.5C14.9946 1.77614 14.7708 2 14.4946 2L10.4946 2C10.2185 2 9.99463 1.77614 9.99463 1.5Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M12.9958 1.49904C12.9958 1.22343 12.7719 1 12.4956 1 12.2194 1 11.9954 1.22343 11.9954 1.49904V7.99415H10.9985C10.4469 7.99415 9.99808 8.43931 9.99808 8.9915V12.0882C9.99808 13.0155 10.4365 13.6827 10.9178 14.1267 11.3909 14.5632 11.9209 14.8012 12.1775 14.9004 12.3851 14.9806 12.613 14.9806 12.8205 14.9004 13.0772 14.8012 13.6072 14.5632 14.0803 14.1267 14.5616 13.6827 15 13.0155 15 12.0882V8.9915C15 8.43931 14.5511 7.99415 13.9996 7.99415H12.9958V1.49904ZM10.9985 8.99224H13.9996V12.0882C13.9996 12.6668 13.7359 13.085 13.4011 13.3939 13.0797 13.6904 12.7082 13.8693 12.499 13.9539 12.2899 13.8693 11.9184 13.6904 11.597 13.3939 11.2622 13.085 10.9985 12.6668 10.9985 12.0882V8.99224ZM1.98524 7.01543V5.50235C1.98524 4.03068 3.44454 3.00714 4.98639 3.00714 6.52824 3.00714 7.98754 4.03068 7.98754 5.50235V7.01532H8.00269C8.55518 7.01532 9.00307 7.46217 9.00307 8.0134V9.78179C9.00307 9.8634 8.99304 9.94469 8.9732 10.0239L7.91574 14.244C7.80441 14.6883 7.40427 15 6.94523 15H3.05784C2.5988 15 2.19866 14.6883 2.08733 14.244L1.02987 10.0239C1.01003 9.94469 1 9.8634 1 9.78179V8.0134C1 7.46722 1.43973 7.0235 1.98524 7.01543ZM2.98563 5.50235C2.98563 4.77823 3.77719 4.00522 4.98639 4.00522 6.1956 4.00522 6.98716 4.77823 6.98716 5.50235V7.01532H2.98563V5.50235ZM8.00269 8.0134H2.00038V9.78179L2.05783 10.011 5.50159 10.011C5.77784 10.011 6.00178 10.2345 6.00178 10.5101 6.00178 10.7857 5.77784 11.0091 5.50159 11.0091H2.30792L3.05784 14.0019H6.94523L8.00269 9.78179V8.0134Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgBucketShovel.propTypes = iconPropTypes;
