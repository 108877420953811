import { UnreachableCaseException } from '@kontent-ai/errors';
import styled from 'styled-components';
import { Spacing, gridUnit } from '../../../tokens/quarks/spacing.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { ButtonSize } from '../buttonSize.ts';

interface IStyledButtonBadgePositioner {
  readonly buttonSize: ButtonSize;
  readonly withValue: boolean;
}

const getBadgeSpacing = (buttonSize: ButtonSize, withValue: boolean): number => {
  switch (buttonSize) {
    case 'small':
      return withValue ? 0 : 0.75 * gridUnit;
    case 'medium':
      return withValue ? 0.75 * gridUnit : 1.25 * gridUnit;
    case 'large':
      return withValue ? Spacing.S : Spacing.M;
    default:
      throw UnreachableCaseException(buttonSize);
  }
};

export const StyledButtonBadgePositioner = styled.div<IStyledButtonBadgePositioner>`
  position: absolute;
  top: ${(props) => px(getBadgeSpacing(props.buttonSize, props.withValue))};
  right: ${(props) => px(getBadgeSpacing(props.buttonSize, props.withValue))};
`;
