import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgH6 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path fill="currentColor" d="M0 2V15H1V9H6V15H7V2H6V8H1V2H0Z" />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M11.2664 14.6848C11.6926 14.8908 12.1293 14.9958 12.5766 14.9999C13.2669 15.0041 13.8674 14.8187 14.3781 14.4439C14.8923 14.069 15.2903 13.5644 15.5721 12.9301C15.8574 12.2957 16 11.6017 16 10.8479C16 10.0529 15.8503 9.34645 15.5509 8.72858C15.2516 8.10659 14.8518 7.61847 14.3517 7.26423C13.8516 6.90587 13.3004 6.72669 12.6981 6.72669C12.3142 6.72669 11.9479 6.80495 11.5992 6.96148C11.2506 7.11388 10.9389 7.32808 10.6642 7.60406C10.393 7.87592 10.1711 8.19927 9.99849 8.57411H9.92981C9.93333 6.80701 10.1781 5.44358 10.6642 4.48383C11.1502 3.51995 11.837 3.03802 12.7245 3.03802C13.3444 3.03802 14.0302 3.25427 14.4 3.68678C14.7698 4.11929 14.9014 4.3327 15 5H16C16 4.5033 15.7812 4.10805 15.5055 3.6102L15.5 3.60028C15.1445 3.03802 14.7197 2.70643 14.2725 2.42633C13.8252 2.14211 13.3092 2 12.7245 2C12.1011 2 11.557 2.173 11.0921 2.51901C10.6272 2.8609 10.2397 3.34489 9.92981 3.971C9.61987 4.5971 9.38742 5.33854 9.23245 6.19532C9.07748 7.0521 9 7.99537 9 9.02515C9 10.1373 9.10038 11.0744 9.30113 11.8365C9.50189 12.5985 9.77308 13.2122 10.1147 13.6777C10.4564 14.139 10.8403 14.4747 11.2664 14.6848ZM13.8392 13.5418C13.4694 13.8219 13.0486 13.9619 12.5766 13.9619C12.1293 13.9619 11.726 13.8301 11.3668 13.5665C11.0111 13.3029 10.7223 12.9424 10.5004 12.4852C10.282 12.0239 10.1535 11.5028 10.1147 10.922C10.0865 10.506 10.1306 10.1085 10.2468 9.72952C10.3665 9.35056 10.5391 9.01486 10.7645 8.7224C10.9935 8.42582 11.2629 8.19309 11.5728 8.02421C11.8828 7.85121 12.2174 7.7647 12.5766 7.7647C13.0486 7.7647 13.4694 7.9027 13.8392 8.17868C14.2126 8.45466 14.5067 8.82538 14.7215 9.29084C14.9364 9.7563 15.0438 10.2753 15.0438 10.8479C15.0438 11.4204 14.9364 11.9435 14.7215 12.4172C14.5067 12.8868 14.2126 13.2617 13.8392 13.5418Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgH6.propTypes = iconPropTypes;
