import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgRotateLeft = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M2.66373 7.00879C3.1143 4.15171 5.48011 2 8.29311 2C11.4233 2 13.9997 4.6643 13.9997 8.00007C13.9997 11.3358 11.4233 14.0001 8.29311 14.0001C6.91966 14.0001 5.65812 13.4904 4.67051 12.6362C4.46165 12.4556 4.1459 12.4784 3.96525 12.6873C3.78461 12.8962 3.80748 13.2119 4.01634 13.3926C5.17497 14.3947 6.66564 15.0001 8.29311 15.0001C12.0186 15.0001 14.9997 11.8441 14.9997 8.00007C14.9997 4.15605 12.0186 1 8.29311 1C4.89349 1 2.11369 3.62807 1.6534 7.00879H0.500799C0.108082 7.00879 -0.13134 7.44076 0.0768003 7.77379L1.67468 10.3304C1.87051 10.6437 2.32684 10.6437 2.52267 10.3304L4.12055 7.77379C4.32869 7.44076 4.08927 7.00879 3.69655 7.00879H2.66373Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgRotateLeft.propTypes = iconPropTypes;
