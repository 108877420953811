import styled, { css } from 'styled-components';
import { transition500 } from '../../../tokens/quarks/transitions.ts';
import { px } from '../../../tokens/utils/utils.ts';

type StyledChevronProps = {
  readonly angle: number;
  readonly skipAnimation?: boolean;
  readonly $size?: number;
};

export const StyledChevron = styled.span.attrs<StyledChevronProps>((props) => ({
  style: {
    transform: `rotate(${props.angle}deg)`,
  },
}))<StyledChevronProps>`
  display: block;
  width: 1em;
  height: 1em;
  ${({ $size }) =>
    $size &&
    css`
    font-size: ${px($size)};
  `};

  ${({ skipAnimation }) =>
    !skipAnimation &&
    css`
    transition: transform ${transition500};
  `};
`;
