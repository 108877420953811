import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgTwoRectanglesStacked = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.14791 1.14254C1.24168 1.04877 1.36886 0.996091 1.50148 0.996094L9.50371 0.996287C9.77983 0.996294 10.0037 1.22013 10.0037 1.49625L10.0039 3.99504H14.4997C14.7758 3.99504 14.9997 4.21889 14.9997 4.49504V14.4961C14.9997 14.7722 14.7758 14.9961 14.4997 14.9961H6.50008C6.22394 14.9961 6.00008 14.7722 6.00008 14.4961V11.9959L1.50166 11.9957C1.22552 11.9957 1.00168 11.7718 1.00167 11.4957L1.00146 1.4961C1.00146 1.36349 1.05414 1.23631 1.14791 1.14254ZM7.00008 11.9959V13.9961H13.9997V4.99504H10.0039V11.4959C10.0039 11.772 9.78005 11.9959 9.50391 11.9959H7.00008ZM9.00373 1.99627L2.00148 1.99611L2.00166 10.9957L9.00391 10.9959L9.00373 1.99627Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgTwoRectanglesStacked.propTypes = iconPropTypes;
