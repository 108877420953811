import React from 'react';
import { ButtonSize } from '../buttonSize.ts';
import { ButtonStyle } from '../buttonStyle.ts';

export interface IButtonContext {
  readonly buttonStyle: ButtonStyle;
  readonly buttonSize: ButtonSize;
}

export const ButtonContext = React.createContext<IButtonContext>({
  buttonSize: 'medium',
  buttonStyle: 'primary',
});
