import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgTriangleRight = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M5.28511 1.04854C5.45928 0.965639 5.66559 0.990315 5.8153 1.11195L13.8153 7.61194C13.9322 7.70689 14 7.84944 14 8C14 8.15056 13.9322 8.29311 13.8153 8.38806L5.8153 14.8881C5.66559 15.0097 5.45928 15.0344 5.28511 14.9515C5.11095 14.8686 5 14.6929 5 14.5V1.5C5 1.30712 5.11095 1.13143 5.28511 1.04854ZM6 2.55049V13.4495L12.7071 8L6 2.55049Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgTriangleRight.propTypes = iconPropTypes;
