import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgBezierScheme = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M0.500306 3.99309C0.224163 3.99292 0.000169104 4.21681 9.4703e-08 4.49317C-0.00016799 4.76953 0.223553 4.9937 0.499695 4.99387L4.07385 4.99605C3.55566 5.30997 3.07416 5.68337 2.65244 6.12594C1.73208 7.09183 1.11934 8.36417 1.01564 9.99778L0.5 9.99778C0.223858 9.99778 3.4511e-07 10.2218 3.5719e-07 10.4982L4.44681e-07 12.4997C4.56761e-07 12.7761 0.223858 13.0001 0.5 13.0001L2.5 13.0001C2.77614 13.0001 3 12.7761 3 12.4997L3 10.4982C3 10.2218 2.77614 9.99778 2.5 9.99778L2.01794 9.99778C2.11838 8.62567 2.63326 7.59622 3.37613 6.8166C4.07283 6.08545 4.98889 5.55512 6 5.18429L6 6.49504C6 6.7714 6.22386 6.99543 6.5 6.99543L9.5 6.99543C9.77614 6.99543 10 6.7714 10 6.49504L10 5.16071C11.006 5.49861 11.923 6.02937 12.6214 6.77276C13.3656 7.5648 13.8817 8.61873 13.9821 9.99778L13.5 9.99778C13.2239 9.99778 13 10.2218 13 10.4982L13 12.4997C13 12.7761 13.2239 13.0001 13.5 13.0001L15.5 13.0001C15.7761 13.0001 16 12.7761 16 12.4997L16 10.4982C16 10.2218 15.7761 9.99778 15.5 9.99778L14.9844 9.99778C14.8809 8.36135 14.2694 7.06581 13.35 6.08722C12.9503 5.66179 12.4965 5.30037 12.0077 4.99753L15.4952 4.99753C15.7713 4.99753 15.9952 4.7735 15.9952 4.49714C15.9952 4.22078 15.7713 3.99675 15.4952 3.99675L10 3.99675L10 3.50051C10 3.22415 9.77614 3.00012 9.5 3.00012L6.5 3.00012C6.22386 3.00012 6 3.22416 6 3.50051L6 3.99645L0.500306 3.99309ZM7 4.50507C7.00017 4.49751 7.00017 4.48993 7 4.48232L7 4.0009L9 4.0009L9 4.48298L8.99988 4.49304L9 4.50472L9 5.99465L7 5.99465L7 4.50507ZM1 10.9986L1 11.9993L2 11.9993L2 10.9986L1 10.9986ZM15 10.9986L15 11.9993L14 11.9993L14 10.9986L15 10.9986Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" transform="rotate(-90 8 8)" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgBezierScheme.propTypes = iconPropTypes;
