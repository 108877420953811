import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgEBook = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 17 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M15.8265 1.11828C15.7143 1.02324 15.566 0.982513 15.4211 1.00694L8.5011 2.17358L1.58777 1.007C1.44239 0.982465 1.29358 1.02314 1.18098 1.11818C1.06837 1.21322 1.00342 1.35297 1.00342 1.5002V13.3179C1.00342 13.562 1.17984 13.7705 1.42085 13.8112L8.36462 14.9829C8.40893 14.9948 8.45488 15.0006 8.50119 14.9999C8.54826 15.0006 8.59432 14.9946 8.63829 14.9827L15.5869 13.8113C15.8273 13.7707 16.0034 13.5622 16.0034 13.318V1.5002C16.0034 1.35303 15.9387 1.21332 15.8265 1.11828ZM7.99475 13.9059V12.6321C7.99475 12.3559 8.21834 12.1319 8.49417 12.1319C8.76999 12.1319 8.99359 12.3559 8.99359 12.6321V13.8113L15.0046 12.895V2.09164L9.00855 3.1025V8.59085C9.00855 8.63286 9.00336 8.67367 8.99359 8.71266V9.29935L10.1388 8.15236C10.3338 7.95702 10.6501 7.95702 10.8451 8.15236C11.0401 8.34771 11.0401 8.66441 10.8451 8.85976L8.84739 10.8606C8.65236 11.0559 8.33614 11.0559 8.1411 10.8606L6.14341 8.85976C5.94837 8.66441 5.94837 8.34771 6.14341 8.15236C6.33845 7.95702 6.65466 7.95702 6.8497 8.15236L7.99475 9.29919V3.10271L2.0052 2.09201V12.8952L7.99475 13.9059Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgEBook.propTypes = iconPropTypes;
