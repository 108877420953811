import PropTypes, { Requireable } from 'prop-types';
import { BaseColor, Color } from '../tokens/quarks/colors.ts';

export const baseColorPropTypeValidator = PropTypes.oneOf(Object.values(BaseColor));

export const transparentColorPropTypeValidator = (
  props: Record<string, string>,
  propName: string,
  componentName: string,
) => {
  const prop = props[propName];

  if (prop === null || prop === undefined) {
    return null;
  }

  // You need this check despite what your IDE says, because when used in PropTypes.oneOfType([...]), React will run the validators sequentially ignoring typescript. And the prop can (obviously) be of multiple types.
  if (typeof prop !== 'string' || !prop.startsWith('rgba')) {
    return new Error(
      `Invalid prop ${propName} with value '${prop}' supplied to ${componentName}. Validation failed.`,
    );
  }
  return null;
};

export const colorPropTypeValidator = PropTypes.oneOfType([
  baseColorPropTypeValidator,
  transparentColorPropTypeValidator,
]) as Requireable<Color>;

export const gradientPropTypeValidator = PropTypes.shape({
  toString: PropTypes.func.isRequired,
  from: colorPropTypeValidator.isRequired,
  to: colorPropTypeValidator.isRequired,
});
