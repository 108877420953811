import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgArrowRight = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M12.8524 5.15023C12.6571 4.95448 12.3404 4.95448 12.145 5.15023C11.9497 5.34599 11.9497 5.66337 12.145 5.85912L14.2835 8.00216L0.500183 8.00216C0.22394 8.00216 0 8.22658 0 8.50342C0 8.78026 0.22394 9.00468 0.500183 9.00468L14.2835 9.00468L12.145 11.1477C11.9497 11.3435 11.9497 11.6608 12.145 11.8566C12.3404 12.0524 12.6571 12.0524 12.8524 11.8566L15.766 8.93675C15.7971 8.91715 15.8264 8.89379 15.8535 8.86667C15.9534 8.76656 16.0022 8.63463 15.9999 8.50342C16.0022 8.37222 15.9534 8.24028 15.8535 8.14016C15.8264 8.11305 15.7971 8.08969 15.766 8.07008L12.8524 5.15023Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgArrowRight.propTypes = iconPropTypes;
