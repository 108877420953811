import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLinesRectangle = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M3.04639 3.5498C3.04639 3.27366 3.27024 3.0498 3.54639 3.0498H12.5521C12.8282 3.0498 13.0521 3.27366 13.0521 3.5498 13.0521 3.82595 12.8282 4.0498 12.5521 4.0498H3.54639C3.27024 4.0498 3.04639 3.82595 3.04639 3.5498ZM3.04639 6.49805C3.04639 6.2219 3.27024 5.99805 3.54639 5.99805H12.5521C12.8282 5.99805 13.0521 6.2219 13.0521 6.49805 13.0521 6.77419 12.8282 6.99805 12.5521 6.99805H3.54639C3.27024 6.99805 3.04639 6.77419 3.04639 6.49805ZM3.54639 9.00293C3.27024 9.00293 3.04639 9.22679 3.04639 9.50293 3.04639 9.77907 3.27024 10.0029 3.54639 10.0029H12.5521C12.8282 10.0029 13.0521 9.77907 13.0521 9.50293 13.0521 9.22679 12.8282 9.00293 12.5521 9.00293H3.54639ZM3.04639 12.5117C3.04639 12.2356 3.27024 12.0117 3.54639 12.0117H12.5521C12.8282 12.0117 13.0521 12.2356 13.0521 12.5117 13.0521 12.7879 12.8282 13.0117 12.5521 13.0117H3.54639C3.27024 13.0117 3.04639 12.7879 3.04639 12.5117Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.50439 0C1.22825 0 1.00439 0.223858 1.00439 0.5V15.5C1.00439 15.7761 1.22825 16 1.50439 16H14.5038C14.7799 16 15.0038 15.7761 15.0038 15.5V0.5C15.0038 0.223858 14.7799 0 14.5038 0H1.50439ZM2.00439 15V1H14.0038V15H2.00439Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLinesRectangle.propTypes = iconPropTypes;
