import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLRows3 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M14.5 1C14.7761 1 15 1.22336 15 1.49889L15 4.49249C15 4.76802 14.7761 4.99138 14.5 4.99138L1.5 4.99138C1.22386 4.99138 1 4.76802 1 4.49249L1 1.49889C1 1.22336 1.22386 1 1.5 1L14.5 1ZM14 1.99778 2 1.99778 2 3.9936 14 3.9936V1.99778ZM14.5 6.00637C14.7761 6.00637 15 6.22973 15 6.50526V9.49858C15 9.77411 14.7761 9.99747 14.5 9.99747L1.5 9.99747C1.22386 9.99747 1 9.77411 1 9.49858L1 6.50526C1 6.22973 1.22386 6.00637 1.5 6.00637L14.5 6.00637ZM14 7.00414 2 7.00414 2 8.99969 14 8.99969V7.00414ZM15 11.5078C15 11.2323 14.7761 11.0089 14.5 11.0089L1.5 11.0089C1.22386 11.0089 1 11.2323 1 11.5078L1 14.5011C1 14.7766 1.22386 15 1.5 15L14.5 15C14.7761 15 15 14.7766 15 14.5011V11.5078ZM2 12.0067 14 12.0067V14.0022L2 14.0022 2 12.0067Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLRows3.propTypes = iconPropTypes;
