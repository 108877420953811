import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

interface IFormGroupProps {
  readonly children: React.ReactNode;
  readonly isCompact?: boolean;
}

const propTypes: PropTypesShape<IFormGroupProps> = {
  children: PropTypes.node.isRequired,
  isCompact: PropTypes.bool,
};

const FormGroup: React.FC<IFormGroupProps> = ({ children, isCompact }) => (
  <div
    className={classNames('form__group', {
      'form__group--is-compact': isCompact,
    })}
    {...getDataUiElementAttribute(DataUiElement.FormGroup)}
  >
    {children}
  </div>
);

FormGroup.displayName = 'FormGroup';
FormGroup.propTypes = propTypes;

export { FormGroup };
