import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgListNumbers = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M0 2.00885H1.00391V1.00898L2.00391 1.00879V5.00828L1.00391 5.00846V3.00872H0V2.00885ZM3.99951 2.5C3.99951 2.22386 4.22337 2 4.49951 2H15.4995C15.7757 2 15.9995 2.22386 15.9995 2.5 15.9995 2.77614 15.7757 3 15.4995 3H4.49951C4.22337 3 3.99951 2.77614 3.99951 2.5ZM4.5 8C4.22386 8 4 8.22386 4 8.5 4 8.77614 4.22386 9 4.5 9H15.5C15.7761 9 16 8.77614 16 8.5 16 8.22386 15.7761 8 15.5 8H4.5ZM4.5 14C4.22386 14 4 14.2239 4 14.5 4 14.7761 4.22386 15 4.5 15H15.5C15.7761 15 16 14.7761 16 14.5 16 14.2239 15.7761 14 15.5 14H4.5ZM1 12H0V11H2V16H0V15H1V14H0V13H1V12ZM2 9.99997H0V9.0001L1.02377 7.00036H0V6.00049H2V7.00036L1.02377 9.0001H2V9.99997Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgListNumbers.propTypes = iconPropTypes;
