import { UnreachableCaseException } from '@kontent-ai/errors';
import React from 'react';
import styled, { css } from 'styled-components';
import {
  colorAlertBackgroundInverse,
  colorAlertText,
  colorIconDefaultInverse,
  colorTextDefaultInverse,
} from '../../../tokens/decision/colors.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { BadgeStyle } from '../badgeStyle.ts';

export interface IStyledBadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  readonly badgeStyle: BadgeStyle;
  readonly withValue?: boolean;
}

const defaultColorStyles = css`
  background-color: ${colorAlertBackgroundInverse};
  color: ${colorTextDefaultInverse};
`;

const inverseColorStyles = css`
  background-color: ${colorIconDefaultInverse};
  color: ${colorAlertText};
`;

const getColorStyles = ({ badgeStyle }: IStyledBadgeProps) => {
  switch (badgeStyle) {
    case 'default':
      return defaultColorStyles;
    case 'inverse':
      return inverseColorStyles;
    default:
      throw UnreachableCaseException(badgeStyle);
  }
};

const getBadgeSizeStyles = ({ withValue }: IStyledBadgeProps) => {
  const spacing = withValue ? Spacing.L : Spacing.S;

  return css`
    height: ${px(spacing)};
    width: ${px(spacing)};
  `;
};

export const StyledBadge = styled.div<IStyledBadgeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${px(BorderRadius.Pill)};
  ${getBadgeSizeStyles};
  ${getColorStyles};
`;
