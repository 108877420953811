import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgH2 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M15.9997 6.01609C16.0637 4.02092 14.6613 1.98628 12.4968 2.00007 10.5663 2.01237 9.01231 3.5664 9.00001 5.49687L9.99999 5.50324C10.0088 4.12008 11.12 3.00886 12.5032 3.00005 13.9391 2.9909 15.0516 4.38061 15.0003 5.98406 14.9822 6.54611 14.6712 7.19992 14.3126 7.75728 14.1394 8.02645 13.9669 8.25535 13.8377 8.41679 13.7733 8.49726 13.7201 8.56035 13.6837 8.6026 13.6655 8.62372 13.6516 8.63959 13.6425 8.64978L13.6327 8.66074 13.6308 8.66283 13.6284 8.66558 9 13.8082V15H16V14H10.1727L14.3693 9.33712 14.3713 9.33489 14.3756 9.33016 14.3902 9.31389C14.4024 9.30006 14.4198 9.28034 14.4415 9.25516 14.4848 9.20482 14.5457 9.13248 14.6184 9.04165 14.7634 8.86049 14.9574 8.60327 15.1536 8.29836 15.5337 7.70755 15.9727 6.86136 15.9997 6.01609ZM0 15V2H1V8H6V2H7V15H6V9H1V15H0Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgH2.propTypes = iconPropTypes;
