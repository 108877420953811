import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgDocCopy = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.01156 3.00342V1.5C4.01156 0.671575 4.68242 0 5.50998 0H13.5016C14.3291 0 15 0.671573 15 1.5V11.5C15 12.3284 14.3291 13 13.5016 13H12.9903V15C12.9903 15.5522 12.543 16 11.9913 16H2.99895C2.44725 16 2 15.5522 2 15V4.00342C2 3.45113 2.44725 3.00342 2.99895 3.00342H4.01156ZM5.01051 1.5C5.01051 1.22386 5.23413 1 5.50998 1H13.5016C13.7774 1 14.001 1.22386 14.001 1.5V11.5C14.001 11.7761 13.7774 12 13.5016 12H12.9903V7.99367L8.4411 3.00342H5.01051V1.5ZM2.99895 15H11.9913V9.02686H8.48976C7.6622 9.02686 6.99133 8.35528 6.99133 7.52686V4.00342L2.99895 4.00342V15ZM8.00033 4.00342L11.6681 8.02686H8.48976C8.2139 8.02686 7.99028 7.803 7.99028 7.52686V4.00342H8.00033Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgDocCopy.propTypes = iconPropTypes;
