import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgTags = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M8.04707 5.03679C8.04707 6.14234 7.15164 7.03857 6.04707 7.03857C4.9425 7.03857 4.04707 6.14234 4.04707 5.03679C4.04707 3.93124 4.9425 3.03501 6.04707 3.03501C7.15164 3.03501 8.04707 3.93124 8.04707 5.03679ZM6.04707 6.03768C6.59936 6.03768 7.04707 5.58957 7.04707 5.03679C7.04707 4.48402 6.59936 4.0359 6.04707 4.0359C5.49479 4.0359 5.04707 4.48402 5.04707 5.03679C5.04707 5.58957 5.49479 6.03768 6.04707 6.03768Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3.25601 1.00012L7.60308 1C7.76233 1 7.97126 1.02625 8.16468 1.07497C8.26312 1.09977 8.36947 1.13333 8.47016 1.17866C8.56499 1.22134 8.68708 1.28862 8.78826 1.3954L8.78927 1.39646L13.636 6.54122C14.1207 7.05343 14.1211 7.83494 13.636 8.34692L13.2923 8.70957L13.6192 9.02498C14.1262 9.51213 14.1267 10.276 13.6192 10.7629L9.57451 14.6433C9.08061 15.1171 8.3258 15.1189 7.82989 14.6488L2.39125 9.72111L2.38082 9.71139C2.27541 9.61026 2.17465 9.48843 2.10309 9.33398C2.03023 9.17671 2 9.0124 2 8.84241V2.29836C2 1.95299 2.1106 1.62306 2.34513 1.37557C2.58191 1.1257 2.90738 1.00012 3.25601 1.00012ZM7.60309 2.00089L3.25603 2.00101C3.15106 2.00101 3.0985 2.03499 3.07068 2.06435C3.0406 2.09609 3 2.16506 3 2.29836V6.28737C3 6.38292 3.01596 6.42282 3.08515 6.49674L8.52291 11.9163L8.53288 11.9265C8.63811 12.0375 8.76052 12.0375 8.86574 11.9265L12.9104 7.65815C13.0299 7.53211 13.0299 7.35604 12.9104 7.23L12.9094 7.22893L8.07766 2.10005C8.0736 2.0979 8.06781 2.095 8.06002 2.09149C8.02778 2.07698 7.9805 2.06068 7.9206 2.04559C7.79733 2.01453 7.67065 2.00089 7.60309 2.00089ZM3 7.82435V8.84241C3 8.89001 3.00757 8.90702 3.01031 8.91293C3.01422 8.92138 3.02616 8.94336 3.06832 8.9845L8.50566 13.911L8.51608 13.9207C8.62508 14.0253 8.77354 14.0253 8.88254 13.9207L12.9272 10.0404C12.9856 9.98431 13 9.93127 13 9.89396C13 9.85665 12.9857 9.80362 12.9272 9.74755L12.9262 9.74654L12.6042 9.43581L9.59131 12.6153C9.09355 13.1405 8.31265 13.1425 7.81282 12.621L3 7.82435Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgTags.propTypes = iconPropTypes;
