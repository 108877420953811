import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgFacePlus = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1 8C1 4.13401 4.13401 1 8 1C11.8313 1 14.9438 4.07815 14.9992 7.8962C15.0033 8.17231 15.2303 8.3929 15.5065 8.38889C15.7826 8.38488 16.0031 8.1578 15.9991 7.88168C15.9358 3.51787 12.3788 0 8 0C3.58172 0 0 3.58172 0 8C0 12.3788 3.51787 15.9358 7.88168 15.9991C8.1578 16.0031 8.38488 15.7826 8.38889 15.5065C8.3929 15.2303 8.17231 15.0033 7.8962 14.9992C4.07815 14.9438 1 11.8313 1 8Z"
        />
        <path
          fill="currentColor"
          d="M6 8C6.55228 8 7 7.10457 7 6 7 4.89543 6.55228 4 6 4 5.44772 4 5 4.89543 5 6 5 7.10457 5.44772 8 6 8ZM10 8C10.5523 8 11 7.10457 11 6 11 4.89543 10.5523 4 10 4 9.44771 4 9 4.89543 9 6 9 7.10457 9.44771 8 10 8ZM12 10H14V12H16V14H14V16H12V14H10V12H12V10ZM3.67958 9.81636C3.89156 9.6394 4.20687 9.66779 4.38383 9.87977 5.19792 10.855 6.50767 11.4998 8 11.4998 8.27614 11.4998 8.5 11.7236 8.5 11.9998 8.5 12.2759 8.27614 12.4998 8 12.4998 6.22113 12.4998 4.62653 11.7309 3.61617 10.5206 3.4392 10.3086 3.46759 9.99333 3.67958 9.81636Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgFacePlus.propTypes = iconPropTypes;
