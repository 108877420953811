import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgChevronDownLine = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1.49688 13C1.22084 13 .99707 12.7761.99707 12.5.99707 12.2239 1.22084 12 1.49688 12H14.4919C14.7679 12 14.9917 12.2239 14.9917 12.5 14.9917 12.7761 14.7679 13 14.4919 13H1.49688ZM14.7913 3.90529C15.0145 3.74283 15.0638 3.43011 14.9014 3.20682 14.739 2.98352 14.4264 2.9342 14.2032 3.09666L7.99974 7.87642 1.79629 3.09666C1.57308 2.9342 1.26049 2.98352 1.09809 3.20682.935692 3.43011.98499 3.74283 1.2082 3.90529L7.70569 8.89906C7.88098 9.02665 8.1185 9.02665 8.29378 8.89906L14.7913 3.90529Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgChevronDownLine.propTypes = iconPropTypes;
