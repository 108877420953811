import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgArrowUpCircle = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M11.3533 7.34848C11.158 7.54374 10.8415 7.54374 10.6462 7.34848L8.99634 5.69861L8.99634 12.4954C8.99634 12.7716 8.77248 12.9954 8.49634 12.9954C8.22019 12.9954 7.99634 12.7716 7.99634 12.4954V5.70633L6.35419 7.34848C6.15892 7.54374 5.84234 7.54374 5.64708 7.34848C5.45182 7.15321 5.45182 6.83663 5.64708 6.64137L8.14664 4.14181C8.3419 3.94655 8.65848 3.94655 8.85375 4.14181L11.3533 6.64137C11.5486 6.83663 11.5486 7.15321 11.3533 7.34848Z" />
          <path
            fillRule="evenodd"
            d="M8.50464 16C12.6468 16 16.0046 12.6421 16.0046 8.5C16.0046 4.35786 12.6468 1 8.50464 1C4.3625 1 1.00464 4.35786 1.00464 8.5C1.00464 12.6421 4.3625 16 8.50464 16ZM15.0046 8.5C15.0046 12.0899 12.0945 15 8.50464 15C4.91479 15 2.00464 12.0898 2.00464 8.5C2.00464 4.91015 4.91479 2 8.50464 2C12.0945 2 15.0046 4.91015 15.0046 8.5Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" transform="rotate(-90 8 8)" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgArrowUpCircle.propTypes = iconPropTypes;
