import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgSquareDashed = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path
            fillRule="evenodd"
            d="M2.00299 14.9986V15.9986L.636601 15.9992C.467827 15.9992.305964 15.9465.186623 15.8527.0672819 15.7589.00023696 15.6318.000236966 15.4992L.000236988 14.9992 2.00299 14.9986ZM13.9849 1.00055V.00055383L15.3513 0C15.5201-2.36404e-7 15.6819.0526785 15.8013.146447 15.9206.240215 15.9877.367392 15.9877.5V.999999L13.9849 1.00055ZM15.004 13.9957V15.9978L15.3637 15.9988C15.5325 15.9988 15.6944 15.9461 15.8137 15.8524 15.9331 15.7586 16.0001 15.6314 16.0001 15.4988V13.9966L15.004 13.9957Z"
            clipRule="evenodd"
          />
          <path d="M12.9849 14.9996H13.9849V15.9996H12.9849V14.9996ZM8.98492 14.9996H9.98492V15.9996H8.98492V14.9996ZM10.9849 14.9996H11.9849V15.9996H10.9849V14.9996ZM6.98492 14.9996H7.98492V15.9996H6.98492V14.9996ZM4.98492 14.9996H5.98492V15.9996H4.98492V14.9996ZM2.9942 14.9996H3.9942V15.9996H2.9942V14.9996ZM12.002.000553472H13.002V1.00055H12.002V.000553472ZM8.00201.000553472H9.00201V1.00055H8.00201V.000553472ZM10.002.000553472H11.002V1.00055H10.002V.000553472ZM6.00201.000553472H7.00201V1.00055H6.00201V.000553472ZM4.00201.000553472H5.00201V1.00055H4.00201V.000553472ZM2.01129.000553472H3.01129V1.00055H2.01129V.000553472ZM16.004 11.9991V12.9991H15.004L15.004 11.9991H16.004ZM16.004 7.99909V8.99909H15.004V7.99909H16.004ZM16.004 9.99909V10.9991H15.004V9.99909H16.004ZM16.004 5.99909V6.99909H15.004V5.99909H16.004ZM16.004 3.99909V4.99909H15.004V3.99909H16.004ZM16.004 2.00837V3.00837H15.004V2.00837H16.004Z" />
          <path
            fillRule="evenodd"
            d="M0.99615 2.00934L0.99615 0.00718401L0.636364 0.00620743C0.46759 0.00620719 0.305728 0.0588864 0.186386 0.152654C0.0670451 0.246423 7.14575e-08 0.373599 6.5661e-08 0.506208L0 2.00837L0.99615 2.00934Z"
            clipRule="evenodd"
          />
          <path d="M.0000573981 4.00495.0000575292 3.00495H1.00006L1.00006 4.00495H.0000573981ZM.0000568735 8.00495.0000570047 7.00495H1.00006L1.00006 8.00495H.0000568735ZM.0000571358 6.00495.0000572669 5.00495H1.00006L1.00006 6.00495H.0000571358ZM.0000566113 10.0049.0000567424 9.00495H1.00006L1.00006 10.0049H.0000566113ZM.000056349 12.0049.0000564801 11.0049H1.00006L1.00006 12.0049H.000056349ZM.0000560879 13.9957.0000562191 12.9957H1.00006L1.00006 13.9957H.0000560879Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgSquareDashed.propTypes = iconPropTypes;
