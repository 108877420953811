import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgStarSemi = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.48188 13.3899L4.86723 14.8609C4.06155 15.3141 3.11105 14.5938 3.27535 13.6545L3.80855 10.6061C3.8745 10.2291 3.75415 9.84276 3.48833 9.57816L1.33919 7.43891C0.676956 6.77972 1.04001 5.6142 1.94723 5.48693L4.89142 5.07388C5.25557 5.0228 5.57065 4.78402 5.72976 4.43856L7.01617 1.64549C7.21433 1.21523 7.60706 1.00007 7.99981 1C8.3927 0.999932 8.78561 1.2151 8.98383 1.64549L10.2702 4.43856C10.4294 4.78402 10.7444 5.0228 11.1086 5.07388L14.0528 5.48693C14.96 5.6142 15.323 6.77972 14.6608 7.43891L12.5117 9.57816C12.2459 9.84276 12.1255 10.2291 12.1915 10.6061L12.7247 13.6545C12.8889 14.5938 11.9384 15.3141 11.1328 14.8609L8.51812 13.3899C8.35637 13.2989 8.17809 13.2535 7.99981 13.2535C7.82166 13.2535 7.64352 13.299 7.48188 13.3899ZM7.99981 2V12.2535C8.34714 12.2535 8.69446 12.3418 9.00844 12.5184L11.6231 13.9893C11.6371 13.9972 11.645 13.9991 11.6472 13.9995L11.6504 14L11.6525 13.9999C11.6561 13.9996 11.6698 13.9971 11.6885 13.9829C11.7072 13.9687 11.7231 13.9485 11.7327 13.9251C11.7409 13.9051 11.748 13.8745 11.7396 13.8268L11.2064 10.7784C11.0856 10.0876 11.3033 9.37001 11.8062 8.86942L13.9553 6.73017C13.9803 6.70534 13.991 6.6822 13.9962 6.65865C14.0022 6.63106 14.0016 6.59621 13.9906 6.56086C13.9795 6.52541 13.9625 6.50338 13.9511 6.49298C13.9434 6.48601 13.935 6.48019 13.9138 6.47723L10.9697 6.06419C10.2547 5.96388 9.65755 5.4987 9.36195 4.85689L8.07554 2.06382C8.05717 2.02392 8.04005 2.01259 8.03479 2.0093C8.02625 2.00396 8.01424 2 7.99981 2Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgStarSemi.propTypes = iconPropTypes;
