import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgMerge = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M0 4C0 5.10457 0.895431 6 2 6C3.10457 6 4 5.10457 4 4H8.71583C7.86296 4.70525 7.22695 5.82822 7.22695 7.5C7.22695 8.98565 6.75918 9.82015 6.12847 10.303C5.52047 10.7684 4.68165 10.9695 3.73055 10.9967C3.38428 10.4007 2.73894 10 2 10C0.895431 10 0 10.8954 0 12C0 13.1046 0.895431 14 2 14C3.10457 14 4 13.1046 4 12C4 11.9953 3.99998 11.9907 3.99995 11.986C4.9784 11.9296 5.9583 11.6926 6.73634 11.097C7.67315 10.3799 8.22695 9.21435 8.22695 7.5C8.22695 6.05099 8.78693 5.19993 9.44522 4.69745C10.1271 4.17693 10.9573 4 11.5 4H12C12 5.10457 12.8954 6 14 6C15.1046 6 16 5.10457 16 4C16 2.89543 15.1046 2 14 2C13.2597 2 12.6134 2.4022 12.2676 3L3.73243 3C3.38663 2.4022 2.74028 2 2 2C0.895431 2 0 2.89543 0 4ZM1 4C1 4.55228 1.44772 5 2 5C2.55228 5 3 4.55228 3 4C3 3.44772 2.55228 3 2 3C1.44772 3 1 3.44772 1 4ZM2 13C1.44772 13 1 12.5523 1 12C1 11.4477 1.44772 11 2 11C2.55228 11 3 11.4477 3 12C3 12.5523 2.55228 13 2 13Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgMerge.propTypes = iconPropTypes;
