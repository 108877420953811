import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgDoc = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3 0H9.2L14 4.57143V15C14 15.5523 13.5523 16 13 16H3C2.44771 16 2 15.5523 2 15V1C2 0.447715 2.44772 0 3 0ZM13 15H3L3 1H7.99805V4.49339C7.99805 5.32182 8.66962 5.99339 9.49805 5.99339H13V15ZM12.9931 4.99339L8.99805 1.18862V4.49339C8.99805 4.76953 9.2219 4.99339 9.49805 4.99339H12.9931Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgDoc.propTypes = iconPropTypes;
