import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgPictures = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M5.00393 3.00335C3.89899 3.00335 3.00325 3.89853 3.00325 5.00279C3.00325 6.10705 3.89899 7.00223 5.00393 7.00223C6.10888 7.00223 7.00461 6.10705 7.00461 5.00279C7.00461 3.89853 6.10888 3.00335 5.00393 3.00335ZM4.00359 5.00279C4.00359 4.45066 4.45146 4.00307 5.00393 4.00307C5.55641 4.00307 6.00427 4.45066 6.00427 5.00279C6.00427 5.55492 5.55641 6.00251 5.00393 6.00251C4.45146 6.00251 4.00359 5.55492 4.00359 5.00279Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.00031 2.11083V10.5224C0.999899 10.534 0.999896 10.5456 1.00031 10.5573V11.8859C1.00031 12.1517 1.04846 12.471 1.28742 12.7098C1.52637 12.9485 1.84582 12.9967 2.1118 12.9967H11.8929C12.1589 12.9967 12.4783 12.9485 12.7173 12.7098C12.9562 12.471 13.0044 12.1517 13.0044 11.8859V2.1108C13.0044 1.84498 12.9562 1.52572 12.7173 1.28692C12.4783 1.04813 12.1589 1 11.8929 1L2.23406 1.00003C1.96267 1.00003 1.65167 1.05048 1.39909 1.2428C1.12146 1.45419 1.00031 1.76935 1.00031 2.11083ZM11.8929 1.99972L2.23406 1.99975C2.08606 1.99975 2.02495 2.02673 2.00934 2.03551C2.00646 2.04417 2.00065 2.06704 2.00065 2.11083V9.77979L4.25655 8.80818C4.37302 8.75802 4.50421 8.75394 4.62357 8.79678L6.46513 9.45775L8.91936 7.00503C9.08165 6.84284 9.33357 6.81185 9.53037 6.92986L12.004 8.41315V2.1108C12.004 2.06349 12.0012 2.02945 11.9978 2.00591C11.9743 2.00255 11.9402 1.99972 11.8929 1.99972ZM12.004 9.57901L9.35057 7.98791L7.50601 9.83133L8.16862 10.0692C8.4286 10.1625 8.56366 10.4487 8.47029 10.7085C8.37693 10.9684 8.09048 11.1033 7.8305 11.01L4.47121 9.80434L2.00065 10.8684V11.8859C2.00065 11.9332 2.00348 11.9672 2.00684 11.9907C2.03039 11.9941 2.06445 11.9969 2.1118 11.9969H11.8929C11.9402 11.9969 11.9743 11.9941 11.9979 11.9907C12.0012 11.9672 12.004 11.9332 12.004 11.8859V9.57901Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M14.4998 3.00335C14.7761 3.00335 15 3.22714 15 3.50321V12.0564C15 13.7172 13.7164 15 12.0546 15H3.49609C3.21986 15 2.99592 14.7762 2.99592 14.5001C2.99592 14.2241 3.21986 14.0003 3.49609 14.0003H12.0546C13.164 14.0003 13.9997 13.1651 13.9997 12.0564V3.50321C13.9997 3.22714 14.2236 3.00335 14.4998 3.00335Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgPictures.propTypes = iconPropTypes;
