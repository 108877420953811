import { Validator } from 'react';

export const multiplePropTypeValidator =
  <T>(...validators: readonly Validator<T>[]): Validator<T> =>
  (...args) => {
    const errors = validators.map((type) => type(...args)).filter(Boolean);

    if (errors.length === 0) {
      return null;
    }

    const message = errors.map((e: Error) => e.message).join('\n');
    return new Error(message);
  };
