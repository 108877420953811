import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgBell = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.97105 0C5.51096 0 2.99474 2.23879 2.99474 5.5V10.2176L2.13093 11.1627C2.0467 11.2548 2 11.3752 2 11.5V12.5C2 12.7761 2.22386 13 2.5 13H13.5C13.7761 13 14 12.7761 14 12.5V11.5C14 11.3703 13.9496 11.2456 13.8594 11.1524L12.9474 10.2094V5.5C12.9474 2.23879 10.4311 0 7.97105 0ZM3.99474 5.5C3.99474 2.76121 6.09264 1 7.97105 1C9.84945 1 11.9474 2.76121 11.9474 5.5V10.4116C11.9474 10.5414 11.9978 10.666 12.088 10.7593L13 11.7022V12H3V11.6941L3.86381 10.749C3.94803 10.6568 3.99474 10.5365 3.99474 10.4116V5.5Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M5.5 15C5.22386 15 5 15.2239 5 15.5C5 15.7761 5.22386 16 5.5 16H10.5C10.7761 16 11 15.7761 11 15.5C11 15.2239 10.7761 15 10.5 15H5.5Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgBell.propTypes = iconPropTypes;
