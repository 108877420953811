import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgMarket = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3.40866 1.28631C3.4912 1.11149 3.66702 1 3.86015 1H12.1228C12.3159 1 12.4917 1.11149 12.5743 1.28631L14.935 6.28631C15.0082 6.4412 14.997 6.6228 14.9054 6.76754C14.8139 6.91228 14.6547 7 14.4835 7H13.9895V14.0012H14.5006C14.7764 14.0012 15 14.2251 15 14.5012C15 14.7774 14.7764 15.0012 14.5006 15.0012H1.51646C1.24065 15.0012 1.01707 14.7774 1.01707 14.5012C1.01707 14.2251 1.24065 14.0012 1.51646 14.0012H2.00415V7H1.49939C1.32827 7 1.16907 6.91228 1.07751 6.76754C0.985943 6.6228 0.974773 6.4412 1.04791 6.28631L3.40866 1.28631ZM10.3585 7H12.9907V10.999L3.00293 10.999V7H5.62481C5.62684 7.00002 5.62887 7.00004 5.6309 7.00004H10.3524C10.3544 7.00004 10.3565 7.00002 10.3585 7ZM10.7963 6H13.6951L11.8065 2H10.3241L10.7963 6ZM9.79052 6L9.31838 2.00004H6.66494L6.19279 6H9.79052ZM5.18706 6L5.65921 2H4.17645L2.28785 6H5.18706ZM3.00293 11.999V14.001H12.9907V11.999L3.00293 11.999Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgMarket.propTypes = iconPropTypes;
