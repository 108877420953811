import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgMinusCircle = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M4.50488 8.00293C4.22874 8.00293 4.00488 8.22679 4.00488 8.50293C4.00488 8.77907 4.22874 9.00293 4.50488 9.00293H11.5049C11.781 9.00293 12.0049 8.77907 12.0049 8.50293C12.0049 8.22679 11.781 8.00293 11.5049 8.00293H4.50488Z" />
          <path
            fillRule="evenodd"
            d="M0 8C1.93129e-07 3.58172 3.58172 -1.93129e-07 8 0C12.4183 1.93129e-07 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 7.60545e-07 12.4183 0 8ZM8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" transform="rotate(90 8 8)" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgMinusCircle.propTypes = iconPropTypes;
