import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgChevronDownSquare = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M11.8543 7.18632C12.0495 6.99105 12.0495 6.67447 11.8543 6.47921C11.659 6.28395 11.3424 6.28395 11.1472 6.47921L7.99977 9.62662L4.85236 6.47921C4.65709 6.28395 4.34051 6.28395 4.14525 6.47921C3.94999 6.67447 3.94999 6.99105 4.14525 7.18632L7.64621 10.6873C7.84148 10.8825 8.15806 10.8825 8.35332 10.6873L11.8543 7.18632Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M0.997803 13.0022C0.997803 14.1068 1.89323 15.0022 2.9978 15.0022H13.0017C14.1062 15.0022 15.0017 14.1068 15.0017 13.0022V2.99834C15.0017 1.89377 14.1062 0.998339 13.0017 0.998339H2.9978C1.89324 0.998339 0.997803 1.89377 0.997803 2.99834V13.0022ZM2.9978 14.0022H13.0017C13.5539 14.0022 14.0017 13.5545 14.0017 13.0022V2.99834C14.0017 2.44605 13.5539 1.99834 13.0017 1.99834H2.9978C2.44552 1.99834 1.9978 2.44606 1.9978 2.99834V13.0022C1.9978 13.5545 2.44552 14.0022 2.9978 14.0022Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgChevronDownSquare.propTypes = iconPropTypes;
