import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgList = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M5.99057 2.4997C5.99057 2.22372 6.21466 2 6.4911 2H14.4995C14.7759 2 15 2.22372 15 2.4997 15 2.77568 14.7759 2.9994 14.4995 2.9994H6.4911C6.21466 2.9994 5.99057 2.77568 5.99057 2.4997ZM1.50492 5.99857C1.22849 5.99857 1.0044 6.2223 1.0044 6.49827 1.0044 6.77425 1.22849 6.99797 1.50492 6.99797H2.50597C2.7824 6.99797 3.00649 6.77425 3.00649 6.49827 3.00649 6.2223 2.7824 5.99857 2.50597 5.99857H1.50492ZM1.50492 10.004C1.22849 10.004 1.0044 10.2277 1.0044 10.5037 1.0044 10.7797 1.22849 11.0034 1.50492 11.0034H2.50597C2.7824 11.0034 3.00649 10.7797 3.00649 10.5037 3.00649 10.2277 2.7824 10.004 2.50597 10.004H1.50492ZM1 14.5003C1 14.2243 1.22409 14.0006 1.50052 14.0006H2.50157C2.778 14.0006 3.00209 14.2243 3.00209 14.5003 3.00209 14.7763 2.778 15 2.50157 15H1.50052C1.22409 15 1 14.7763 1 14.5003ZM6.4911 5.99857C6.21466 5.99857 5.99057 6.2223 5.99057 6.49827 5.99057 6.77425 6.21466 6.99797 6.4911 6.99797H14.4995C14.7759 6.99797 15 6.77425 15 6.49827 15 6.2223 14.7759 5.99857 14.4995 5.99857H6.4911ZM5.99057 10.5037C5.99057 10.2277 6.21466 10.004 6.4911 10.004H14.4995C14.7759 10.004 15 10.2277 15 10.5037 15 10.7797 14.7759 11.0034 14.4995 11.0034H6.4911C6.21466 11.0034 5.99057 10.7797 5.99057 10.5037ZM6.4911 14.0006C6.21466 14.0006 5.99057 14.2243 5.99057 14.5003 5.99057 14.7763 6.21466 15 6.4911 15H14.4995C14.7759 15 15 14.7763 15 14.5003 15 14.2243 14.7759 14.0006 14.4995 14.0006H6.4911ZM1.50052 2C1.22409 2 1 2.22372 1 2.4997 1 2.77568 1.22409 2.9994 1.50052 2.9994H2.50157C2.778 2.9994 3.00209 2.77568 3.00209 2.4997 3.00209 2.22372 2.778 2 2.50157 2H1.50052Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgList.propTypes = iconPropTypes;
