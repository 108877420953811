import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgTwoRectanglesH = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M14 8.01425L2 8.01425L2 14L14 14L14 8.01425ZM2 7.01425L14 7.01425L14 2L2 2L2 7.01425ZM15 14.5C15 14.6326 14.9473 14.7598 14.8536 14.8536C14.7598 14.9473 14.6326 15 14.5 15L1.5 15C1.22386 15 1 14.7761 1 14.5L1 1.5C1 1.36739 1.05268 1.24021 1.14645 1.14645C1.24022 1.05268 1.36739 1 1.5 1L14.5 1C14.7761 1 15 1.22386 15 1.5L15 14.5Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgTwoRectanglesH.propTypes = iconPropTypes;
