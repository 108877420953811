import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgRestrictionList = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1.8528 1.14642C1.65771.951195 1.34141.951195 1.14632 1.14642.951228 1.34164.951228 1.65815 1.14632 1.85338L2.7913 3.49948 1.14632 5.14558C.951228 5.3408.951228 5.65732 1.14632 5.85254 1.34141 6.04776 1.65771 6.04776 1.8528 5.85254L3.49778 4.20644 5.14277 5.85254C5.33786 6.04776 5.65416 6.04776 5.84925 5.85254 6.04434 5.65732 6.04434 5.3408 5.84925 5.14558L4.20426 3.49948 5.84925 1.85338C6.04434 1.65815 6.04434 1.34164 5.84925 1.14642 5.65416.951195 5.33786.951195 5.14277 1.14642L3.49778 2.79252 1.8528 1.14642ZM8.4914 2.99179C8.2155 2.99179 7.99185 3.2156 7.99185 3.49168 7.99185 3.76777 8.2155 3.99158 8.4914 3.99158H14.4861C14.762 3.99158 14.9856 3.76777 14.9856 3.49168 14.9856 3.2156 14.762 2.99179 14.4861 2.99179H8.4914ZM8.50576 11.9889C8.22986 11.9889 8.0062 12.2127 8.0062 12.4888 8.0062 12.7649 8.22986 12.9887 8.50576 12.9887H14.5004C14.7763 12.9887 15 12.7649 15 12.4888 15 12.2127 14.7763 11.9889 14.5004 11.9889H8.50576ZM1.1512 10.1475C1.34628 9.95224 1.66259 9.95224 1.85768 10.1475L3.50273 11.7936 5.14779 10.1475C5.34288 9.95224 5.65918 9.95224 5.85427 10.1475 6.04936 10.3427 6.04936 10.6592 5.85427 10.8544L4.20921 12.5006 5.85413 14.1466C6.04922 14.3418 6.04922 14.6584 5.85413 14.8536 5.65904 15.0488 5.34274 15.0488 5.14765 14.8536L3.50273 13.2075 1.85781 14.8536C1.66272 15.0488 1.34642 15.0488 1.15133 14.8536.956243 14.6584.956243 14.3418 1.15133 14.1466L2.79625 12.5006 1.1512 10.8544C.956106 10.6592.956106 10.3427 1.1512 10.1475Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgRestrictionList.propTypes = iconPropTypes;
