import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgParentChildrenScheme3 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4 1.5C4 1.22386 4.22386 1 4.5 1H12.5C12.7761 1 13 1.22386 13 1.5V6.5C13 6.77614 12.7761 7 12.5 7H7.99303V9H13.4894C13.7655 9 13.9894 9.22386 13.9894 9.5V11H14.5C14.7761 11 15 11.2239 15 11.5V14.5C15 14.7761 14.7761 15 14.5 15H11.5C11.2239 15 11 14.7761 11 14.5V11.5C11 11.2239 11.2239 11 11.5 11H12.9894V10H9V11H9.5C9.77614 11 10 11.2239 10 11.5V14.5C10 14.7761 9.77614 15 9.5 15H6.5C6.22386 15 6 14.7761 6 14.5V11.5C6 11.2239 6.22386 11 6.5 11H8V10H2.98938V11H4.5C4.77614 11 5 11.2239 5 11.5V14.5C5 14.7761 4.77614 15 4.5 15H1.5C1.22386 15 1 14.7761 1 14.5V11.5C1 11.2239 1.22386 11 1.5 11H1.98938V9.5C1.98938 9.22386 2.21324 9 2.48938 9H6.99303V7H4.5C4.22386 7 4 6.77614 4 6.5V1.5ZM7 12V14H9V12H7ZM12 6H5V2H12V6ZM12 12V14H14V12H12ZM2 14V12H4V14H2Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgParentChildrenScheme3.propTypes = iconPropTypes;
