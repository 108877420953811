import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgHalfArrowsRightLeft = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M15.4993 7.00367C15.7024 7.00367 15.8854 6.88054 15.962 6.69218 16.0387 6.50383 15.9939 6.28774 15.8487 6.14553L11.7601 2.1426C11.5628 1.9494 11.2464 1.95296 11.0535 2.15055 10.8605 2.34814 10.8641 2.66493 11.0614 2.85813L14.2735 6.00293.508021 6.00293C.232039 6.00293.00831164 6.22695.00831161 6.5033.00831159 6.77964.232039 7.00366.508021 7.00366L15.4993 7.00367ZM.498749 8.99634C.295613 8.99634.112692 9.11947.0360104 9.30782-.0406708 9.49617.00414955 9.71227.149393 9.85447L4.23792 13.8574C4.43525 14.0506 4.75163 14.047 4.94457 13.8494 5.13752 13.6519 5.13396 13.3351 4.93663 13.1419L1.72459 9.99707H15.49C15.766 9.99707 15.9897 9.77305 15.9897 9.4967 15.9897 9.22036 15.766 8.99634 15.49 8.99634H.498749Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgHalfArrowsRightLeft.propTypes = iconPropTypes;
