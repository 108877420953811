import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgPin = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.77317 4.02586C6.07288 3.55433 4.20897 3.84674 2.70945 4.90527C1.9555 5.4375 2.02298 6.4631 2.58364 7.0316L5.37011 9.85703L1.14538 14.1408C0.951541 14.3374 0.951541 14.656 1.14538 14.8526C1.33921 15.0491 1.65348 15.0491 1.84731 14.8526L6.07205 10.5688L9.05143 13.5898C9.6121 14.1583 10.6236 14.2268 11.1484 13.4623C12.1991 11.932 12.4844 10.0274 12.0066 8.2944L14.5639 5.70141C15.1454 5.11178 15.1454 4.15579 14.5639 3.56616L12.4692 1.44223C11.8877 0.852592 10.9449 0.852592 10.3634 1.44223L7.82871 4.01237C7.81001 4.01578 7.79146 4.02027 7.77317 4.02586ZM11.7673 2.15398C11.5735 1.95743 11.2592 1.95743 11.0654 2.15398L8.68142 4.57125L10.017 5.92555C10.2109 6.1221 10.2109 6.44076 10.017 6.6373C9.82321 6.83385 9.50895 6.83385 9.31511 6.6373L7.77817 5.07887C6.28534 4.57361 4.60736 4.79198 3.27663 5.73137C3.19156 5.79142 3.14872 5.87829 3.14587 5.98261C3.14287 6.09284 3.18676 6.21964 3.28558 6.31985L9.75337 12.8781C9.85219 12.9783 9.97725 13.0228 10.086 13.0197C10.1888 13.0169 10.2745 12.9734 10.3337 12.8872C11.2622 11.5349 11.4765 9.82908 10.974 8.31294C10.914 8.13182 10.9603 7.93186 11.0935 7.79676L13.8619 4.98966C14.0558 4.79311 14.0558 4.47445 13.8619 4.27791L11.7673 2.15398Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgPin.propTypes = iconPropTypes;
