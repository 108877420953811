import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLForms2 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M0.503418 2C0.227276 2 0.00341797 2.22386 0.00341797 2.5V4.5C0.00341797 4.77614 0.227276 5 0.503418 5H6.49985C6.776 5 6.99985 4.77614 6.99985 4.5V2.5C6.99985 2.22386 6.776 2 6.49985 2H0.503418ZM1.00342 4V3H5.99985V4H1.00342Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M6.50146 10.0195H.501465C.225322 10.0195.00146484 10.2434.00146484 10.5195V11.5195C.00146484 11.7957.225323 12.0195.501465 12.0195H6.50146C6.77761 12.0195 7.00146 11.7957 7.00146 11.5195V10.5195C7.00146 10.2434 6.77761 10.0195 6.50146 10.0195ZM15.5 12H9.5C9.22386 12 9 12.2239 9 12.5V13.5C9 13.7761 9.22386 14 9.5 14H15.5C15.7761 14 16 13.7761 16 13.5V12.5C16 12.2239 15.7761 12 15.5 12Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M9 2.5C9 2.22386 9.22386 2 9.5 2H15.4965C15.7727 2 15.9965 2.22386 15.9965 2.5V4.5C15.9965 4.77614 15.7727 5 15.4965 5H9.5C9.22386 5 9 4.77614 9 4.5V2.5ZM10 3V4H14.9965V3H10Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M.503418 6C.227276 6 .00341797 6.22386.00341797 6.5.00341797 6.77614.227276 7 .503418 7H6.49985C6.776 7 6.99985 6.77614 6.99985 6.5 6.99985 6.22386 6.776 6 6.49985 6H.503418ZM9 6.5C9 6.22386 9.22386 6 9.5 6H15.4965C15.7727 6 15.9965 6.22386 15.9965 6.5 15.9965 6.77614 15.7727 7 15.4965 7H9.5C9.22386 7 9 6.77614 9 6.5ZM.503418 8C.227276 8 .00341797 8.22386.00341797 8.5.00341797 8.77614.227276 9 .503418 9H6.49985C6.776 9 6.99985 8.77614 6.99985 8.5 6.99985 8.22386 6.776 8 6.49985 8H.503418ZM9 8.5C9 8.22386 9.22386 8 9.5 8H15.4965C15.7727 8 15.9965 8.22386 15.9965 8.5 15.9965 8.77614 15.7727 9 15.4965 9H9.5C9.22386 9 9 8.77614 9 8.5ZM9.5 10C9.22386 10 9 10.2239 9 10.5 9 10.7761 9.22386 11 9.5 11H15.4965C15.7727 11 15.9965 10.7761 15.9965 10.5 15.9965 10.2239 15.7727 10 15.4965 10H9.5Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLForms2.propTypes = iconPropTypes;
