import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgCloud = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path
            fillRule="evenodd"
            d="M2.33264 5.54348C2.57214 2.99442 4.78117 1 7.42649 1C9.66412 1 11.5927 2.38592 12.2749 4.3883C12.3952 4.3771 12.5039 4.37723 12.5937 4.37734L12.6188 4.37736C14.4983 4.37736 16.0034 5.82781 16.0034 7.69217C16.0034 9.55653 14.4983 11.007 12.6188 11.007H2.81107C1.27779 11.007 0.00341797 9.78169 0.00341797 8.25506C0.00341797 6.89053 1.02153 5.76674 2.33264 5.54348ZM7.42649 2.0007C5.15205 2.0007 3.31101 3.81502 3.31101 6.00349C3.31101 6.27983 3.08718 6.50384 2.81107 6.50384C1.80587 6.50384 1.0033 7.30496 1.0033 8.25506C1.0033 9.20516 1.80587 10.0063 2.81107 10.0063H12.6188C13.9702 10.0063 15.0035 8.98001 15.0035 7.69217C15.0035 6.40433 13.9702 5.37805 12.6188 5.37805C12.3754 5.37805 12.2582 5.38512 12.1456 5.44005C12.0084 5.50696 11.8482 5.50752 11.7106 5.44157C11.573 5.37562 11.473 5.25038 11.4391 5.10149C11.0315 3.31179 9.39494 2.0007 7.42649 2.0007Z"
            clipRule="evenodd"
          />
          <path d="M2.00269 12.508C2.00269 12.2317 2.22652 12.0077 2.50263 12.0077H5.50227C5.77837 12.0077 6.00221 12.2317 6.00221 12.508 6.00221 12.7844 5.77837 13.0084 5.50227 13.0084H2.50263C2.22652 13.0084 2.00269 12.7844 2.00269 12.508ZM7.50447 13.9993C7.22836 13.9993 7.00453 14.2233 7.00453 14.4997 7.00453 14.776 7.22836 15 7.50447 15H12.5039C12.78 15 13.0038 14.776 13.0038 14.4997 13.0038 14.2233 12.78 13.9993 12.5039 13.9993H7.50447Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgCloud.propTypes = iconPropTypes;
