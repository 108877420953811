import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgBrandTwitter = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M11.3042 4.50317C11.4033 4.50317 11.6025 4.50317 11.8018 4.60327C11.9014 4.70312 12.001 4.80322 12.001 5.00317C12.001 5.60327 11.6025 6.20312 11.3042 6.50317C11.3042 7.80322 10.7065 9.20312 9.81055 10.2031C9.01367 11.1033 8.01807 11.5032 6.92285 11.5032C5.82764 11.5032 4.83203 11.0032 4.33398 10.8032C4.19678 10.6653 4.10645 10.5273 4.09668 10.3567C4.09229 10.2798 4.104 10.1963 4.13477 10.1033C4.13477 9.9917 4.22754 9.91138 4.34424 9.86206C4.43701 9.82275 4.54443 9.80322 4.63281 9.80322C5.03125 9.90308 5.4292 9.50317 5.72803 9.20312C5.62842 9.00317 5.4292 8.90308 5.32959 8.70312C4.5332 7.90308 3.83643 6.40308 4.03516 4.90308C4.13477 4.70312 4.23438 4.60327 4.43359 4.50317C4.63281 4.50317 4.83203 4.50317 4.93164 4.70312C5.24609 5.08276 5.80029 5.46216 6.4165 5.74048C6.7749 5.9021 7.1543 6.02979 7.52051 6.10327L7.52197 6.09888C7.62109 5.80029 7.72021 5.50171 7.91846 5.20312C8.51611 4.50317 9.71094 4.30322 10.5073 4.90308C10.5674 4.87305 10.6367 4.84277 10.7124 4.80981C10.8872 4.73364 11.0957 4.64282 11.3042 4.50317ZM9.11328 9.50317C10.0098 8.60327 10.3081 7.20312 10.3081 6.30322C10.3081 6.20312 10.4077 6.00317 10.5073 5.90308C10.4077 5.90308 10.2085 5.90308 10.1094 5.80322C9.71094 5.40308 9.01367 5.50317 8.71533 5.80322C8.51611 6.00317 8.4165 6.20312 8.51611 6.50317C8.57031 6.61157 8.56543 6.71997 8.53369 6.8125C8.50732 6.89062 8.46191 6.95728 8.4165 7.00317C8.31689 7.10327 8.21729 7.20312 8.01807 7.20312C7.12207 7.10327 6.02686 6.80322 5.13086 6.20312C5.4292 7.50317 6.4248 8.60327 6.72363 8.70312C6.92285 8.70312 7.12207 8.80322 7.12207 9.00317C7.22168 9.30322 7.12207 9.50317 6.92285 9.60327C6.82324 9.70312 6.62402 9.80322 6.52441 9.90308C6.4248 10.0034 6.32471 10.0786 6.22461 10.1538C6.12549 10.2285 6.02637 10.3035 5.92725 10.4031C7.22168 10.7031 8.21729 10.4031 9.11328 9.50317Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3.07617 1.00244C1.93311 1.00244 1 1.93555 1 3.07837V12.928C1 14.0708 1.93311 15.0039 3.07617 15.0039H12.9258C14.0684 15.0039 15.0015 14.0708 15.0015 12.928V3.07837C15.0015 1.93555 14.0684 1.00244 12.9258 1.00244H3.07617ZM2 3.07837C2 2.48779 2.48535 2.00244 3.07617 2.00244H12.9258C13.5161 2.00244 14.0015 2.48779 14.0015 3.07837V12.928C14.0015 13.5186 13.5161 14.0039 12.9258 14.0039H3.07617C2.48535 14.0039 2 13.5186 2 12.928V3.07837Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgBrandTwitter.propTypes = iconPropTypes;
