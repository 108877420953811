import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgSubscript = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1 1.5C1 1.22386 1.22386 1 1.5 1H9.5C9.77614 1 10 1.22386 10 1.5 10 1.77614 9.77614 2 9.5 2H6V11.5C6 11.7761 5.77614 12 5.5 12 5.22386 12 5 11.7761 5 11.5V2H1.5C1.22386 2 1 1.77614 1 1.5ZM11.3517 14H15V15H10V13.8415L13.1475 11.1436 13.1693 11.118C13.1981 11.084 13.24 11.0333 13.2902 10.9691 13.3911 10.8399 13.5225 10.6601 13.647 10.4552 13.7726 10.2486 13.8835 10.0288 13.952 9.81888 14.0217 9.605 14.0356 9.43724 14.0104 9.31599 13.8423 8.50628 13.2591 7.99521 12.5032 8.00002 12.1542 8.00225 11.7638 8.1477 11.4625 8.39247 11.161 8.63744 11.0018 8.93509 11 9.2175L10 9.21113C10.0042 8.54679 10.3717 7.99031 10.8319 7.61634 11.2925 7.24216 11.8991 7.00385 12.4968 7.00004 13.7983 6.99175 14.7394 7.90794 14.9896 9.11265 15.0634 9.46837 15.0019 9.8248 14.9027 10.1289 14.8022 10.437 14.6508 10.729 14.5016 10.9745 14.3513 11.2219 14.1956 11.4346 14.0781 11.5849 14.019 11.6605 13.9689 11.7212 13.9329 11.7638 13.9148 11.7851 13.9003 11.8019 13.8899 11.8138L13.8775 11.8279 13.8737 11.8322 13.8725 11.8335C13.8724 11.8336 13.8717 11.8344 13.5 11.5L13.8717 11.8344 13.85 11.8585 11.3517 14Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgSubscript.propTypes = iconPropTypes;
