import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgPlusCircle = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M8.49756 11.9741C8.22142 11.9741 7.99756 11.7502 7.99756 11.4741V9.00195H5.47406C5.19792 9.00195 4.97406 8.7781 4.97406 8.50195C4.97406 8.22581 5.19792 8.00195 5.47406 8.00195H7.99756V5.47406C7.99756 5.19792 8.22142 4.97406 8.49756 4.97406C8.7737 4.97406 8.99756 5.19792 8.99756 5.47406V8.00195H11.4741C11.7502 8.00195 11.9741 8.22581 11.9741 8.50195C11.9741 8.7781 11.7502 9.00195 11.4741 9.00195H8.99756V11.4741C8.99756 11.7502 8.7737 11.9741 8.49756 11.9741Z" />
          <path
            fillRule="evenodd"
            d="M16 8.5C16 12.6421 12.6421 16 8.5 16C4.35786 16 1 12.6421 1 8.5C1 4.35786 4.35786 1 8.5 1C12.6421 1 16 4.35786 16 8.5ZM15 8.5C15 12.0899 12.0899 15 8.5 15C4.91014 15 2 12.0899 2 8.5C2 4.91014 4.91014 2 8.5 2C12.0899 2 15 4.91014 15 8.5Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgPlusCircle.propTypes = iconPropTypes;
