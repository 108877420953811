import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgBubbleWithLines = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M4 4.5C4 4.22386 4.22386 4 4.5 4H8.5C8.77614 4 9 4.22386 9 4.5 9 4.77614 8.77614 5 8.5 5H4.5C4.22386 5 4 4.77614 4 4.5ZM4.5 7C4.22386 7 4 7.22386 4 7.5 4 7.77614 4.22386 8 4.5 8H10.5005C10.7766 8 11.0005 7.77614 11.0005 7.5 11.0005 7.22386 10.7766 7 10.5005 7H4.5Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M2.46763e-05 2.31818C2.46763e-05 1.04204 1.04206 0 2.3182 0H13.6818C14.958 0 16 1.04204 16 2.31818V10.6947C16 11.9739 14.9549 13.0044 13.6818 13.0044H8.18465L4.82276 15.88C4.67444 16.0068 4.46587 16.0358 4.28859 15.9541C4.11132 15.8725 3.99776 15.6952 3.99776 15.5V13.0044H2.31818C1.0451 13.0044 0 11.9739 0 10.6947L2.46763e-05 2.31818ZM2.3182 1C1.59435 1 1.00002 1.59432 1.00002 2.31818L1 10.6947C1 11.4155 1.59126 12.0044 2.31818 12.0044H4.49776C4.7739 12.0044 4.99776 12.2283 4.99776 12.5044V14.4144L7.67499 12.1244C7.76555 12.047 7.88081 12.0044 7.99999 12.0044H13.6818C14.4087 12.0044 15 11.4155 15 10.6947V2.31818C15 1.59432 14.4057 1 13.6818 1H2.3182Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgBubbleWithLines.propTypes = iconPropTypes;
