import { UnreachableCaseException } from '@kontent-ai/errors';
import styled, { css } from 'styled-components';
import {
  colorAlertText,
  colorPrimary,
  colorPrimaryHover,
  colorTextDefault,
  colorTextDisabled,
} from '../../../tokens/decision/colors.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { transition250 } from '../../../tokens/quarks/transitions.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { getButtonIconSize, getDestructiveIconHoverColor } from '../ButtonIcon.tsx';
import { colorTextOnDark } from '../decisionTokens.ts';
import {
  buttonIconColorVariableName,
  buttonIconHoverColorVariableName,
  buttonIconSizeVariableName,
} from '../utils/cssVariableNames.ts';
import { getIconColor } from '../utils/stylingUtils.ts';
import { IBaseButtonComponentProps, IInjectedProps } from './BaseButtonComponent.tsx';
import { StyledButton } from './StyledButton.tsx';

type StyledButtonContentProps = Pick<
  IBaseButtonComponentProps,
  'buttonStyle' | 'disabled' | 'destructive'
> &
  Pick<IInjectedProps, '$size' | '$activated'>;

const getDestructiveHoverStyles = (disabled?: boolean) => css`
  ${StyledButton}:hover &&,
  ${StyledButton}:active && {
    color: ${disabled ? colorTextDisabled : colorTextOnDark};
  }
`;

const getTextColor = ({ buttonStyle, disabled }: StyledButtonContentProps) => {
  if (disabled) {
    switch (buttonStyle) {
      case 'primary':
      case 'secondary-inverse':
      case 'primary-inverse':
      case 'secondary':
      case 'tertiary':
        return colorTextDisabled;
      default:
        throw UnreachableCaseException(buttonStyle);
    }
  }

  switch (buttonStyle) {
    case 'primary':
    case 'secondary-inverse':
      return colorTextOnDark;
    case 'primary-inverse':
      return colorPrimary;
    case 'secondary':
    case 'tertiary':
      return colorTextDefault;
    default:
      throw UnreachableCaseException(buttonStyle);
  }
};

const getDestructiveTextColor = ({
  buttonStyle,
  disabled,
  $activated,
}: StyledButtonContentProps) => {
  if (disabled) {
    return colorTextDisabled;
  }
  switch (buttonStyle) {
    case 'primary':
    case 'primary-inverse':
      return colorTextOnDark;
    case 'secondary':
    case 'secondary-inverse':
    case 'tertiary':
      return $activated ? colorTextOnDark : colorAlertText;
    default:
      throw UnreachableCaseException(buttonStyle);
  }
};

const extraColorStyles = ({ buttonStyle, disabled, $activated }: StyledButtonContentProps) => {
  if (disabled) {
    return;
  }
  switch (buttonStyle) {
    case 'primary-inverse':
      return css`
        ${StyledButton}:hover &&,
        ${StyledButton}:active && {
          color: ${colorPrimaryHover};
        }
        
        ${$activated ? css`color: ${colorPrimaryHover};` : undefined}
      `;
    default:
      return;
  }
};

export const StyledButtonContent = styled.span<StyledButtonContentProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${px(Spacing.S)};
  height: 100%;
  width: 100%;

  color: ${({ destructive }) => (destructive ? getDestructiveTextColor : getTextColor)};
  ${extraColorStyles};

  transition: color ${transition250};
  ${({ destructive, disabled }) => (destructive ? getDestructiveHoverStyles(disabled) : '')};
  ${buttonIconSizeVariableName}: ${({ $size }) => getButtonIconSize($size)};
  ${buttonIconColorVariableName}: ${({ disabled, buttonStyle, destructive, $activated }) =>
    getIconColor(buttonStyle, disabled, destructive, $activated)};
  ${buttonIconHoverColorVariableName}: ${({ disabled, destructive }) =>
    getDestructiveIconHoverColor(disabled, destructive)};
`;
