import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgForm = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
          <path d="M.0185547.500657C.0185547.224152.242412 0 .518555 0H6.51855C6.7947 0 7.01855.224152 7.01855.500657V3.50051C7.01855 3.77702 6.7947 4.00117 6.51855 4.00117H.518555C.242412 4.00117.0185547 3.77702.0185547 3.50051V.500657ZM1.01855 1.00131V2.99985H6.01855V1.00131H1.01855ZM15.4961 4.9919.496094 4.99193C.219951 4.99193-.00390625 5.21608-.00390625 5.49259V9.49781C-.00390625 9.77432.219951 9.99847.496094 9.99847H15.4961C15.7722 9.99847 15.9961 9.77432 15.9961 9.49781V5.49256C15.9961 5.35978 15.9434 5.23243 15.8496 5.13854 15.7559 5.04465 15.6287 4.9919 15.4961 4.9919ZM.996094 8.99716V5.99325L14.9961 5.99322V8.99716H.996094ZM.518555 10.9934C.242412 10.9934.0185547 11.2176.0185547 11.4941V15.4993C.0185547 15.7758.242412 16 .518555 16H9.51855C9.7947 16 10.0186 15.7758 10.0186 15.4993V11.4941C10.0186 11.2176 9.7947 10.9934 9.51855 10.9934H.518555ZM1.01855 14.9987V11.9947H9.01855V14.9987H1.01855ZM8.51855 0C8.24241 0 8.01855.224152 8.01855.500657V3.50051C8.01855 3.77702 8.24241 4.00117 8.51855 4.00117H14.5186C14.7947 4.00117 15.0186 3.77702 15.0186 3.50051V.500657C15.0186.224152 14.7947 0 14.5186 0H8.51855ZM9.01855 2.99985V1.00131H14.0186V2.99985H9.01855Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgForm.propTypes = iconPropTypes;
