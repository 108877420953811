import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgI = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M5.99542 1.5C5.99542 1.22386 6.21928 1 6.49542 1H13.4997C13.7758 1 13.9997 1.22386 13.9997 1.5C13.9997 1.77614 13.7758 2 13.4997 2L10.3861 2L6.65012 13.9951H9.50427C9.78042 13.9951 10.0043 14.219 10.0043 14.4951C10.0043 14.7713 9.78042 14.9951 9.50427 14.9951L2.5 14.9951C2.22386 14.9951 2 14.7713 2 14.4951C2 14.219 2.22386 13.9951 2.5 13.9951H5.60274L9.33868 2L6.49542 2C6.21928 2 5.99542 1.77614 5.99542 1.5Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgI.propTypes = iconPropTypes;
