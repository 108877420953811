import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgCalendarNumber = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M4.9961 8.49365C4.9961 8.21751 5.21974 7.99365 5.49561 7.99365H6.4946C6.77047 7.99365 6.99411 8.21751 6.99411 8.49365V11.4935C6.99411 11.7696 6.77047 11.9935 6.4946 11.9935C6.21873 11.9935 5.99509 11.7696 5.99509 11.4935V8.99365H5.49561C5.21974 8.99365 4.9961 8.76979 4.9961 8.49365Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M10.9902 9.99365C10.9902 9.54685 10.8749 9.07872 10.6471 8.70636C10.4197 8.33479 10.0321 7.99365 9.49165 7.99365C8.95116 7.99365 8.56361 8.33478 8.33628 8.70636C8.10846 9.07872 7.99316 9.54683 7.99316 9.99361C7.99316 10.4404 8.10846 10.9085 8.33627 11.2809C8.56359 11.6525 8.95114 11.9937 9.49165 11.9937C10.0321 11.9937 10.4197 11.6525 10.6471 11.2809C10.8749 10.9086 10.9902 10.4405 10.9902 9.99365ZM9.49165 8.99365C9.56425 8.99365 9.67622 9.03433 9.79513 9.22866C9.91355 9.4222 9.99121 9.70407 9.99121 9.99365C9.99121 10.2832 9.91355 10.5651 9.79513 10.7586C9.67622 10.953 9.56425 10.9937 9.49165 10.9937C9.41907 10.9937 9.30713 10.953 9.18824 10.7587C9.06983 10.5651 8.99219 10.2832 8.99219 9.99361C8.99219 9.70401 9.06983 9.42214 9.18823 9.22862C9.30711 9.03431 9.41905 8.99365 9.49165 8.99365Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M5.03317 0.5C5.03317 0.223858 4.80953 0 4.53366 0C4.25779 0 4.03415 0.223858 4.03415 0.5V1.00016L3.28417 1.00017C2.66607 1.00017 2.09884 1.347 1.70438 1.78132C1.30628 2.21966 1.01366 2.82223 1.01366 3.45015V5.38265C1.00473 5.42007 1 5.45911 1 5.49927C1 5.53942 1.00473 5.57847 1.01366 5.61588V12.55C1.01366 13.1779 1.30628 13.7805 1.70438 14.2188C2.09884 14.6532 2.66606 15 3.28417 15H12.7295C13.3476 15 13.9148 14.6532 14.3093 14.2188C14.7074 13.7805 15 13.1779 15 12.55V3.44997C15 2.82206 14.7074 2.21949 14.3093 1.78115C13.9148 1.34683 13.3476 1 12.7295 1L12.0156 1.00001V0.5C12.0156 0.223858 11.792 0 11.5161 0C11.2402 0 11.0166 0.223858 11.0166 0.5V1.00003L5.03317 1.00014V0.5ZM2.01268 5.99927V12.55C2.01268 12.8754 2.17265 13.2478 2.44359 13.5462C2.71818 13.8485 3.03645 14 3.28417 14H12.7295C12.9772 14 13.2955 13.8485 13.5701 13.5462C13.841 13.2478 14.001 12.8754 14.001 12.55V5.99927L2.01268 5.99927ZM14.001 4.99927V3.44997C14.001 3.12457 13.841 2.75215 13.5701 2.45383C13.2955 2.1515 12.9772 2 12.7295 2L3.28418 2.00017C3.03646 2.00017 2.71818 2.15167 2.44359 2.454C2.17265 2.75233 2.01268 3.12474 2.01268 3.45015V4.99927L14.001 4.99927Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgCalendarNumber.propTypes = iconPropTypes;
