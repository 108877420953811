import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgFace = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M7 6C7 7.10457 6.55228 8 6 8 5.44772 8 5 7.10457 5 6 5 4.89543 5.44772 4 6 4 6.55228 4 7 4.89543 7 6ZM11 6C11 7.10457 10.5523 8 10 8 9.44771 8 9 7.10457 9 6 9 4.89543 9.44771 4 10 4 10.5523 4 11 4.89543 11 6ZM4.38383 9.87977C4.20687 9.66779 3.89156 9.6394 3.67958 9.81636 3.46759 9.99333 3.4392 10.3086 3.61617 10.5206 4.62653 11.7309 6.22113 12.4998 8 12.4998 9.77887 12.4998 11.3735 11.7309 12.3838 10.5206 12.5608 10.3086 12.5324 9.99333 12.3204 9.81636 12.1084 9.6394 11.7931 9.66779 11.6162 9.87977 10.8021 10.855 9.49233 11.4998 8 11.4998 6.50767 11.4998 5.19792 10.855 4.38383 9.87977Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgFace.propTypes = iconPropTypes;
