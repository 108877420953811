import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLaptop = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M1 0.5C1 0.223858 1.22386 0 1.5 0H14.5C14.7761 0 15 0.223858 15 0.5V10.4505L15.9903 15.4019C16.0197 15.5488 15.9816 15.7012 15.8867 15.817C15.7917 15.9328 15.6498 16 15.5 16H0.500004C0.350208 16 0.208303 15.9328 0.113333 15.817C0.0183639 15.7012 -0.0196636 15.5488 0.00971396 15.4019L1 10.4505V0.5ZM5.64039 15H10.3596L10.1096 14H5.89039L5.64039 15ZM11.3904 15L10.9851 13.3787C10.9294 13.1561 10.7294 13 10.5 13H5.5C5.27057 13 5.07058 13.1561 5.01493 13.3787L4.60962 15H1.10991L1.90991 11H14.0901L14.8901 15H11.3904ZM2 1V10H14V1H2Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLaptop.propTypes = iconPropTypes;
