import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgChecklist = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M7.27537 1.85317C7.47041 1.65799 7.47041 1.34155 7.27537 1.14638 7.08033.951207 6.76411.951207 6.56907 1.14638L3.53297 4.18457 1.85258 2.50301C1.65754 2.30784 1.34132 2.30784 1.14628 2.50301.95124 2.69819.95124 3.01462 1.14628 3.2098L3.17982 5.24475C3.37486 5.43992 3.69108 5.43992 3.88612 5.24475L7.27537 1.85317ZM8.01005 3.47886C8.01005 3.20284 8.23366 2.97908 8.50948 2.97908L14.5005 2.97908C14.7764 2.97908 15 3.20284 15 3.47886 15 3.75487 14.7764 3.97863 14.5005 3.97863L8.50948 3.97863C8.23366 3.97863 8.01005 3.75487 8.01005 3.47886ZM8.00908 12.4699C8.00908 12.1939 8.23268 11.9701 8.50851 11.9701L14.5006 11.9701C14.7764 11.9701 15 12.1939 15 12.4699 15 12.7459 14.7764 12.9697 14.5006 12.9697L8.50851 12.9697C8.23268 12.9697 8.00908 12.7459 8.00908 12.4699ZM1.85258 9.1491C1.65754 8.95393 1.34132 8.95393 1.14628 9.1491.95124 9.34427.95124 9.66071 1.14628 9.85589L3.29028 12.0014 1.14628 14.1468C.95124 14.342.95124 14.6584 1.14628 14.8536 1.34132 15.0488 1.65754 15.0488 1.85258 14.8536L3.99657 12.7081 6.14057 14.8536C6.33561 15.0488 6.65183 15.0488 6.84687 14.8536 7.04191 14.6584 7.04191 14.342 6.84687 14.1468L4.70287 12.0014 6.84687 9.85589C7.04191 9.66071 7.04191 9.34427 6.84687 9.1491 6.65183 8.95393 6.33561 8.95393 6.14057 9.1491L3.99657 11.2946 1.85258 9.1491Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgChecklist.propTypes = iconPropTypes;
