import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgCancel = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1.14596 14.1468C0.950696 14.342 0.950696 14.6586 1.14596 14.8539C1.34122 15.0491 1.6578 15.0491 1.85306 14.8539L7.9997 8.70725L14.147 14.8546C14.3423 15.0498 14.6589 15.0498 14.8542 14.8546C15.0494 14.6593 15.0494 14.3427 14.8542 14.1475L8.70681 8.00015L14.8542 1.85282C15.0494 1.65756 15.0494 1.34098 14.8542 1.14571C14.6589 0.950452 14.3423 0.950452 14.147 1.14571L7.9997 7.29304L1.85306 1.14642C1.6578 0.951161 1.34122 0.951162 1.14596 1.14642C0.950696 1.34169 0.950696 1.65827 1.14596 1.85353L7.29259 8.00015L1.14596 14.1468Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgCancel.propTypes = iconPropTypes;
