import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgMicrophone = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M2.5 6.0054C2.77614 6.0054 3 6.2294 3 6.50573C3 8.81357 5.18556 11.0087 8.00008 11.0087C10.8146 11.0087 13 8.81359 13 6.50573C13 6.2294 13.2239 6.0054 13.5 6.0054C13.7761 6.0054 14 6.2294 14 6.50573C14 8.82012 12.3113 10.9141 9.97559 11.6903V13.9993H11.5019C11.778 13.9993 12.0019 14.2233 12.0019 14.4997C12.0019 14.776 11.778 15 11.5019 15H4.49831C4.22217 15 3.99831 14.776 3.99831 14.4997C3.99831 14.2233 4.22217 13.9993 4.49831 13.9993H6.00781V11.6847C3.68078 10.9041 2 8.81459 2 6.50573C2 6.2294 2.22386 6.0054 2.5 6.0054ZM7.00781 11.9306V13.9993H8.97559V11.9333C8.65787 11.9832 8.33198 12.0093 8.00008 12.0093C7.66234 12.0093 7.33083 11.9823 7.00781 11.9306Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.5 4.35859C4.5 2.45879 6.06726 1 8 1C9.93275 1 11.5 2.45879 11.5 4.35859V6.6452C11.5 8.545 9.93275 10.0038 8 10.0038C6.06726 10.0038 4.5 8.545 4.5 6.6452V4.35859ZM8 2.00066C6.57274 2.00066 5.5 3.05713 5.5 4.35859V6.6452C5.5 7.94666 6.57275 9.00313 8 9.00313C9.42726 9.00313 10.5 7.94666 10.5 6.6452V4.35859C10.5 3.05713 9.42726 2.00066 8 2.00066Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgMicrophone.propTypes = iconPropTypes;
