import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLArticleMap = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M11.4999 7C12.3283 7 12.9999 6.32843 12.9999 5.5C12.9999 4.67157 12.3283 4 11.4999 4C10.6715 4 9.99988 4.67157 9.99988 5.5C9.99988 6.32843 10.6715 7 11.4999 7ZM10.9999 5.5C10.9999 5.22386 11.2237 5 11.4999 5C11.776 5 11.9999 5.22386 11.9999 5.5C11.9999 5.77614 11.776 6 11.4999 6C11.2237 6 10.9999 5.77614 10.9999 5.5Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M11.4999 10.5C11.2275 10.9193 11.2273 10.9192 11.2272 10.9191L11.2257 10.9181L11.2228 10.9162L11.2138 10.9102L11.1833 10.8896C11.1577 10.8721 11.1217 10.8468 11.0768 10.8141C10.9869 10.7488 10.8611 10.6532 10.7114 10.5286C10.4124 10.2799 10.0146 9.91277 9.61622 9.43602C8.82247 8.48623 7.99988 7.06856 7.99988 5.26923C7.99988 4.30606 8.34051 3.47434 8.97739 2.88645C9.6102 2.30231 10.4903 2 11.4999 2C12.5094 2 13.3895 2.30231 14.0223 2.88644C14.6592 3.47434 14.9999 4.30605 14.9999 5.26923C14.9999 7.06856 14.1773 8.48623 13.3835 9.43602C12.9851 9.91277 12.5873 10.2799 12.2884 10.5286C12.1386 10.6532 12.0128 10.7488 11.923 10.8141C11.878 10.8468 11.842 10.8721 11.8164 10.8896L11.786 10.9102L11.777 10.9162L11.7741 10.9181L11.773 10.9188C11.7728 10.9189 11.7722 10.9193 11.4999 10.5ZM9.65568 3.62125C9.24812 3.99745 8.99988 4.55035 8.99988 5.26923C8.99988 6.75195 9.67729 7.94966 10.3835 8.79475C10.7351 9.21544 11.0873 9.54059 11.3509 9.75985C11.4046 9.80457 11.4546 9.84476 11.4999 9.88035C11.5452 9.84476 11.5951 9.80457 11.6489 9.75985C11.9124 9.54059 12.2646 9.21544 12.6162 8.79475C13.3225 7.94966 13.9999 6.75195 13.9999 5.26923C13.9999 4.55036 13.7516 3.99746 13.3441 3.62125C12.9324 3.24128 12.3125 3 11.4999 3C10.6872 3 10.0673 3.24128 9.65568 3.62125Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M11.4999 10.5 11.773 10.9188C11.6074 11.0264 11.3928 11.0267 11.2272 10.9191L11.4999 10.5ZM5.5 2.00879H.5C.223858 2.00879 0 2.23265 0 2.50879V3.50879C0 3.78493.223858 4.00879.5 4.00879H5.5C5.77614 4.00879 6 3.78493 6 3.50879V2.50879C6 2.23265 5.77614 2.00879 5.5 2.00879ZM.0112305 6.49545C.0112305 6.2193.235088 5.99545.51123 5.99545H6.51123C6.78737 5.99545 7.01123 6.2193 7.01123 6.49545 7.01123 6.77159 6.78737 6.99545 6.51123 6.99545H.51123C.235088 6.99545.0112305 6.77159.0112305 6.49545ZM.51123 8.0013C.235088 8.0013.0112305 8.22516.0112305 8.5013.0112305 8.77745.235088 9.0013.51123 9.0013H6.51123C6.78737 9.0013 7.01123 8.77745 7.01123 8.5013 7.01123 8.22516 6.78737 8.0013 6.51123 8.0013H.51123ZM.0110861 10.5075C.0110861 10.2313.234944 10.0075.511086 10.0075H6.51109C6.78723 10.0075 7.01109 10.2313 7.01109 10.5075 7.01109 10.7836 6.78723 11.0075 6.51109 11.0075H.511086C.234944 11.0075.0110861 10.7836.0110861 10.5075ZM.511086 11.9991C.234944 11.9991.0110861 12.223.0110861 12.4991.0110861 12.7752.234944 12.9991.511086 12.9991H6.51109C6.78723 12.9991 7.01109 12.7752 7.01109 12.4991 7.01109 12.223 6.78723 11.9991 6.51109 11.9991H.511086Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLArticleMap.propTypes = iconPropTypes;
