import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLCols8020 = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M15.5001 14C15.7762 14 16 13.7761 16 13.5V2.5C16 2.22386 15.7762 2 15.5001 2H13.4997C13.2236 2 12.9998 2.22386 12.9998 2.5L12.9998 13.5C12.9998 13.7761 13.2236 14 13.4997 14H15.5001ZM15.0002 3 15.0002 13H13.9996L13.9996 3 15.0002 3ZM11.4934 14C11.7695 14 11.9933 13.7761 11.9933 13.5L11.9933 2.5C11.9933 2.22386 11.7695 2 11.4934 2H.49992C.223823 2 .00000190704 2.22386.00000190704 2.5L0 13.5C0 13.7761.223822 14 .49992 14L11.4934 14ZM10.9935 3 10.9935 13 .999838 13 .999839 3 10.9935 3Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLCols8020.propTypes = iconPropTypes;
