import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgU = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M4.01255 1.49955C4.01255 1.22365 3.78869 1 3.51255 1 3.2364 1 3.01255 1.22365 3.01255 1.49955V7.96427C3.01255 10.7364 5.24806 12.9891 8.01255 12.9891 10.777 12.9891 13.0125 10.7364 13.0125 7.96427V1.49955C13.0125 1.22365 12.7887 1 12.5125 1 12.2364 1 12.0125 1.22365 12.0125 1.49955V7.96427C12.0125 10.1907 10.2186 11.99 8.01255 11.99 5.80647 11.99 4.01255 10.1907 4.01255 7.96427V1.49955ZM2.5 14.0009C2.22386 14.0009 2 14.2246 2 14.5005 2 14.7763 2.22386 15 2.5 15L13.5 15C13.7761 15 14 14.7763 14 14.5005 14 14.2246 13.7761 14.0009 13.5 14.0009L2.5 14.0009Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgU.propTypes = iconPropTypes;
