import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgNotebook = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M8.1593 0.146437C8.25307 0.0526687 8.38025 -6.37706e-06 8.51287 5.82071e-10L11.4977 0.000144094C11.7738 0.000157415 11.9977 0.224009 11.9977 0.50014V0.99841H13.499C13.7752 0.99841 14 1.22227 14 1.49841V15.5C14 15.7761 13.7761 16 13.5 16H2.5C2.22386 16 2 15.7761 2 15.5V1.49841C2 1.22227 2.22385 0.998417 2.49999 0.99841L8.01284 0.998278V0.499995C8.01284 0.367384 8.06552 0.240205 8.1593 0.146437ZM8.01284 1.99827L3 1.99839V15H13V1.9984H11.9979L11.9977 6.26932C11.9977 6.46028 11.8889 6.63457 11.7173 6.71847C11.5458 6.80237 11.3414 6.78123 11.1907 6.66399L10.0123 5.74748L8.8175 6.66563C8.6665 6.78167 8.46269 6.80185 8.29186 6.71768C8.12103 6.63352 8.01284 6.45961 8.01284 6.26917V1.99827ZM9.01284 1.00002V5.25438L9.70951 4.71903C9.89002 4.58031 10.1414 4.58105 10.3211 4.72081L10.9977 5.24699V1.00011L9.01284 1.00002Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgNotebook.propTypes = iconPropTypes;
