import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgGraduateCap = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.78547 1.04538C7.91944 0.98292 8.07422 0.98292 8.20819 1.04538L15.6624 4.52063C15.8588 4.58791 16 4.77402 16 4.99307V10.524C16 10.7997 15.7762 11.0233 15.5002 11.0233C15.2242 11.0233 15.0004 10.7997 15.0004 10.524V5.77413L12.9883 6.71219C12.9941 6.74245 12.9971 6.77368 12.9971 6.8056V10.5245C12.9971 11.5665 12.3584 12.4472 11.4554 13.0443C10.5486 13.6439 9.32496 13.9985 7.99847 13.9985C6.67198 13.9985 5.44831 13.6439 4.54149 13.0443C3.63851 12.4472 2.99988 11.5665 2.99988 10.5245V6.8056C2.99988 6.77417 3.00279 6.74342 3.00835 6.7136L0.288443 5.44554C0.112475 5.3635 0 5.18706 0 4.99307C0 4.79907 0.112475 4.62263 0.288443 4.5406L7.78547 1.04538ZM3.99949 7.17568V10.5245C3.99949 11.1253 4.36795 11.732 5.09321 12.2116C5.81463 12.6886 6.84035 12.9999 7.99847 12.9999C9.15659 12.9999 10.1823 12.6886 10.9037 12.2116C11.629 11.732 11.9975 11.1253 11.9975 10.5245V7.17415L8.20819 8.94075C8.07422 9.00321 7.91944 9.00321 7.78547 8.94075L3.99949 7.17568ZM1.68169 4.99307L7.99683 7.93727L14.312 4.99307L7.99683 2.04886L1.68169 4.99307Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgGraduateCap.propTypes = iconPropTypes;
