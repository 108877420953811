import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgBrandLinkedin = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M6 4.54053C6 4.81667 5.77614 5.04053 5.5 5.04053 5.22386 5.04053 5 4.81667 5 4.54053 5 4.26438 5.22386 4.04053 5.5 4.04053 5.77614 4.04053 6 4.26438 6 4.54053ZM6 6.54053C6 6.26438 5.77614 6.04053 5.5 6.04053 5.22386 6.04053 5 6.26438 5 6.54053V10.5405C5 10.8167 5.22386 11.0405 5.5 11.0405 5.77614 11.0405 6 10.8167 6 10.5405V6.54053ZM7 7.5473V10.5405C7 10.8167 7.22386 11.0405 7.5 11.0405 7.77614 11.0405 8 10.8167 8 10.5405V7.70419C8.40432 7.20803 9.14194 6.94086 9.80428 7.03549 10.1474 7.08451 10.4396 7.22719 10.6449 7.45411 10.8464 7.67687 11 8.02011 11 8.54055V10.5405C11 10.8167 11.2239 11.0405 11.5 11.0405 11.7761 11.0405 12 10.8167 12 10.5405V8.54055C12 7.81098 11.7786 7.21671 11.3864 6.7832 10.9979 6.35386 10.4776 6.12153 9.94572 6.04554 9.29622 5.95274 8.58291 6.0878 7.9877 6.4298 7.93731 6.20694 7.73809 6.04053 7.5 6.04053 7.22386 6.04053 7 6.26438 7 6.54053V7.53274C6.99993 7.5376 6.99993 7.54245 7 7.5473Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.00488 3.08C1.00488 1.93741 1.93765 1.00464 3.08025 1.00464H12.9263C14.0689 1.00464 15.0016 1.93741 15.0016 3.08V12.926C15.0016 14.0686 14.0689 15.0014 12.9263 15.0014H3.08025C1.93765 15.0014 1.00488 14.0686 1.00488 12.926V3.08ZM3.08025 2.00464C2.48994 2.00464 2.00488 2.4897 2.00488 3.08V12.926C2.00488 13.5163 2.48994 14.0014 3.08025 14.0014H12.9263C13.5166 14.0014 14.0016 13.5163 14.0016 12.926V3.08C14.0016 2.4897 13.5166 2.00464 12.9263 2.00464H3.08025Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgBrandLinkedin.propTypes = iconPropTypes;
