import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgCarousel = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <g fill="currentColor" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
          <path d="M4.01292.501221C4.01292.225078 4.23641.0012207 4.5121.0012207H11.5006C11.7763.0012207 11.9998.225078 11.9998.501221V15.5012C11.9998 15.7774 11.7763 16.0012 11.5006 16.0012H4.5121C4.23641 16.0012 4.01292 15.7774 4.01292 15.5012V.501221ZM5.01127 1.00122V15.0012H11.0014V1.00122H5.01127ZM0 2.5C0 2.22386.223489 2 .499177 2H2.49589C2.77158 2 2.99506 2.22386 2.99506 2.5 2.99506 2.77614 2.77158 3 2.49589 3H.998355V13H2.49589C2.77158 13 2.99506 13.2239 2.99506 13.5 2.99506 13.7761 2.77158 14 2.49589 14H.499177C.223489 14 0 13.7761 0 13.5V2.5ZM13.0049 2.52173C13.0049 2.24559 13.2284 2.02173 13.5041 2.02173H15.5008C15.7765 2.02173 16 2.24559 16 2.52173V13.5217C16 13.7979 15.7765 14.0217 15.5008 14.0217H13.5041C13.2284 14.0217 13.0049 13.7979 13.0049 13.5217 13.0049 13.2456 13.2284 13.0217 13.5041 13.0217H15.0016V3.02173H13.5041C13.2284 3.02173 13.0049 2.79787 13.0049 2.52173Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgCarousel.propTypes = iconPropTypes;
