import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgArrowCrookedRight = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M11.6642 4.13176C11.4597 4.32029 11.4443 4.64164 11.6298 4.8495L14.3701 7.99377H1.65385C1.17669 7.99377 0.749046 8.17774 0.445187 8.52183C0.145727 8.86094 0 9.31648 0 9.80851V12.4919C0 12.7725 0.223858 13 0.500001 13C0.776143 13 1 12.7725 1 12.4919V9.80851C1 9.51657 1.08504 9.3188 1.18943 9.2006C1.28942 9.08737 1.43869 9.01002 1.65385 9.01002H14.3701L11.6298 12.0065C11.4443 12.2143 11.4597 12.5357 11.6642 12.7242C11.8688 12.9127 12.185 12.8971 12.3705 12.6892L15.8482 8.79267C15.9418 8.70033 16 8.57107 16 8.428C16 8.28506 15.9419 8.15591 15.8484 8.06358L12.3705 4.16676C12.185 3.95889 11.8688 3.94322 11.6642 4.13176Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgArrowCrookedRight.propTypes = iconPropTypes;
