import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgLListTitle = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M1.50359 1H11.4825C11.7581 1 11.9815 1.2236 11.9815 1.49941V2.49824C11.9815 2.77406 11.7581 2.99766 11.4825 2.99766H1.50359C1.22803 2.99766 1.00464 2.77406 1.00464 2.49824V1.49941C1.00464 1.2236 1.22803 1 1.50359 1ZM1 4.49182C1 4.216 1.22339 3.99241 1.49895 3.99241L11.4991 3.97107C11.7747 3.97107 11.9981 4.19466 11.9981 4.47048 11.9981 4.7463 11.7747 4.9699 11.4991 4.9699L1.49895 4.99124C1.22339 4.99124 1 4.76764 1 4.49182ZM1 6.49338C1 6.21756 1.22339 5.99396 1.49895 5.99396L11.4991 5.97263C11.7747 5.97263 11.9981 6.19622 11.9981 6.47204 11.9981 6.74786 11.7747 6.97146 11.4991 6.97146L1.49895 6.99279C1.22339 6.99279 1 6.7692 1 6.49338ZM1.49895 9.98636C1.22339 9.98636 1 10.21 1 10.4858 1 10.7616 1.22339 10.9852 1.49895 10.9852L11.4991 10.9639C11.7747 10.9639 11.9981 10.7403 11.9981 10.4644 11.9981 10.1886 11.7747 9.96502 11.4991 9.96502L1.49895 9.98636ZM1 8.48714C1 8.21132 1.22339 7.98772 1.49895 7.98772L11.4991 7.96638C11.7747 7.96638 11.9981 8.18998 11.9981 8.4658 11.9981 8.74162 11.7747 8.96521 11.4991 8.96521L1.49895 8.98655C1.22339 8.98655 1 8.76295 1 8.48714ZM1.5009 11.9762C1.22534 11.9762 1.00195 12.1998 1.00195 12.4756 1.00195 12.7514 1.22534 12.975 1.5009 12.975L11.5011 12.9537C11.7766 12.9537 12 12.7301 12 12.4543 12 12.1785 11.7766 11.9549 11.5011 11.9549L1.5009 11.9762ZM1.00195 14.5006C1.00195 14.2248 1.22534 14.0012 1.5009 14.0012L11.5011 13.9798C11.7766 13.9798 12 14.2034 12 14.4792 12 14.7551 11.7766 14.9787 11.5011 14.9787L1.5009 15C1.22534 15 1.00195 14.7764 1.00195 14.5006Z"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgLListTitle.propTypes = iconPropTypes;
