import { forwardRef } from 'react';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { IIconProps } from '../IIconProps.type.ts';
import { IconWrapper } from '../IconWrapper.tsx';
import { iconPropTypes } from '../iconPropTypes.ts';
export const SvgQrCode = forwardRef<SVGSVGElement, IIconProps>(
  ({ className, color, screenReaderText, size, ...props }, ref) => {
    // 'hack' to render svg’s jsx wrapped in <IconWrapper>
    // we can’t use it directly in the return function because it fails with 'BABEL_TRANSFORM_ERROR'
    const icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          d="M11.3985 3.99542C11.3985 3.67069 11.662 3.40744 11.987 3.40744C12.312 3.40744 12.5755 3.67069 12.5755 3.99542V4.01009C12.5755 4.33482 12.312 4.59807 11.987 4.59807C11.662 4.59807 11.3985 4.33482 11.3985 4.01009V3.99542Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M10.4911 0.998047C9.66078 0.998047 8.98766 1.67058 8.98766 2.50018V5.50446C8.98766 6.33406 9.66078 7.00659 10.4911 7.00659H13.498C14.3283 7.00659 15.0015 6.33406 15.0015 5.50446V2.50018C15.0015 1.67058 14.3283 0.998047 13.498 0.998047H10.4911ZM9.98996 2.50018C9.98996 2.22365 10.2143 1.99947 10.4911 1.99947H13.498C13.7748 1.99947 13.9992 2.22365 13.9992 2.50018V5.50446C13.9992 5.78099 13.7748 6.00517 13.498 6.00517H10.4911C10.2143 6.00517 9.98996 5.78099 9.98996 5.50446V2.50018Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M3.4064 3.98676C3.4064 3.65994 3.67157 3.39501 3.99867 3.39501C4.32577 3.39501 4.59093 3.65994 4.59093 3.98676C4.59093 4.31357 4.32577 4.5785 3.99867 4.5785C3.67157 4.5785 3.4064 4.31357 3.4064 3.98676Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.00219 2.50049C1.00219 1.67088 1.67531.998355 2.50564.998355H5.51254C6.34288.998355 7.01599 1.67088 7.01599 2.50049V5.50476C7.01599 6.33437 6.34288 7.0069 5.51254 7.0069H2.50564C1.67531 7.0069 1.00219 6.33437 1.00219 5.50476V2.50049ZM2.50564 1.99978C2.22887 1.99978 2.00449 2.22396 2.00449 2.50049V5.50476C2.00449 5.7813 2.22887 6.00548 2.50564 6.00548H5.51254C5.78932 6.00548 6.01369 5.7813 6.01369 5.50476V2.50049C6.01369 2.22396 5.78932 1.99978 5.51254 1.99978H2.50564ZM10.5235 10.0031C10.2467 10.0031 10.0223 10.2273 10.0223 10.5038V13.5081C10.0223 13.7846 10.2467 14.0088 10.5235 14.0088H13.5304C13.8071 14.0088 14.0315 13.7846 14.0315 13.5081V10.5038C14.0315 10.2273 13.8071 10.0031 13.5304 10.0031H10.5235ZM11.0246 13.0074V11.0045H13.0292V13.0074H11.0246Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M3.99867 11.4117C3.67157 11.4117 3.4064 11.6767 3.4064 12.0035C3.4064 12.3303 3.67157 12.5952 3.99867 12.5952C4.32577 12.5952 4.59093 12.3303 4.59093 12.0035C4.59093 11.6767 4.32577 11.4117 3.99867 11.4117Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.00146 10.4916C1.00146 9.66203 1.67458 8.9895 2.50492 8.9895H5.51182C6.34215 8.9895 7.01527 9.66203 7.01527 10.4916V13.4959C7.01527 14.3255 6.34215 14.998 5.51182 14.998H2.50492C1.67458 14.998 1.00146 14.3255 1.00146 13.4959V10.4916ZM2.50492 9.99093C2.22814 9.99093 2.00377 10.2151 2.00377 10.4916V13.4959C2.00377 13.7724 2.22814 13.9966 2.50492 13.9966H5.51182C5.78859 13.9966 6.01297 13.7724 6.01297 13.4959V10.4916C6.01297 10.2151 5.78859 9.99093 5.51182 9.99093H2.50492Z"
          clipRule="evenodd"
        />
      </svg>
    );
    return (
      <IconWrapper className={className} $color={color} $size={size}>
        {icon}
        {screenReaderText && <SrOnly>{screenReaderText}</SrOnly>}
      </IconWrapper>
    );
  },
);
SvgQrCode.propTypes = iconPropTypes;
